<template>
  <div>
    <div class="subtitle-1 black--text">{{ value }}</div>
    <div class="data-cell-label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: 'BaseVerticalDataCell',

  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
  },
};
</script>

<style scoped>
.data-cell-label {
  font-size: 12px;
}
</style>
