<template>
  <div>
    <v-row v-if="!workerView" class="mt-1 mb-3" :id="`order-part-${part.id}-row`" dense>
      <v-col class="d-flex justify-space-between align-center" cols="12">
        <div class="subtitle-2 d-flex align-center flex-wrap">
          {{ orderPartTitle }}
          <BaseChipSelect
            v-if="!isBought"
            :value="part.status"
            :items="orderPartStatuses"
            chip-classes="ml-2"
            @change="onStatusChange"
          />

          <BaseChipSelect
            :value="part.warehouse_location_id"
            :items="$store.getters.warehouseLocations"
            :no-data-text="$t('location_unspecified')"
            :outlined="!part.warehouse_location_id"
            chip-classes="ml-2"
            item-value="id"
            item-text="name"
            show-search
            @change="updateWarehouseLocation"
          />

          <v-tooltip
            v-if="workDaysTillDeadline >= 0 && workDaysTillDeadline <= 14"
            transition="none"
            bottom
          >
            <template #activator="{ on }">
              <v-icon
                v-on="on"
                :color="workDaysTillDeadline <= 7 ? 'error' : 'yellow darken-2'"
                class="ml-1"
                small
                >mdi-clock</v-icon
              >
            </template>
            <div v-if="workDaysTillDeadline > 0">
              {{ workDaysTillDeadline }} {{ $t('work_days_till_deadline') }}
            </div>
            <div v-else>{{ $t('is_overdue') }}!</div>
          </v-tooltip>
        </div>
        <div>
          <BaseActionMenu :actions="actions" :item="part" :max-buttons="3" />
        </div>
      </v-col>
    </v-row>

    <OrderPartDocumentPicker
      v-if="!workerView && part.document_library_items.length"
      :documents="part.document_library_items"
    />

    <BaseFileDropZone
      v-if="!workerView"
      :current-files="part.documents"
      :upload-button-text="$t('attach_scheme')"
      :label="$t('schemes')"
      :upload-function="uploadOrderPartDocuments"
      :delete-function="deleteOrderPartDocument"
      :accept="documentFileFormats"
      class="mb-4"
      multiple
    />

    <div v-if="part.processes && part.processes.length && !workerView" class="mb-4">
      <div class="subtitle-2">
        {{ $t('processes') }}
      </div>

      <OrderPartProcessTable
        :rows="part.processes"
        :order="order"
        :order-part="part"
        @update:process-status="$emit('update:process-status', $event)"
      />
    </div>

    <div v-if="part.materials && part.materials.length" class="mb-4">
      <div class="subtitle-2">
        {{ $t('materials')
        }}<template v-if="!workerView && part.material_defects"
          >.
          <span class="link" @click="$emit('click:material-defects')"
            >{{ $t('defects') }} ({{ part.material_defects.length }})</span
          >
        </template>
      </div>
      <OrderPartMaterialTable
        :part="part"
        :order="order"
        :rows="part.materials"
        :worker-view="workerView"
        :can-change-status="!workerView"
        :can-change-location="!workerView"
        :show-purchases="!workerView"
        :key="updateCount"
        dense
        @update="updateOrderPartMaterial"
      />
    </div>

    <v-row class="mb-3" dense>
      <v-col cols="12" sm="6" md="4" lg="3">
        <template v-if="!part.inventory_item">
          <div class="subtitle-2">
            {{ $t('inventory_item') }}
          </div>
          <div>
            -
          </div>
        </template>
        <template v-else>
          <div class="subtitle-2">
            {{ $t('inventory_item') }}
            <v-tooltip v-if="!workerView" transition="none" bottom>
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  @click="$emit('click:inventory-item-information', part.inventory_item)"
                >
                  <v-icon>
                    mdi-information
                  </v-icon>
                </v-btn>
              </template>
              <div>
                {{ $t('show_usage_in_order_parts') }}
              </div>
            </v-tooltip>
          </div>
          <div>
            {{ part.inventory_item.title }} ({{
              $t(`inventory_item_types.${part.inventory_item.type}`)
            }})
          </div>
          <div>
            {{ $t('quantity_in_warehouse') }}:
            <BaseNumberCell :value="part.quantity_in_warehouse" />
          </div>
          <div v-if="!isBought">
            {{ $t('quantity_in_progress') }}:
            <BaseNumberCell :value="part.quantity_in_progress" />
          </div>
          <div>
            {{ $t('quantity_issued') }}:
            <BaseNumberCell :value="part.quantity_issued" />
          </div>
          <div v-if="part.inventory_item">
            {{ $t('quantity_available') }}:
            <BaseNumberCell :value="part.inventory_item.quantity_in_warehouse" />
          </div>
        </template>
      </v-col>

      <v-col v-if="!isBought" cols="12" sm="6" md="4" lg="3">
        <div class="subtitle-2">
          {{ $t('product_info') }}
        </div>
        <div v-if="part.quantity">
          {{ $t('quantity') }}:
          <BaseNumberCell :value="part.quantity" />
        </div>
        <div v-if="part.inventory_item_length_in_mm_param">
          {{ $t('inventory_item_length_in_mm_param_short') }}:
          <BaseNumberCell :value="part.inventory_item_length_in_mm_param" />
        </div>
      </v-col>

      <v-col v-if="!workerView && !isBought" cols="12" sm="6" md="4" lg="3">
        <div class="subtitle-2">
          {{ $t('material_sell_price') }}
        </div>
        {{ materialSellPrice }}
      </v-col>

      <v-col v-if="!isBought" cols="12" sm="6" md="4" lg="3">
        <div class="subtitle-2">
          {{ $t('deadline_at') }}
        </div>
        {{ part.deadline_at || order.deadline_at || '-' }}
      </v-col>
    </v-row>

    <canvas ref="qrCodeCanvas" class="d-none" />
  </div>
</template>

<script>
import QRCode from 'qrcode';
import BaseActionMenu from './base/BaseActionMenu';
import OrderPartProcessTable from './tables/OrderPartProcessTable';
import BaseChipSelect from './base/BaseChipSelect';
import { ORDER_PART_STATUSES } from '@/api/order-part-service';
import eventBus, { OPEN_SNACKBAR } from '@/util/event-bus';
import OrderPartDocumentPicker from '@/components/OrderPartDocumentPicker';
import BaseFileDropZone from '@/components/base/BaseFileDropZone';
import orderDocumentService from '@/api/order-document-service';
import { removeItemById, updateItemById } from '@/util/array';
import BaseNumberCell from '@/components/base/BaseNumberCell';
import OrderPartMaterialTable from '@/components/tables/OrderPartMaterialTable';
import { getWorkDayCount } from '@/api/holiday-service';
import orderPartMaterialService from '@/api/order-part-material-service';
import crudMixin from '@/mixins/crud-mixin';
import { round } from '@/util/numbers';

export default {
  name: 'OrderPartDetails',

  components: {
    OrderPartMaterialTable,
    BaseNumberCell,
    BaseFileDropZone,
    OrderPartDocumentPicker,
    BaseChipSelect,
    OrderPartProcessTable,
    BaseActionMenu,
  },

  mixins: [crudMixin],

  props: {
    part: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      default: () => ({}),
    },
    index: Number,
    workerView: {
      type: Boolean,
      default: false,
    },
    documentFileFormats: {
      type: String,
      default: '*',
    },
    updateCount: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      actions: [
        {
          callback: p => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: p => this.generateQrCode(p),
          label: this.$t('get_qr_code'),
          icon: 'mdi-qrcode',
        },
        {
          callback: p => this.$emit('delete', p),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ],
      orderPartStatuses: ORDER_PART_STATUSES,
    };
  },

  computed: {
    orderPartTitle() {
      let title = '';
      if (this.part.inventory_item?.title) {
        title += `${this.part.inventory_item.title} `;
      }
      title += `${this.order?.order_no}.${this.part.order_part_no}`;
      return title;
    },

    isBought() {
      return this.part.inventory_item?.type === 'bought';
    },

    workDaysTillDeadline() {
      const today = new Date();

      if (!this.part.deadline_at && !this.order.deadline_at) {
        return -1;
      }

      return getWorkDayCount(today, new Date(this.part.deadline_at || this.order.deadline_at));
    },

    materialSellPrice() {
      return +this.part.material_sell_price ? `€${round(this.part.material_sell_price, 4)}` : '-';
    },
  },

  methods: {
    generateQrCode(part) {
      const url = `${window.location.host}${
        this.$router.resolve({
          name: 'order-part',
          params: { id: part.id },
        }).href
      }`;

      console.log(url);
      QRCode.toCanvas(this.$refs.qrCodeCanvas, url);
      const dataUrl = this.$refs.qrCodeCanvas.toDataURL('image/png');
      const newTab = window.open();
      if (newTab) {
        newTab.document.body.innerHTML = `<img src="${dataUrl}"></img>`;
      } else {
        eventBus.$emit(OPEN_SNACKBAR, {
          text: this.$t('allow_browser_popups_and_retry'),
          timeout: 12000,
        });
      }
    },

    onStatusChange(newStatus) {
      const updatedOrderPart = {
        ...this.part,
        status: newStatus,
      };
      this.$emit('update', updatedOrderPart);
    },

    updateWarehouseLocation(newLocationId) {
      const updatedOrderPart = {
        ...this.part,
        warehouse_location_id: newLocationId,
      };
      this.$emit('update', updatedOrderPart);
    },

    async uploadOrderPartDocuments(files) {
      const payload = {
        files,
        orderID: this.part.order_id,
        orderPartID: this.part.id,
      };
      const { data } = await orderDocumentService.upload(payload);
      this.$set(this.part, 'documents', [...this.part.documents, ...data]);
    },

    async deleteOrderPartDocument(document) {
      await orderDocumentService.delete(document);
      this.$set(this.part, 'documents', removeItemById(this.part.documents, document.id));
    },

    async updateOrderPartMaterial(item) {
      const { data } = await orderPartMaterialService.patch(item);
      eventBus.$emit(OPEN_SNACKBAR, this.$t('order_part_material_updated'));
      this.$set(
        this.part,
        'materials',
        updateItemById(this.part.materials, {
          ...item,
          ...data,
          material: {
            ...(item.material || {}),
            ...(data.material || {}),
          },
        }),
      );
    },
  },
};
</script>

<style scoped></style>
