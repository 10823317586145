<template>
  <span>
    <slot v-if="!useExternalActivator" name="activator">
      <v-btn v-bind="$attrs" color="primary" text @click="openMainDialog">
        <v-icon left>mdi-currency-eur</v-icon>
        {{ $t('price_history') }}</v-btn
      >
    </slot>
    <v-dialog
      :value="isMainDialogOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="1100"
      persistent
      scrollable
      @input="closeMainDialog"
    >
      <v-card>
        <v-card-title>
          <div>{{ mainDialogTitle }}</div>
          <v-spacer />
          <v-btn color="primary" text @click="createInventoryItemPriceHistoryEntry">
            <v-icon left>mdi-plus</v-icon>
            {{ $t('create') }}
          </v-btn>
        </v-card-title>

        <v-card-text>
          <InventoryItemPriceHistoryTable
            :rows="inventoryItemPriceHistoryArray"
            :pagination="inventoryItemPriceHistoryPagination"
            :loading="$store.getters.loading['get:api/inventory-item-price-history']"
            @change-page="getPaginatedInventoryItemPriceHistory"
            @edit="crudMixin_openForm('inventoryItemPriceHistory', $event)"
            @delete="deleteInventoryItemPriceHistoryEntry"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="closeMainDialog">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isInventoryItemPriceHistoryFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <InventoryItemPriceHistoryForm
        :dialog="isInventoryItemPriceHistoryFormOpen"
        :form-item="inventoryItemPriceHistoryFormItem"
        @create="crudMixin_created('inventoryItemPriceHistory', $event)"
        @update="crudMixin_updated('inventoryItemPriceHistory', $event)"
        @cancel="isInventoryItemPriceHistoryFormOpen = false"
      />
    </v-dialog>
  </span>
</template>

<script>
import crudMixin from '@/mixins/crud-mixin';
import inventoryItemPriceHistoryService from '@/api/inventory-item-price-history-service';
import InventoryItemPriceHistoryTable from '@/components/tables/InventoryItemPriceHistoryTable';
import InventoryItemPriceHistoryForm from '@/components/forms/InventoryItemPriceHistoryForm';
import { format } from 'date-fns';

export default {
  name: 'InventoryItemPriceHistoryListDialog',

  components: { InventoryItemPriceHistoryForm, InventoryItemPriceHistoryTable },

  mixins: [crudMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    useExternalActivator: {
      type: Boolean,
      default: false,
    },
    inventoryItem: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      inventoryItemPriceHistoryArray: [],
      inventoryItemPriceHistoryPagination: {
        page: 1,
      },
      inventoryItemPriceHistoryFormItem: {},
      isInventoryItemPriceHistoryFormOpen: false,
      isOpen: false,
    };
  },

  computed: {
    isMainDialogOpen() {
      if (this.useExternalActivator) {
        return this.dialog;
      }
      return this.isOpen;
    },

    mainDialogTitle() {
      return this.$t('inventory_item_price_history').replace('{0}', this.inventoryItem.title);
    },
  },

  watch: {
    isMainDialogOpen(newValue) {
      if (!newValue) {
        return;
      }
      this.inventoryItemPriceHistoryArray = [];
      this.inventoryItemPriceHistoryPagination = {
        page: 1,
      };
      this.getPaginatedInventoryItemPriceHistory();
    },
  },

  methods: {
    openMainDialog() {
      this.inventoryItemPriceHistoryArray = [];
      this.inventoryItemPriceHistoryPagination = {
        page: 1,
      };
      this.getPaginatedInventoryItemPriceHistory();
      this.isOpen = true;
    },

    closeMainDialog() {
      this.isOpen = false;
      this.$emit('close');
    },

    getPaginatedInventoryItemPriceHistory(page = 1) {
      const params = {
        inventory_item_id: this.inventoryItem.id,
      };
      this.crudMixin_getPage(
        inventoryItemPriceHistoryService.getPage,
        inventoryItemPriceHistoryService.model,
        page,
        params,
      );
    },

    createInventoryItemPriceHistoryEntry() {
      this.crudMixin_openForm('inventoryItemPriceHistory', {
        inventory_item_id: this.inventoryItem.id,
        type: 'selling',
        date: format(new Date(), 'yyyy-MM-dd'),
      });
    },

    deleteInventoryItemPriceHistoryEntry(entry) {
      this.crudMixin_delete(
        inventoryItemPriceHistoryService.delete,
        'inventoryItemPriceHistory',
        entry,
      );
    },
  },
};
</script>

<style scoped></style>
