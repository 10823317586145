<template>
  <div>
    <v-data-table
      :expanded="tableMixin_expandedRows"
      :headers="tableMixin_displayedHeaders"
      :items="decoratedRows"
      :footer-props="
        pagination
          ? {
              'items-per-page-options': [pagination.rowsPerPage],
            }
          : null
      "
      :server-items-length="pagination ? pagination.totalItems : -1"
      :mobile-breakpoint="0"
      :disable-pagination="!pagination"
      :hide-default-footer="!pagination"
      disable-sort
      @click:row="tableMixin_onRowClick"
    >
      <template v-slot:item.orders="{ item }">
        <div v-if="item.usedInOrdersNumbers.length">
          <span>{{ $t('in_use') }}</span
          >:
          <router-link
            v-for="(orderNumber, index) in item.usedInOrdersNumbers"
            :key="index"
            :to="`/orders?search=${orderNumber}`"
            class="link"
          >
            {{ orderNumber
            }}<template v-if="index < item.usedInOrdersNumbers.length - 1">, </template>
          </router-link>
        </div>

        <div v-if="item.reservedForOrdersNumbers.length">
          <span>{{ $t('reserved') }}</span
          >:
          <router-link
            v-for="(orderNumber, index) in item.reservedForOrdersNumbers"
            :key="index"
            :to="`/orders?search=${orderNumber}`"
            class="link"
          >
            {{ orderNumber
            }}<template v-if="index < item.reservedForOrdersNumbers.length - 1">, </template>
          </router-link>
        </div>
      </template>

      <template v-slot:item.material_delivered_by="{ item }">
        <template v-if="deliveredByTypesMap[item.material_delivered_by]">
          {{ deliveredByTypesMap[item.material_delivered_by].text }}
        </template>
      </template>

      <template v-slot:item.status="{ item }">
        <BaseChipSelect
          :value="item.status"
          :items="inventoryItemPurchaseStatuses"
          :color="item.statusColor"
          @change="onPurchaseStatusChange(item, $event)"
        />
      </template>

      <template v-slot:item.dispatches="{ item }">
        <template>
          {{
            getInventoryItemQuantity(
              getDispatchedQuantity(item),
              item.material_unit,
              item.inventory_item ? item.inventory_item.unit_a_title : inventoryItem.unit_a_title,
              item.inventory_item ? item.inventory_item.unit_b_title : inventoryItem.unit_b_title,
              item.inventory_item
                ? item.inventory_item.unit_a_b_ratio
                : inventoryItem.unit_a_b_ratio,
            )
          }}
        </template>
      </template>

      <template v-slot:item.quantity_ordered="{ item }">
        <span :class="{ 'grey--text': !+item.quantity_ordered }">
          {{
            getInventoryItemQuantity(
              item.quantity_ordered,
              item.material_unit,
              item.inventory_item ? item.inventory_item.unit_a_title : inventoryItem.unit_a_title,
              item.inventory_item ? item.inventory_item.unit_b_title : inventoryItem.unit_b_title,
              item.inventory_item
                ? item.inventory_item.unit_a_b_ratio
                : inventoryItem.unit_a_b_ratio,
            )
          }}
        </span>
      </template>

      <template v-slot:item.planned_arrival_at="{ item }">
        <div class="text-no-wrap d-flex align-center">
          {{ item.planned_arrival_at }}
          <v-tooltip v-if="item.isLate" bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="ml-1" color="error">
                mdi-alert-circle
              </v-icon>
            </template>
            <span>
              {{ $t('is_late') }}
            </span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.inventory_item.title="{ item }">
        <router-link
          v-if="item.inventory_item"
          :to="`/order-part-materials?search=${item.inventory_item.title}`"
          class="link"
        >
          {{ item.inventory_item.title }}
        </router-link>
      </template>

      <template v-slot:item.gross_cost="{ item }">
        <div class="text-no-wrap">
          <BaseNumberCell :value="item.gross_cost_per_unit_a" /> /
          <span>{{
            item.inventory_item ? item.inventory_item.unit_a_title : inventoryItem.unit_a_title
          }}</span>
        </div>
        <div class="text-no-wrap">
          <BaseNumberCell :value="item.gross_cost_per_unit_b" /> /
          <span>{{
            item.inventory_item ? item.inventory_item.unit_b_title : inventoryItem.unit_b_title
          }}</span>
        </div>
      </template>

      <template v-slot:item.selling_price="{ item }">
        <div class="text-no-wrap">
          <BaseNumberCell :value="item.sell_price_per_unit_a" /> /
          <span>{{
            item.inventory_item ? item.inventory_item.unit_a_title : inventoryItem.unit_a_title
          }}</span>
        </div>
        <div class="text-no-wrap">
          <BaseNumberCell :value="item.sell_price_per_unit_b" /> /
          <span>{{
            item.inventory_item ? item.inventory_item.unit_b_title : inventoryItem.unit_b_title
          }}</span>
        </div>
      </template>

      <template v-slot:item.material_cost="{ item }">
        <BaseNumberCell :value="item.material_cost" />
      </template>

      <template v-slot:item.created_by="{ item }">
        <template v-if="item.creator && item.creator.person">{{
          item.creator.person.full_name
        }}</template>
      </template>

      <template v-slot:item.supplier.name="{ item }">
        <v-menu v-if="item.supplier" :close-on-content-click="false" offset-y nudge-bottom="8">
          <template v-slot:activator="{ on }">
            <span v-on="on" class="link">
              {{ item.supplier.name }}
            </span>
          </template>
          <InventoryItemPurchaseSupplierCard
            :purchase="item"
            :show-filter-button="linkifySupplier"
            @click:add-to-filter="$emit('click:supplier', item.supplier)"
          />
        </v-menu>
        <template v-else>
          {{ item.supplier ? item.supplier.name : '' }}
        </template>
      </template>

      <template v-slot:item.actions="{ item }">
        <BaseActionMenu :actions="getRowActions(item)" :item="item" />
      </template>

      <template v-slot:expanded-item="{ item }">
        <BaseExpandedTableRow
          :colspan="tableMixin_displayedHeaders.length"
          :headers="tableMixin_hiddenHeaders"
          :item="item"
        >
          <template v-slot:item.material_delivered_by>
            <template v-if="deliveredByTypesMap[item.material_delivered_by]">
              {{ deliveredByTypesMap[item.material_delivered_by].text }}
            </template>
          </template>

          <template v-slot:item.status>
            <BaseChipSelect
              :value="item.status"
              :items="inventoryItemPurchaseStatuses"
              :color="item.statusColor"
              @change="onPurchaseStatusChange(item, $event)"
            />
          </template>

          <template v-slot:item.quantity_ordered>
            <span :class="{ 'grey--text': !+item.quantity_ordered }">
              {{
                getInventoryItemQuantity(
                  item.quantity_ordered,
                  item.material_unit,
                  item.inventory_item
                    ? item.inventory_item.unit_a_title
                    : inventoryItem.unit_a_title,
                  item.inventory_item
                    ? item.inventory_item.unit_b_title
                    : inventoryItem.unit_b_title,
                  item.inventory_item
                    ? item.inventory_item.unit_a_b_ratio
                    : inventoryItem.unit_a_b_ratio,
                )
              }}
            </span>
          </template>

          <template v-slot:item.dispatches>
            <template>
              {{
                getInventoryItemQuantity(
                  getDispatchedQuantity(item),
                  item.material_unit,
                  item.inventory_item
                    ? item.inventory_item.unit_a_title
                    : inventoryItem.unit_a_title,
                  item.inventory_item
                    ? item.inventory_item.unit_b_title
                    : inventoryItem.unit_b_title,
                  item.inventory_item
                    ? item.inventory_item.unit_a_b_ratio
                    : inventoryItem.unit_a_b_ratio,
                )
              }}
            </template>
          </template>

          <template v-slot:item.planned_arrival_at>
            <div class="text-no-wrap d-flex align-center">
              {{ item.planned_arrival_at }}
              <v-tooltip v-if="item.isLate" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" class="ml-1" color="error">
                    mdi-alert-circle
                  </v-icon>
                </template>
                <span>
                  {{ $t('is_late') }}
                </span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:item.inventory_item.title>
            <router-link
              v-if="item.inventory_item"
              :to="`/order-part-materials?search=${item.inventory_item.title}`"
              class="link"
            >
              {{ item.inventory_item.title }}
            </router-link>
          </template>

          <template v-slot:item.supplier.name>
            <v-menu v-if="item.supplier" :close-on-content-click="false" offset-y nudge-bottom="8">
              <template v-slot:activator="{ on }">
                <span v-on="on" class="link">
                  {{ item.supplier.name }}
                </span>
              </template>
              <InventoryItemPurchaseSupplierCard
                :purchase="item"
                :show-filter-button="linkifySupplier"
                @click:add-to-filter="$emit('click:supplier', item.supplier)"
              />
            </v-menu>
            <template v-else>
              {{ item.supplier ? item.supplier.name : '' }}
            </template>
          </template>

          <template v-slot:item.gross_cost>
            <div>
              <BaseNumberCell :value="item.gross_cost_per_unit_a" /> /
              <span>{{
                item.inventory_item ? item.inventory_item.unit_a_title : inventoryItem.unit_a_title
              }}</span>
            </div>
            <div>
              <BaseNumberCell :value="item.gross_cost_per_unit_b" /> /
              <span>{{
                item.inventory_item ? item.inventory_item.unit_b_title : inventoryItem.unit_b_title
              }}</span>
            </div>
          </template>

          <template v-slot:item.selling_price>
            <div>
              <BaseNumberCell :value="item.sell_price_per_unit_a" /> /
              <span>{{
                item.inventory_item ? item.inventory_item.unit_a_title : inventoryItem.unit_a_title
              }}</span>
            </div>
            <div>
              <BaseNumberCell :value="item.sell_price_per_unit_b" /> /
              <span>{{
                item.inventory_item ? item.inventory_item.unit_b_title : inventoryItem.unit_b_title
              }}</span>
            </div>
          </template>

          <template v-slot:item.material_cost>
            <BaseNumberCell :value="item.material_cost" />
          </template>

          <template v-slot:item.created_by>
            <template v-if="item.creator && item.creator.person">{{
              item.creator.person.full_name
            }}</template>
          </template>
        </BaseExpandedTableRow>
      </template>
    </v-data-table>

    <canvas ref="qrCodeCanvas" class="d-none" />

    <InventoryItemPriceHistoryListDialog
      v-if="showPriceHistoryButton"
      :inventory-item="selectedInventoryItem"
      :dialog="isInventoryPriceHistoryDialogOpen"
      use-external-activator
      @close="isInventoryPriceHistoryDialogOpen = false"
    />
  </div>
</template>

<script>
import tableMixin from '@/mixins/table-mixin';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import { ORDER_PART_DELIVERED_BY_TYPES } from '@/api/order-part-service';
import { arrayToMap } from '@/util/array';
import BaseChipSelect from '@/components/base/BaseChipSelect';
import BaseNumberCell from '@/components/base/BaseNumberCell';
import BaseActionMenu from '@/components/base/BaseActionMenu';
import { INVENTORY_ITEM_PURCHASE_STATUSES } from '@/api/inventory-item-purchase-service';
import { getInventoryItemQuantity } from '@/util/inventory-item-quantity';
import InventoryItemPurchaseSupplierCard from '@/components/InventoryItemPurchaseSupplierCard';
import QRCode from 'qrcode';
import eventBus, { OPEN_SNACKBAR } from '@/util/event-bus';
import InventoryItemPriceHistoryListDialog from '@/components/InventoryItemPriceHistoryListDialog';
import clone from 'just-clone';

export default {
  name: 'InventoryItemPurchaseTable',

  components: {
    InventoryItemPriceHistoryListDialog,
    InventoryItemPurchaseSupplierCard,
    BaseActionMenu,
    BaseNumberCell,
    BaseChipSelect,
    BaseExpandedTableRow,
  },

  mixins: [tableMixin],

  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: () => null,
    },
    linkifySupplier: {
      type: Boolean,
      default: false,
    },
    showOrderNumbers: {
      type: Boolean,
      default: false,
    },
    showMaterialTitle: {
      type: Boolean,
      default: false,
    },
    inventoryItem: {
      type: Object,
      default: () => ({}),
    },
    showPriceHistoryButton: {
      type: Boolean,
      default: false,
    },
    showDispatchButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedInventoryItem: {},
      isInventoryPriceHistoryDialogOpen: false,
    };
  },

  computed: {
    deliveredByTypesMap() {
      return arrayToMap(ORDER_PART_DELIVERED_BY_TYPES);
    },

    inventoryItemPurchaseStatuses() {
      return INVENTORY_ITEM_PURCHASE_STATUSES;
    },

    inventoryItemPurchaseStatusesMap() {
      return arrayToMap(INVENTORY_ITEM_PURCHASE_STATUSES);
    },

    headers() {
      let headers = [
        {
          text: this.$t('orders'),
          value: 'orders',
        },
        {
          text: this.$t('status'),
          value: 'status',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('planned_arrival_at'),
          value: 'planned_arrival_at',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('material'),
          value: 'inventory_item.title',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('quantity_ordered'),
          value: 'quantity_ordered',
          align: this.showOrderNumbers ? 'end' : 'start',
          hidden: this.showOrderNumbers ? 'smAndDown' : '',
        },
        {
          text: this.$t('quantity_issued'),
          value: 'dispatches',
          align: 'end',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('material_delivered_by'),
          value: 'material_delivered_by',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('supplier'),
          value: 'supplier.name',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('gross_cost'),
          value: 'gross_cost',
          align: 'end',
          hidden: 'mdAndDown',
        },
        {
          text: this.$t('selling_price'),
          value: 'selling_price',
          align: 'end',
          hidden: 'mdAndDown',
        },
        {
          text: this.$t('material_cost'),
          value: 'material_cost',
          align: 'end',
          hidden: 'mdAndDown',
        },
        {
          text: this.$t('created_by'),
          value: 'created_by',
          hidden: 'mdAndDown',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ];
      if (!this.showOrderNumbers) {
        headers = headers.filter(h => h.value !== 'orders');
      }
      if (!this.showMaterialTitle) {
        headers = headers.filter(h => h.value !== 'inventory_item.title');
      }
      return headers;
    },

    decoratedRows() {
      const now = new Date();
      return this.rows.map(r => {
        let statusColor = this.inventoryItemPurchaseStatusesMap[r.status].color;
        let isLate = false;
        if (
          r.status !== 'arrived' &&
          r.planned_arrival_at &&
          new Date(r.planned_arrival_at) < now
        ) {
          statusColor = 'red lighten-4';
          isLate = true;
        }
        return {
          ...r,
          usedInOrdersNumbers:
            r.order_part_materials?.map(opm => opm.order_part?.order?.order_no) || [],
          reservedForOrdersNumbers:
            r.reserved_for_order_parts?.map(part => part?.order?.order_no) || [],
          statusColor,
          isLate,
        };
      });
    },
  },

  methods: {
    getInventoryItemQuantity,

    generateQrCode(inventoryItemPurchase) {
      const url = `${window.location.host}${
        this.$router.resolve({
          name: 'inventory-item-purchase-details',
          params: { id: inventoryItemPurchase.id },
        }).href
      }`;

      console.log(url);
      QRCode.toCanvas(this.$refs.qrCodeCanvas, url);
      const dataUrl = this.$refs.qrCodeCanvas.toDataURL('image/png');
      const newTab = window.open();
      if (newTab) {
        newTab.document.body.innerHTML = `<img src="${dataUrl}"></img>`;
      } else {
        eventBus.$emit(OPEN_SNACKBAR, {
          text: this.$t('allow_browser_popups_and_retry'),
          timeout: 12000,
        });
      }
    },

    getRowActions(item) {
      let actions = [
        {
          callback: p => this.$emit('dispatch', p, this.inventoryItem),
          label: item.status === 'arrived' ? this.$t('dispatch') : this.$t('nothing_to_dispatch'),
          disabled: item.status !== 'arrived',
          icon: 'mdi-tanker-truck',
          key: 'dispatch',
        },
        {
          callback: p => {
            const clonedPurchase = clone(p);
            delete clonedPurchase.id;
            this.$emit('edit', clonedPurchase);
          },
          label: this.$t('create_copy'),
          icon: 'mdi-content-copy',
        },
        {
          callback: p => this.generateQrCode(p),
          label: this.$t('get_qr_code'),
          icon: 'mdi-qrcode',
        },
        {
          callback: p => this.showInventoryItemPriceHistory(p),
          label: this.$t('price_history'),
          icon: 'mdi-currency-eur',
          key: 'priceHistory',
        },
        {
          callback: p => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: p => this.$emit('delete', p),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ];

      if (!this.showPriceHistoryButton) {
        actions = actions.filter(a => a.key !== 'priceHistory');
      }

      if (!this.showDispatchButton) {
        actions = actions.filter(a => a.key !== 'dispatch');
      }

      return actions;
    },

    showInventoryItemPriceHistory(purchase) {
      this.selectedInventoryItem = purchase.inventory_item;
      this.isInventoryPriceHistoryDialogOpen = true;
    },

    onPurchaseStatusChange(item, newStatus) {
      if (item.status === newStatus) {
        return;
      }
      this.$emit('update', {
        ...item,
        status: newStatus,
      });
    },

    getDispatchedQuantity(purchase) {
      if (purchase.dispatches?.length) {
        return purchase.dispatches.reduce((acc, curr) => +acc + +curr.quantity, 0).toFixed(4);
      }
      return 0;
    },
  },
};
</script>
