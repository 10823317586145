<template>
  <div
    class="file-dropzone"
    @dragover.stop.prevent="showDropZone"
    @dragleave.stop.prevent="hideDropZone"
    @drop.stop.prevent="uploadFiles"
  >
    <slot name="content">
      <div class="d-flex justify-space-between align-center my-3">
        <div class="subtitle-2">
          <template v-if="currentFiles && currentFiles.length">
            {{ label }}
          </template>
        </div>

        <div v-if="uploadFunction">
          <v-tooltip v-if="$vuetify.breakpoint.smAndUp" transition="none" bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="mr-3" color="info">mdi-information</v-icon>
            </template>
            <span>
              {{ $t('you_can_drop_files_here_to_upload_them') }}
            </span>
          </v-tooltip>
          <v-btn color="primary" @click="openFilePicker">
            <v-icon left>mdi-upload</v-icon>
            {{ uploadButtonText }}
          </v-btn>
        </div>
      </div>

      <v-chip
        v-for="file in currentFiles"
        :key="file.id"
        :disabled="disabledFileIdMap[file.id]"
        :close="!disabledFileIdMap[file.id]"
        class="mr-1 mb-1"
        small
        @click="viewFile(file)"
        @click:close="deleteFile(file)"
      >
        {{ file.file_name }}
        <v-progress-circular
          v-if="disabledFileIdMap[file.id]"
          width="2"
          size="16"
          class="ml-2"
          indeterminate
        />
      </v-chip>
    </slot>
    <slot name="append" />
    <v-fade-transition>
      <div v-if="isDropZoneDisplayed" class="file-dropzone-overlay">
        <div class="text-center">
          <h2
            class="headline font-weight-medium"
            @dragover.stop.prevent="isDropZoneDisplayed = true"
          >
            {{ $t('drop_files_for_upload') }}
          </h2>
          <p
            v-if="accept && accept !== '*'"
            class="subtitle-1"
            @dragover.stop.prevent="isDropZoneDisplayed = true"
          >
            {{ $t('accepted_formats') }}: {{ accept }}
          </p>
        </div>
      </div>
    </v-fade-transition>

    <input
      :accept="accept"
      :multiple="multiple"
      :id="fileInputId"
      ref="filePicker"
      type="file"
      class="d-none"
      @change="uploadFiles"
    />

    <BaseFileViewer
      v-model="isFileViewerDialogOpen"
      :file-url="selectedFileUrl"
      :title="selectedFileTitle"
      :type="selectedFileTitle.toLowerCase().indexOf('pdf') > -1 ? 'pdf' : 'img'"
    />
  </div>
</template>

<script>
import i18n from '@/i18n/i18n-config';
import http from '@/api/http';
import BaseFileViewer from '@/components/base/BaseFileViewer';
import eventBus, {
  CONFIRM_DIALOG_CLOSED,
  OPEN_CONFIRM_DIALOG,
  OPEN_SNACKBAR,
} from '@/util/event-bus';
import { downloadFile } from '@/util/files';

export default {
  name: 'BaseFileDropZone',

  components: { BaseFileViewer },

  props: {
    accept: {
      type: String,
      default: '*',
    },
    currentFiles: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: i18n.t('files'),
    },
    uploadButtonText: {
      type: String,
      default: i18n.t('upload_files'),
    },
    deleteFunction: {
      type: Function,
      default: null,
    },
    uploadFunction: {
      type: Function,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    fileInputId: {
      type: String,
      default: 'file-input',
    },
  },

  data() {
    return {
      isDropZoneDisplayed: false,
      timerID: null,
      disabledFileIdMap: {},

      isFileViewerDialogOpen: false,
      selectedFileUrl: '',
      selectedFileTitle: '',
    };
  },

  methods: {
    hideDropZone() {
      clearTimeout(this.timerID);
      // timeout is here to avoid flickering when hovering over helper text inside overlay
      // TODO problem still persists 😕
      this.timerID = setTimeout(() => {
        this.isDropZoneDisplayed = false;
      }, 300);
    },

    showDropZone() {
      if (!this.uploadFunction) {
        return;
      }
      clearTimeout(this.timerID);
      this.isDropZoneDisplayed = true;
    },

    openFilePicker() {
      this.$refs.filePicker.click();
    },

    viewFile(file) {
      this.$set(this.disabledFileIdMap, file.id, true);
      http({
        url: file.file_url,
        method: 'GET',
        responseType: 'blob',
      })
        .then(res => {
          let fileType = '';
          const parts = file.file_name.split('.');
          if (parts.length > 1) {
            fileType = parts[parts.length - 1];
          }

          if (!['jpeg', 'png', 'jpg', 'gif', 'svg', 'bmp', 'pdf'].includes(fileType)) {
            downloadFile(res.data, file.file_name);
            return;
          }

          const blob =
            fileType.toLowerCase() === 'pdf'
              ? new Blob([res.data], { type: 'application/pdf' })
              : new Blob([res.data], { type: `image/${fileType}` });

          this.selectedFileUrl = window.URL.createObjectURL(blob);
          this.selectedFileTitle = file.file_name;
          this.isFileViewerDialogOpen = true;
        })
        .finally(() => {
          this.$delete(this.disabledFileIdMap, file.id);
        });
    },

    async deleteFile(file) {
      eventBus.$emit(OPEN_CONFIRM_DIALOG, {
        title: this.$t('confirm_entry_delete'),
      });
      eventBus.$on(CONFIRM_DIALOG_CLOSED, async confirmed => {
        eventBus.$off(CONFIRM_DIALOG_CLOSED);
        if (!confirmed) {
          return;
        }
        this.$emit('delete:file', file);
        if (!this.deleteFunction) {
          return;
        }

        this.$set(this.disabledFileIdMap, file.id, true);
        try {
          await this.deleteFunction(file);
          eventBus.$emit(OPEN_SNACKBAR, this.$t('file_deleted'));
        } catch (e) {
          console.error(e);
          eventBus.$emit(OPEN_SNACKBAR, this.$t('delete_failed'));
        }
        this.$delete(this.disabledFileIdMap, file.id);
      });
    },

    async uploadFiles(evt) {
      // TODO validate files by passed accept prop
      this.isDropZoneDisplayed = false;
      const files = evt.dataTransfer?.files || evt.target.files || [];
      this.$emit('drop', files);
      if (!this.uploadFunction) {
        return;
      }

      eventBus.$emit(OPEN_SNACKBAR, {
        text: this.$t('uploading_files'),
        timeout: -1,
        showProgress: true,
      });
      try {
        await this.uploadFunction(files);
        eventBus.$emit(OPEN_SNACKBAR, this.$t('files_were_uploaded'));
      } catch (e) {
        const validation = e.response?.data?.errors;
        if (validation) {
          const errorMsg = Object.values(validation)[0][0];
          eventBus.$emit(OPEN_SNACKBAR, {
            text: errorMsg,
            timeout: 8000,
          });
        } else {
          eventBus.$emit(OPEN_SNACKBAR, {
            text: this.$t('file_upload_failed'),
            timeout: 5000,
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.file-dropzone {
  position: relative;
}

.file-dropzone-overlay {
  background-color: rgba(255, 255, 255, 0.85);
  border: 5px dashed #1976d2;
  z-index: 5000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
