<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-radio-group
              v-model="item.type"
              :error-messages="errors.type"
              @change="formMixin_clearErrors('type')"
            >
              <v-radio :label="$t('inventory_item_price_history_types.selling')" value="selling" />
              <v-radio
                :label="$t('inventory_item_price_history_types.purchasing')"
                value="purchasing"
              />
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="item.date"
              :error-messages="errors['date']"
              :label="formMixin_getRequiredFieldLabel($t('date'))"
              @input="formMixin_clearErrors('date')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <BaseAutocomplete
              v-model="item.contact_id"
              :initial-item="item.contact"
              :search-function="searchContacts"
              :error-messages="errors.contact_id"
              :key="item.id"
              :label="$t('contact')"
              @input="formMixin_clearErrors('contact_id')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="item.price"
              :error-messages="errors['price']"
              :label="formMixin_getRequiredFieldLabel($t('price_in_euros'))"
              type="number"
              @blur="formMixin_clearErrors('price')"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="item.comments"
              :error-messages="errors['comments']"
              :label="$t('comments')"
              rows="2"
              auto-grow
              @blur="formMixin_clearErrors('comments')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!item.id" class="subtitle-2 ml-3"> * {{ $t('must_be_filled') }} </span>

        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import inventoryItemPriceHistoryService from '@/api/inventory-item-price-history-service';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';
import BaseAutocomplete from '@/components/base/BaseAutocomplete';
import contactService from '@/api/contact-service';

export default {
  name: 'InventoryItemPriceHistoryForm',

  components: { BaseAutocomplete, BaseDatepickerInput },

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    formItem: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      item: {},
    };
  },

  computed: {
    formTitle() {
      return this.$t(
        this.item.id ? 'edit_inventory_item_price_history' : 'new_inventory_item_price_history',
      );
    },
  },

  methods: {
    searchContacts: contactService.search,

    onDialogOpen() {
      this.item = JSON.parse(JSON.stringify(this.formItem));
      this.errors = {};
    },

    onSubmit() {
      this.crudMixin_createOrUpdate(inventoryItemPriceHistoryService, this.item);
    },
  },
};
</script>
