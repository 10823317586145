<template>
  <v-card max-width="500">
    <v-card-title class="d-flex justify-space-between align-center">
      <div class="pr-4">{{ purchase.supplier.name }}</div>
      <v-tooltip v-if="showFilterButton" bottom>
        <template #activator="{ on }">
          <v-btn v-on="on" icon @click="$emit('click:add-to-filter', purchase.supplier)">
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
        </template>
        <div>
          {{ $t('add_to_filter') }}
        </div>
      </v-tooltip>
    </v-card-title>
    <v-card-text class="pb-0">
      <table class="supplier-info-table">
        <tbody>
          <tr v-if="purchase.responsible && purchase.responsible.person">
            <td>{{ $t('responsible_user') }}</td>
            <td>{{ purchase.responsible.person.full_name }}</td>
          </tr>

          <tr v-if="purchase.supplier_contact_person">
            <td>{{ $t('supplier_contact_person') }}</td>
            <td>
              <div>
                <span class="mr-2">{{ purchase.supplier_contact_person.name }}</span>
                <v-tooltip v-if="purchase.supplier_contact_person.comment" max-width="30em" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small>mdi-comment</v-icon>
                  </template>
                  <span class="text-preserve-whitespace">{{
                    purchase.supplier_contact_person.comment
                  }}</span>
                </v-tooltip>
              </div>
              <div v-if="purchase.supplier_contact_person.email">
                <a :href="`mailto:${purchase.supplier_contact_person.email}`" class="link">{{
                  purchase.supplier_contact_person.email
                }}</a>
              </div>
              <div v-if="purchase.supplier_contact_person.phone">
                <a :href="`tel:${purchase.supplier_contact_person.phone}`" class="link">{{
                  purchase.supplier_contact_person.phone
                }}</a>
              </div>
            </td>
          </tr>

          <tr v-if="purchase.last_communication_at">
            <td>{{ $t('last_communication_at') }}</td>
            <td>{{ purchase.last_communication_at.slice(0, 16) }}</td>
          </tr>

          <tr v-if="purchase.comment">
            <td>{{ $t('comment') }}</td>
            <td>{{ purchase.comment }}</td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'InventoryItemPurchaseSupplierCard',

  props: {
    purchase: {
      type: Object,
      required: true,
    },
    showFilterButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.supplier-info-table {
  border-collapse: collapse;
}

.supplier-info-table tr td {
  vertical-align: baseline;
  padding-top: 2px;
  padding-bottom: 2px;
}

.supplier-info-table tr:not(:last-child) td {
  border-bottom: 1px solid #e0e0e0;
}

.supplier-info-table tr:last-child td {
  padding-bottom: 12px;
}

.supplier-info-table tr td:first-child {
  font-weight: 500;
  color: black;
  padding-right: 12px;
}

@media (min-width: 960px) {
  .supplier-info-table tr td:first-child {
    white-space: nowrap;
  }
}
</style>
