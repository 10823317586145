var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"value":_vm.value,"search-input":_vm.query,"error-messages":_vm.errorMessages,"loading":_vm.isDataLoading,"disabled":_vm.disabled,"hint":_vm.autoCompleteHint,"label":_vm.label,"item-text":_vm.itemText,"items":_vm.computedItems,"item-value":_vm.itemValue,"clearable":_vm.clearable,"prepend-icon":_vm.prependedIcon,"append-icon":_vm.appendedIcon,"append-outer-icon":_vm.appendedOuterIcon,"hide-selected":_vm.hideSelected,"multiple":_vm.multiple,"chips":_vm.chips,"deletable-chips":_vm.deletableChips,"hide-no-data":"","return-object":""},on:{"update:searchInput":function($event){_vm.query=$event},"update:search-input":function($event){_vm.query=$event},"blur":function($event){return _vm.$emit('blur', $event)},"click:append":function($event){return _vm.$emit('click:append')},"click:append-outer":function($event){return _vm.$emit('click:append-outer')},"input":_vm.onInput},scopedSlots:_vm._u([(_vm.useCustomItemSlot)?{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._t("item",null,{"item":item})]}}:null,(_vm.useCustomSelectionSlot)?{key:"selection",fn:function(ref){
var item = ref.item;
var on = ref.on;
var selected = ref.selected;
var attr = ref.attr;
return [_vm._t("selection",null,{"item":item,"on":on,"attr":attr,"selected":selected})]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }