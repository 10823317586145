import http from './http';
import getFormData from '@/util/form-data';

const endpoint = 'api/document-library';

const documentLibraryService = {
  getFolder: params => http.get(endpoint, { params }),

  createFolder: folder => http.post(endpoint, folder),

  uploadFile: document => http.post(`${endpoint}/upload-file`, getFormData(document)),

  downloadFile: document =>
    http({
      url: `${endpoint}/${document.id}/${document.file_hash}`,
      method: 'GET',
      responseType: 'blob',
    }),

  rename: document => http.put(`${endpoint}/${document.id}/rename`, document),

  move: document => http.put(`${endpoint}/${document.id}/move`, document),

  delete: document => http.delete(`${endpoint}/${document.id}`, document),
};

export default documentLibraryService;
