<template>
  <div :class="{ 'page-wrapper': !embedded }">
    <div class="d-flex pr-4 pl-2 pb-4 pt-2">
      <h1 class="d-flex align-center title">
        <v-btn v-if="breadcrumbs.length" icon @click="goUp">
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
        <div class="pl-2 d-flex align-center">
          {{ pageTitle }}
          <v-tooltip transition="none" bottom>
            <template #activator="{ on }">
              <v-icon v-on="on" color="info" class="ml-2">mdi-information</v-icon>
            </template>
            <div>
              {{ $t('document_library_hint') }}
            </div>
          </v-tooltip>
        </div>
      </h1>

      <template v-if="!embedded || $vuetify.breakpoint.mdAndUp">
        <v-spacer />
        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          :text="embedded"
          color="primary"
          class="mr-3"
          @click.stop="selectFiles"
        >
          <v-icon left>
            mdi-upload
          </v-icon>
          {{ $t('upload_file') }}
        </v-btn>

        <BasePrimaryActionButton
          :icon="`mdi-${$vuetify.breakpoint.mdAndUp ? 'folder-plus-outline' : 'plus'}`"
          :label="$t('create_folder')"
          :text="embedded"
          breakpoint="mdAndUp"
          @click="onPrimaryButtonClick"
        />
      </template>
    </div>

    <div v-if="embedded && $vuetify.breakpoint.smAndDown" class="d-flex justify-space-between px-3">
      <v-btn color="primary" class="mr-3 mb-3" @click.stop="selectFiles">
        <v-icon left>
          mdi-upload
        </v-icon>
        {{ $t('upload_file') }}
      </v-btn>

      <v-btn class="mb-3" color="primary" @click.stop="newFolder">
        <v-icon left>
          mdi-folder-plus-outline
        </v-icon>
        {{ $t('create_folder') }}
      </v-btn>
    </div>

    <div class="px-4">
      <v-text-field
        v-model="search"
        :label="$t('search_this_folder')"
        prepend-icon="mdi-magnify"
        style="max-width: 360px;"
        clearable
      />
    </div>

    <div :class="{ 'px-3': embedded && $vuetify.breakpoint.smAndUp }">
      <BaseFileDropZone :upload-function="uploadFiles" :file-input-id="fileInputId">
        <template v-slot:content>
          <DocumentLibraryTable
            :selected-items="selectedDocuments"
            :rows="filteredDocuments"
            :loading="isDataLoading"
            :loading-file-id-map="loadingFileIdMap"
            :pending-document-actions-map="pendingDocumentActionsMap"
            @row-click="onRowClick"
            @dblclick:row="onItemDoubleClick"
            @delete="deleteDocument"
            @edit="editDocument"
            @move="openMoveDocumentForm"
          />
        </template>

        <input
          ref="filePicker"
          :id="fileInputId"
          type="file"
          class="d-none"
          @change="uploadFiles"
        />
      </BaseFileDropZone>
    </div>

    <v-dialog
      v-model="isDocumentFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <DocumentLibraryForm
        :dialog="isDocumentFormOpen"
        :form-item="documentFormItem"
        @create="folderCreated"
        @update="crudMixin_updated('document', $event)"
        @cancel="isDocumentFormOpen = false"
      />
    </v-dialog>

    <v-dialog
      v-model="isDocumentMoveFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <DocumentLibraryMoveForm
        :dialog="isDocumentMoveFormOpen"
        :form-item="documentFormItem"
        @update="documentMoved"
        @cancel="isDocumentMoveFormOpen = false"
      />
    </v-dialog>

    <BaseFileViewer
      v-model="isFileViewerDialogOpen"
      :file-url="selectedFileUrl"
      :title="selectedFileTitle"
      :type="selectedFileTitle.toLowerCase().indexOf('pdf') > -1 ? 'pdf' : 'img'"
    />

    <v-bottom-sheet v-model="isActionsSheetOpen">
      <v-sheet class="text-center" height="200px">
        <div class="pa-3 d-flex align-center flex-column">
          <v-btn class="my-3" outlined @click.stop="selectFiles">
            <v-icon left>
              mdi-upload
            </v-icon>
            {{ $t('upload_file') }}
          </v-btn>
          <v-btn class="my-3" outlined @click.stop="newFolder">
            <v-icon left>
              mdi-folder-plus-outline
            </v-icon>
            {{ $t('create_folder') }}
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import crudMixin from '@/mixins/crud-mixin';
import documentLibraryService from '@/api/document-library-service';
import DocumentLibraryTable from '@/components/tables/DocumentLibraryTable';
import BasePrimaryActionButton from '@/components/base/BasePrimaryActionButton';
import BaseFileDropZone from '@/components/base/BaseFileDropZone';
import BaseFileViewer from '@/components/base/BaseFileViewer';
import DocumentLibraryForm from '@/components/forms/DocumentLibraryForm';
import DocumentLibraryMoveForm from '@/components/forms/DocumentLibraryMoveForm';
import { removeItemById } from '@/util/array';
import documentLibraryMixin from '@/mixins/document-library-mixin';

export default {
  name: 'DocumentLibrary',

  components: {
    DocumentLibraryMoveForm,
    DocumentLibraryForm,
    BaseFileViewer,
    BaseFileDropZone,
    BasePrimaryActionButton,
    DocumentLibraryTable,
  },

  mixins: [crudMixin, documentLibraryMixin],

  props: {
    embedded: {
      type: Boolean,
      default: false,
    },

    selectedDocuments: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      breadcrumbs: [],
      pageTitle: '',
      isDataLoading: false,
      documentArray: [], // files and folders
      documentFilterParams: {},

      isDocumentFormOpen: false,
      isDocumentMoveFormOpen: false,
      documentFormItem: {},

      isActionsSheetOpen: false,

      pendingDocumentActionsMap: {},

      fileInputId: 'document-library-file-input',
      search: '',
    };
  },

  computed: {
    filteredDocuments() {
      const q = this.search?.trim().toLowerCase() || '';
      return this.documentArray.filter(d => d.file_name.toLowerCase().includes(q));
    },
  },

  created() {
    this.pageTitle = this.$t('documents');
    this.getFolder();
  },

  methods: {
    goUp() {
      const location = this.breadcrumbs.pop();
      this.documentFilterParams.parent_folder_id = location.id;
      this.pageTitle = location.title;
      this.getFolder();
    },

    async getFolder() {
      this.isDataLoading = true;
      await this.crudMixin_getAll(
        documentLibraryService.getFolder,
        'document',
        this.documentFilterParams,
      );
    },

    onRowClick(document) {
      this.$emit('click:document', document);
    },

    onItemDoubleClick(item) {
      if (item.is_folder) {
        this.breadcrumbs.push({
          id: this.documentFilterParams.parent_folder_id,
          title: this.pageTitle,
        });
        this.pageTitle = item.file_name;
        this.documentFilterParams.parent_folder_id = item.id;
        this.getFolder();
      } else {
        this.openFile(item);
      }
    },

    onPrimaryButtonClick() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.newFolder();
      } else {
        this.isActionsSheetOpen = true;
      }
    },

    newFolder() {
      this.isActionsSheetOpen = false;
      this.documentFormItem = {
        parent_folder_id: this.documentFilterParams.parent_folder_id,
      };
      this.isDocumentFormOpen = true;
    },

    folderCreated(folder) {
      this.documentArray.unshift(folder);
    },

    selectFiles() {
      this.isActionsSheetOpen = false;
      document.getElementById(this.fileInputId).click(); // inside BaseFileDropZone
    },

    editDocument(document) {
      this.documentFormItem = document;
      this.isDocumentFormOpen = true;
    },

    openMoveDocumentForm(document) {
      this.documentFormItem = document;
      this.isDocumentMoveFormOpen = true;
    },

    documentMoved(document) {
      if (document.parent_id !== this.documentFilterParams.parent_folder_id) {
        this.documentArray = removeItemById(this.documentArray, document.id);
      }
    },

    deleteDocument(document) {
      const successMessage = this.$t(`${document.is_folder ? 'folder' : 'document'}_deleted`);
      this.crudMixin_delete(documentLibraryService.delete, 'document', document, successMessage);
    },

    async uploadFiles(files) {
      const { data } = await documentLibraryService.uploadFile({
        parent_folder_id: this.documentFilterParams.parent_folder_id || null,
        file: files[0],
      });
      // add it to array after last folder (folders are always at the top)
      let wasPutInArray = false;
      for (let i = 0; i < this.documentArray.length; i++) {
        if (!this.documentArray[i].is_folder) {
          this.documentArray.splice(i, 0, data);
          wasPutInArray = true;
          break;
        }
      }
      if (!wasPutInArray) {
        this.documentArray.push(data);
      }
    },
  },
};
</script>

<style scoped></style>
