<template>
  <div class="d-flex align-center" style="margin-left: -6px">
    <v-btn icon @click="$emit('change', !checked)">
      <v-icon :color="checked ? 'primary' : ''">
        {{ checked ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
      </v-icon>
    </v-btn>
    <div style="padding-left: 2px">
      <v-label class="v-label clickable grey--text" @click="$emit('change', !checked)">
        {{ label }}
      </v-label>
    </div>
  </div>
</template>

<script>
// v-checkbox wasn't updating in the view when updated externally, so I made this to avoid that issue
export default {
  name: 'BaseCheckbox',

  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    label: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
