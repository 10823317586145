<template>
  <BaseLoadingOverlay :loading="loading" hide-spinner>
    <v-data-table
      :value="selectedItems"
      :expanded="tableMixin_expandedRows"
      :footer-props="{
        'items-per-page-options': [pagination.rowsPerPage],
      }"
      :headers="tableMixin_displayedHeaders"
      :items="rows"
      :mobile-breakpoint="0"
      :page="pagination.page"
      :loading="loading"
      :server-items-length="pagination.totalItems"
      :hide-default-footer="pagination.totalItems <= pagination.rowsPerPage"
      :height="height"
      class="clickable-rows"
      dense
      fixed-header
      disable-sort
      @update:page="tableMixin_changePage"
      @click:row="tableMixin_onRowClick"
      @dblclick:row="onRowDoubleClick"
    >
      <template v-slot:item.title="{ item, value }">
        <div class="d-flex align-center">
          <v-icon> mdi-{{ item.isInventoryItem ? 'package-variant-closed' : 'folder' }} </v-icon>
          <div class="ml-3">
            {{ value }}
          </div>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <BaseActionMenu :actions="actions" :item="item" small-buttons />
      </template>
    </v-data-table>
  </BaseLoadingOverlay>
</template>

<script>
import BaseActionMenu from '../base/BaseActionMenu';
import tableMixin from '../../mixins/table-mixin';
import BaseLoadingOverlay from '@/components/base/BaseLoadingOverlay';

export default {
  name: 'InventoryItemGroupTable',

  components: {
    BaseLoadingOverlay,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    rows: {
      type: Array,
      required: true,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [Number, String],
      default: 'auto',
    },
  },

  data() {
    return {
      actions: [
        {
          callback: p => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: p => this.$emit('delete', p),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ],
      headers: [
        {
          text: this.$t('title'),
          value: 'title',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ],
    };
  },

  methods: {
    onRowDoubleClick(mouseEvent, payload) {
      this.$emit('dblclick:row', payload.item);
    },
  },
};
</script>
