import http from './http';

const endpoint = 'api/estimations';

const estimationService = {
  model: 'estimation',

  send: estimation => http.post(`${endpoint}`, estimation),
};

export default estimationService;
