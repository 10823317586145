import http from './http';

const endpoint = 'api/inventory-item-price-history';

const inventoryItemPriceHistoryService = {
  model: 'inventoryItemPriceHistory',

  getPage: (page, params) => http.get(endpoint, { params: { ...params, page } }),

  getById: historyEntry => http.get(`${endpoint}/${historyEntry.id}`, historyEntry),

  create: historyEntry => http.post(`${endpoint}`, historyEntry),

  update: historyEntry => http.put(`${endpoint}/${historyEntry.id}`, historyEntry),

  delete: historyEntry => http.delete(`${endpoint}/${historyEntry.id}`),
};

export default inventoryItemPriceHistoryService;
