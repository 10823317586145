var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseLoadingOverlay',{attrs:{"loading":_vm.loading,"hide-spinner":""}},[_c('v-data-table',{staticClass:"clickable-rows",attrs:{"value":_vm.selectedItems,"expanded":_vm.tableMixin_expandedRows,"footer-props":{
      'items-per-page-options': [_vm.pagination.rowsPerPage],
    },"headers":_vm.tableMixin_displayedHeaders,"items":_vm.rows,"mobile-breakpoint":0,"page":_vm.pagination.page,"loading":_vm.loading,"server-items-length":_vm.pagination.totalItems,"hide-default-footer":_vm.pagination.totalItems <= _vm.pagination.rowsPerPage,"height":_vm.height,"dense":"","fixed-header":"","disable-sort":""},on:{"update:page":_vm.tableMixin_changePage,"click:row":_vm.tableMixin_onRowClick,"dblclick:row":_vm.onRowDoubleClick},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
    var item = ref.item;
    var value = ref.value;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',[_vm._v(" mdi-"+_vm._s(item.isInventoryItem ? 'package-variant-closed' : 'folder')+" ")]),_c('div',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(value)+" ")])],1)]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.actions,"item":item,"small-buttons":""}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }