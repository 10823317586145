import http from './http';

const endpoint = 'api/supplier-inquiry-messages';

const supplierInquiryService = {
  model: 'supplierInquiryMessage',

  getPage: (page, params) => http.get(endpoint, { params: { ...params, page } }),

  getById: supplierInquiryMessage => http.get(`${endpoint}/${supplierInquiryMessage.id}`),

  create: supplierInquiryMessage => http.post(`${endpoint}`, supplierInquiryMessage),

  update: supplierInquiryMessage =>
    http.put(`${endpoint}/${supplierInquiryMessage.id}`, supplierInquiryMessage),

  delete: supplierInquiryMessage => http.delete(`${endpoint}/${supplierInquiryMessage.id}`),
};

export default supplierInquiryService;
