import http from './http';
import i18n from '@/i18n/i18n-config';
import eventBus, { UPDATE_INVENTORY_ITEM_QUANTITIES_IN_WAREHOUSE } from '@/util/event-bus';

const endpoint = 'api/inventory-item-purchases';

export function getDefaultInventoryItemPurchaseFormItem() {
  return {
    material_delivered_by: 'us',
    status: 'not_ordered',
  };
}

const inventoryItemPurchaseService = {
  model: 'inventoryItemPurchase',

  getPage: (page, params) => http.get(endpoint, { params: { ...params, page } }),

  getById: (id, params = {}) => http.get(`${endpoint}/${id}`, { params }),

  search: query => http.get(`${endpoint}/find/${query}`),

  create: async inventoryItemPurchase => {
    const res = await http.post(`${endpoint}`, inventoryItemPurchase);
    eventBus.$emit(UPDATE_INVENTORY_ITEM_QUANTITIES_IN_WAREHOUSE, res.data.inventory_item);
    return res;
  },

  update: async inventoryItemPurchase => {
    const res = await http.put(`${endpoint}/${inventoryItemPurchase.id}`, inventoryItemPurchase);
    eventBus.$emit(UPDATE_INVENTORY_ITEM_QUANTITIES_IN_WAREHOUSE, res.data.inventory_item);
    return res;
  },

  delete: inventoryItemPurchase => http.delete(`${endpoint}/${inventoryItemPurchase.id}`),
};

export const INVENTORY_ITEM_PURCHASE_STATUSES = [
  {
    text: i18n.t('not_ordered'),
    value: 'not_ordered',
    color: 'grey lighten-4',
    hexColor: '#F5F5F5',
    filterLabel: i18n.t('show_not_ordered'),
  },
  {
    text: i18n.t('inquired'),
    value: 'inquired',
    color: 'yellow lighten-4',
    hexColor: '#FFF9C4',
    filterLabel: i18n.t('show_inquired'),
  },
  {
    text: i18n.t('ordered'),
    value: 'ordered',
    color: 'blue lighten-4',
    hexColor: '#BBDEFB',
    filterLabel: i18n.t('show_ordered'),
  },
  {
    text: i18n.t('arrived'),
    value: 'arrived',
    color: 'green accent-1',
    hexColor: '#B9F6CA',
    filterLabel: i18n.t('show_arrived'),
  },
];

export const getInventoryItemPurchaseStatusMap = () => {
  const map = {};
  INVENTORY_ITEM_PURCHASE_STATUSES.forEach(status => {
    map[status.value] = { ...status };
  });
  return map;
};

export default inventoryItemPurchaseService;
