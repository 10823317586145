<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
        <v-spacer />
        <InventoryItemPriceHistoryListDialog
          v-if="inventoryItemForPriceHistory"
          :inventory-item="inventoryItemForPriceHistory"
          class="mr-2"
        />
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="6">
            <BaseAutocomplete
              v-if="!inventoryItem.id"
              v-model="inventoryItemPurchase.inventory_item_id"
              :initial-item="inventoryItemPurchase.inventory_item"
              :error-messages="errors.inventory_item_id"
              :label="formMixin_getRequiredFieldLabel($t('inventory_item'))"
              :search-function="searchInventoryItems"
              :key="inventoryItemPurchase.id"
              item-text="title"
              clearable
              @input="formMixin_clearErrors('inventory_item_id')"
              @update:initial-item="$set(inventoryItemPurchase, 'inventory_item', $event)"
            />
          </v-col>

          <v-col cols="12" md="6">
            <BaseAutocomplete
              v-model="inventoryItemPurchase.responsible_user_id"
              :initial-item="inventoryItemPurchase.responsible"
              :search-function="searchUsers"
              :key="inventoryItemPurchase.id"
              :error-messages="errors.responsible_user_id"
              :label="$t('responsible_user')"
              item-text="full_name"
              @input="formMixin_clearErrors('responsible_user_id')"
            />
          </v-col>

          <v-col cols="12">
            <OrderPartMultipleAutocomplete
              v-model="inventoryItemPurchase.reserved_for_order_parts"
              :error-messages="errors.reserved_for_order_parts"
              @input="formMixin_clearErrors('reserved_for_order_parts')"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-radio-group v-model="supplierSelectionMode" row>
              <v-radio :label="$t('select_supplier')" value="selectSupplier" />
              <v-radio :label="$t('send_inquiries')" value="sendInquiries" />
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row v-show="supplierSelectionMode === 'sendInquiries'" dense>
          <v-col cols="12">
            <SupplierInquiries
              v-model="inventoryItemPurchase.inquiries"
              :error-messages="errors.inquiries"
              :inventory-item-id="inventoryItemPurchase.inventory_item_id"
              :inventory-item-purchase-id="inventoryItemPurchase.id"
              @input="onInquiriesInput"
            />
          </v-col>
        </v-row>

        <v-row v-show="supplierSelectionMode === 'selectSupplier'" dense>
          <v-col cols="12" md="6">
            <BaseAutocomplete
              v-model="inventoryItemPurchase.supplier_contact_id"
              :initial-item="inventoryItemPurchase.supplier"
              :search-function="searchSuppliers"
              :key="inventoryItemPurchase.id"
              :error-messages="errors.supplier_contact_id"
              :label="$t('supplier')"
              @input="formMixin_clearErrors('supplier_contact_id')"
              @update:initial-item="setSupplierContacts($event, true)"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              v-if="inventoryItemPurchase.supplier_contact_id"
              v-model="inventoryItemPurchase.supplier_contact_person_id"
              :items="supplierContactPersons"
              :error-messages="errors.supplier_contact_person_id"
              :loading="
                $store.getters.loading[
                  `get:api/contacts/${inventoryItemPurchase.supplier_contact_id}`
                ]
              "
              :label="$t('supplier_contact_person')"
              append-icon="mdi-plus"
              item-text="name"
              item-value="id"
              clearable
              @click:append="isContactPersonFormOpen = true"
              @change="formMixin_clearErrors('supplier_contact_person_id')"
            />
          </v-col>
        </v-row>

        <v-row v-if="inventoryItemPurchase.supplier_contact_id" dense>
          <v-col cols="12" md="6">
            <v-textarea
              v-model="inventoryItemPurchase.comment"
              :error-messages="errors['comment']"
              :label="$t('comment')"
              rows="1"
              auto-grow
              @input="formMixin_clearErrors('comment')"
            />
          </v-col>

          <v-col cols="12" md="6">
            <BaseDateTimePicker v-model="inventoryItemPurchase.last_communication_at">
              <v-text-field
                v-model="inventoryItemPurchase.last_communication_at"
                :error-messages="errors['last_communication_at']"
                :label="$t('last_communication_at')"
                @input="formMixin_clearErrors('last_communication_at')"
              />
            </BaseDateTimePicker>
          </v-col>

          <v-col cols="12">
            <v-divider class="mb-4 mt-2" />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" md="6">
            <v-radio-group
              v-if="unitATitle || unitBTitle"
              v-model="inventoryItemPurchase.material_unit"
              :error-messages="errors['material_unit']"
              :label="formMixin_getRequiredFieldLabel($t('material_unit'))"
              @change="
                formMixin_clearErrors('material_unit');
                onMaterialUnitChange($event);
              "
            >
              <v-radio v-if="unitATitle" :label="unitATitle" value="a" />
              <v-radio v-if="unitBTitle" :label="unitBTitle" value="b" />
            </v-radio-group>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="inventoryItemPurchase.quantity_ordered"
              :error-messages="errors['quantity_ordered']"
              :label="formMixin_getRequiredFieldLabel($t('quantity_ordered'))"
              type="number"
              @blur="formMixin_clearErrors('quantity_ordered')"
              @input="updateMaterialCost($event, costPerUnit)"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="inventoryItemPurchase.cost_per_unit_a"
              :error-messages="errors['cost_per_unit_a']"
              :label="getPricePerUnitALabel($t('cost_per_unit_a'))"
              type="number"
              @blur="formMixin_clearErrors('cost_per_unit_a')"
              @input="
                updateCostPerUnitB($event);
                updateMaterialCost(inventoryItemPurchase.quantity_ordered, costPerUnit);
              "
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="inventoryItemPurchase.cost_per_unit_b"
              :error-messages="errors['cost_per_unit_b']"
              :label="getPricePerUnitBLabel($t('cost_per_unit_b'))"
              type="number"
              @blur="formMixin_clearErrors('cost_per_unit_b')"
              @input="
                updateCostPerUnitA($event);
                updateMaterialCost(inventoryItemPurchase.quantity_ordered, costPerUnit);
              "
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="inventoryItemPurchase.gross_cost_per_unit_a"
              :error-messages="errors['gross_cost_per_unit_a']"
              :label="getPricePerUnitALabel($t('gross_cost_per_unit_a'))"
              type="number"
              @blur="formMixin_clearErrors('gross_cost_per_unit_a')"
              @input="updateGrossCostPerUnitB($event)"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="inventoryItemPurchase.gross_cost_per_unit_b"
              :error-messages="errors['gross_cost_per_unit_b']"
              :label="getPricePerUnitBLabel($t('gross_cost_per_unit_b'))"
              type="number"
              @blur="formMixin_clearErrors('gross_cost_per_unit_b')"
              @input="updateGrossCostPerUnitA($event)"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="inventoryItemPurchase.sell_price_per_unit_a"
              :error-messages="errors['sell_price_per_unit_a']"
              :label="getPricePerUnitALabel($t('sell_price_per_unit_a'))"
              type="number"
              @blur="formMixin_clearErrors('sell_price_per_unit_a')"
              @input="updateSellPricePerUnitB"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="inventoryItemPurchase.sell_price_per_unit_b"
              :error-messages="errors['sell_price_per_unit_b']"
              :label="getPricePerUnitBLabel($t('sell_price_per_unit_b'))"
              type="number"
              @blur="formMixin_clearErrors('sell_price_per_unit_b')"
              @input="updateSellPricePerUnitA"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="inventoryItemPurchase.material_cost"
              :error-messages="errors['material_cost']"
              :label="$t('material_cost')"
              type="number"
              @blur="formMixin_clearErrors('material_cost')"
              @input="updateCostPerUnit($event, inventoryItemPurchase.quantity_ordered)"
            />
          </v-col>

          <v-col cols="12" md="6">
            <BaseDatepickerInput
              v-model="inventoryItemPurchase.planned_arrival_at"
              :error-messages="errors['planned_arrival_at']"
              :label="$t('planned_arrival_at')"
              @input="formMixin_clearErrors('planned_arrival_at')"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" md="6">
            <v-radio-group
              v-model="inventoryItemPurchase.material_delivered_by"
              :error-messages="errors['material_delivered_by']"
              :label="formMixin_getRequiredFieldLabel($t('material_delivered_by'))"
              class="mt-0"
              @change="formMixin_clearErrors('material_delivered_by')"
            >
              <v-radio
                v-for="type in deliveredByTypes"
                :key="type.value"
                :label="type.text"
                :value="type.value"
              />
            </v-radio-group>
          </v-col>

          <v-col cols="12" md="6">
            <v-radio-group
              v-model="inventoryItemPurchase.status"
              :error-messages="errors['status']"
              :label="formMixin_getRequiredFieldLabel($t('status'))"
              class="mt-0"
              @change="formMixin_clearErrors('status')"
            >
              <v-radio
                v-for="status in inventoryItemPurchaseStatuses"
                :key="status.id"
                :label="status.text"
                :value="status.value"
              />
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!inventoryItemPurchase.id" class="subtitle-2 ml-3">
          * {{ $t('must_be_filled') }}
        </span>

        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-if="inventoryItemPurchase.supplier_contact_id"
      v-model="isContactPersonFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="700"
      persistent
      scrollable
    >
      <ContactPersonForm
        :dialog="isContactPersonFormOpen"
        :contact="inventoryItemPurchase.supplier"
        @cancel="isContactPersonFormOpen = false"
        @update="newContactPersonCreated"
      />
    </v-dialog>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import inventoryItemPurchaseService, {
  INVENTORY_ITEM_PURCHASE_STATUSES,
} from '@/api/inventory-item-purchase-service';
import BaseAutocomplete from '@/components/base/BaseAutocomplete';
import contactService from '@/api/contact-service';
import { ORDER_PART_DELIVERED_BY_TYPES } from '@/api/order-part-service';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';
import { round } from '@/util/numbers';
import inventoryItemService from '@/api/inventory-item-service';
import { getInventoryItemQuantityForBackend } from '@/util/inventory-item-quantity';
import userService from '@/api/user-service';
import ContactPersonForm from '@/components/forms/ContactPersonForm';
import BaseDateTimePicker from '@/components/base/BaseDateTimePicker';
import OrderPartMultipleAutocomplete from '@/components/autocompletes/OrderPartMultipleAutocomplete';
import { collectMultiSelectErrors } from '@/util/forms';
import InventoryItemPriceHistoryListDialog from '@/components/InventoryItemPriceHistoryListDialog';
import SupplierInquiries from '@/components/form-fields/SupplierInquiries';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import supplierInquiryService from '@/api/supplier-inquiry-service';
import { updateArrayItem } from '@/util/array';

export default {
  name: 'InventoryItemPurchaseForm',

  components: {
    SupplierInquiries,
    InventoryItemPriceHistoryListDialog,
    OrderPartMultipleAutocomplete,
    BaseDateTimePicker,
    ContactPersonForm,
    BaseDatepickerInput,
    BaseAutocomplete,
  },

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    formItem: Object,
    inventoryItem: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      supplierSelectionMode: 'selectSupplier', // 'sendInquiries'
      supplierContactPersons: [],
      errors: {},
      isRequestPending: false,
      inventoryItemPurchase: {},
      oldMaterialUnit: null,

      isContactPersonFormOpen: false,
    };
  },

  computed: {
    formTitle() {
      if (!this.inventoryItem.id) {
        return this.$t(
          this.inventoryItemPurchase.id
            ? 'edit_inventory_item_purchase'
            : 'new_inventory_item_purchase',
        ).replace('"{0}"', '');
      }
      return this.$t(
        this.inventoryItemPurchase.id
          ? 'edit_inventory_item_purchase'
          : 'new_inventory_item_purchase',
      ).replace('{0}', this.inventoryItem.title);
    },

    deliveredByTypes() {
      return ORDER_PART_DELIVERED_BY_TYPES;
    },

    inventoryItemPurchaseStatuses() {
      return INVENTORY_ITEM_PURCHASE_STATUSES;
    },

    unitATitle() {
      return (
        this.inventoryItem?.unit_a_title || this.inventoryItemPurchase.inventory_item?.unit_a_title
      );
    },

    unitBTitle() {
      return (
        this.inventoryItem?.unit_b_title || this.inventoryItemPurchase.inventory_item?.unit_b_title
      );
    },

    unitABRatio() {
      return (
        this.inventoryItemPurchase?.inventory_item?.unit_a_b_ratio ||
        this.inventoryItem?.unit_a_b_ratio
      );
    },

    costPerUnit() {
      if (
        this.inventoryItemPurchase.material_unit === this.unitATitle &&
        +this.inventoryItemPurchase.cost_per_unit_a
      ) {
        return +this.inventoryItemPurchase.cost_per_unit_a;
      }
      if (
        this.inventoryItemPurchase.material_unit === 'b' &&
        +this.inventoryItemPurchase.cost_per_unit_b
      ) {
        return +this.inventoryItemPurchase.cost_per_unit_b;
      }
      return null;
    },

    inventoryItemForPriceHistory() {
      if (this.inventoryItem?.id) {
        return this.inventoryItem;
      }

      return this.inventoryItemPurchase.inventory_item?.id
        ? this.inventoryItemPurchase.inventory_item
        : null;
    },
  },

  methods: {
    searchSuppliers: contactService.searchSuppliers,
    searchUsers: userService.search,
    searchInventoryItems: inventoryItemService.search,

    getPricePerUnitALabel(label) {
      let fullLabel = label;
      const unitATitle =
        this.inventoryItemPurchase?.inventory_item?.unit_a_title ||
        this.inventoryItem?.unit_a_title;
      if (unitATitle) {
        fullLabel += ` (${unitATitle})`;
      }
      return fullLabel;
    },

    getPricePerUnitBLabel(label) {
      let fullLabel = label;
      const unitBTitle =
        this.inventoryItemPurchase?.inventory_item?.unit_b_title ||
        this.inventoryItem?.unit_b_title;
      if (unitBTitle) {
        fullLabel += ` (${unitBTitle})`;
      }
      return fullLabel;
    },

    setSupplierContacts(supplier, shouldSelectFirst = false) {
      this.inventoryItemPurchase.supplier = supplier;
      if (!supplier) {
        this.supplierContactPersons = [];
        return;
      }

      if (supplier.persons) {
        this.supplierContactPersons = supplier.persons;
        if (shouldSelectFirst && this.supplierContactPersons.length) {
          this.inventoryItemPurchase.supplier_contact_person_id = supplier.persons[0].id;
        }
        return;
      }

      contactService.getById(supplier.id).then(res => {
        this.supplierContactPersons = res.data.persons;
        this.inventoryItemPurchase.supplier.persons = res.data.persons;
        if (shouldSelectFirst && this.supplierContactPersons.length) {
          this.inventoryItemPurchase.supplier_contact_person_id = this.supplierContactPersons[0].id;
        }
      });
    },

    newContactPersonCreated(contact) {
      const newPerson = contact.persons.pop();
      this.supplierContactPersons.push(newPerson);
      this.inventoryItemPurchase.supplier.persons.push(newPerson);
      this.inventoryItemPurchase.supplier_contact_person_id = newPerson.id;
    },

    onDialogOpen() {
      if (this.inventoryItemPurchase.inquiries?.length) {
        this.supplierSelectionMode = 'sendInquiries';
      }

      this.inventoryItemPurchase = JSON.parse(JSON.stringify(this.formItem));

      if (this.inventoryItemPurchase.responsible) {
        this.inventoryItemPurchase.responsible = {
          id: this.inventoryItemPurchase.responsible.id,
          full_name: this.inventoryItemPurchase.responsible.person.full_name,
        };
      }

      if (this.inventoryItemPurchase.supplier_contact_person) {
        this.supplierContactPersons.push(this.inventoryItemPurchase.supplier_contact_person);
      }

      if (this.inventoryItemPurchase.supplier) {
        this.setSupplierContacts(this.inventoryItemPurchase.supplier);
      }

      if (typeof this.inventoryItemPurchase.last_communication_at === 'string') {
        this.inventoryItemPurchase.last_communication_at = this.inventoryItemPurchase.last_communication_at.slice(
          0,
          16,
        );
      }

      if (
        +this.inventoryItemPurchase.quantity_ordered &&
        this.inventoryItemPurchase.material_unit === 'b' &&
        this.unitABRatio
      ) {
        this.inventoryItemPurchase.quantity_ordered =
          +this.inventoryItemPurchase.quantity_ordered / this.unitABRatio;
      }
      this.oldMaterialUnit = this.inventoryItemPurchase.material_unit;
      this.errors = {};
    },

    updateCostPerUnit(materialCost, quantityOrdered) {
      if (!+materialCost || !+quantityOrdered) {
        return;
      }
      if (
        this.inventoryItemPurchase.material_unit &&
        this.inventoryItemPurchase.material_unit === 'a'
      ) {
        this.$set(
          this.inventoryItemPurchase,
          'cost_per_unit_a',
          round(+materialCost / +quantityOrdered, 4),
        );
        this.updateCostPerUnitB(this.inventoryItemPurchase.cost_per_unit_a);
      } else if (
        this.inventoryItemPurchase.material_unit &&
        this.inventoryItemPurchase.material_unit === 'b'
      ) {
        this.$set(
          this.inventoryItemPurchase,
          'cost_per_unit_b',
          round(+materialCost / +quantityOrdered, 4),
        );
        this.updateCostPerUnitA(this.inventoryItemPurchase.cost_per_unit_b);
      }
    },

    updateCostPerUnitA(pricePerUnitB) {
      const abRatio =
        this.inventoryItem.unit_a_b_ratio ||
        this.inventoryItemPurchase.inventory_item?.unit_a_b_ratio;
      if (!+pricePerUnitB || !+abRatio) {
        return;
      }
      this.$set(this.inventoryItemPurchase, 'cost_per_unit_a', round(+pricePerUnitB / +abRatio, 4));
    },

    updateCostPerUnitB(pricePerUnitA) {
      const abRatio =
        this.inventoryItem.unit_a_b_ratio ||
        this.inventoryItemPurchase.inventory_item?.unit_a_b_ratio;
      if (!+pricePerUnitA || !+abRatio) {
        return;
      }
      this.$set(this.inventoryItemPurchase, 'cost_per_unit_b', round(+pricePerUnitA * +abRatio, 4));
    },

    updateGrossCostPerUnitA(pricePerUnitB) {
      const abRatio =
        this.inventoryItem.unit_a_b_ratio ||
        this.inventoryItemPurchase.inventory_item?.unit_a_b_ratio;
      if (!+pricePerUnitB || !+abRatio) {
        return;
      }
      this.$set(
        this.inventoryItemPurchase,
        'gross_cost_per_unit_a',
        round(+pricePerUnitB / +abRatio, 4),
      );
    },

    updateGrossCostPerUnitB(pricePerUnitA) {
      const abRatio =
        this.inventoryItem.unit_a_b_ratio ||
        this.inventoryItemPurchase.inventory_item?.unit_a_b_ratio;
      if (!+pricePerUnitA || !+abRatio) {
        return;
      }
      this.$set(
        this.inventoryItemPurchase,
        'gross_cost_per_unit_b',
        round(+pricePerUnitA * +abRatio, 4),
      );
    },

    updateSellPricePerUnitA(pricePerUnitB) {
      const abRatio =
        this.inventoryItem.unit_a_b_ratio ||
        this.inventoryItemPurchase.inventory_item?.unit_a_b_ratio;
      if (!+pricePerUnitB || !+abRatio) {
        return;
      }
      this.$set(
        this.inventoryItemPurchase,
        'sell_price_per_unit_a',
        round(+pricePerUnitB / +abRatio, 4),
      );
    },

    updateSellPricePerUnitB(pricePerUnitA) {
      const abRatio =
        this.inventoryItem.unit_a_b_ratio ||
        this.inventoryItemPurchase.inventory_item?.unit_a_b_ratio;
      if (!+pricePerUnitA || !+abRatio) {
        return;
      }
      this.$set(
        this.inventoryItemPurchase,
        'sell_price_per_unit_b',
        round(+pricePerUnitA * +abRatio, 4),
      );
    },

    onMaterialUnitChange(selectedUnit) {
      if (!this.unitABRatio || !this.inventoryItemPurchase.quantity_ordered) {
        this.oldMaterialUnit = selectedUnit;
        return;
      }
      if (
        this.oldMaterialUnit === 'a' &&
        selectedUnit === 'b' &&
        +this.inventoryItemPurchase.cost_per_unit_b
      ) {
        this.$set(
          this.inventoryItemPurchase,
          'material_cost',
          round(
            +this.inventoryItemPurchase.quantity_ordered *
              +this.inventoryItemPurchase.cost_per_unit_b,
            4,
          ),
        );
      } else if (
        this.oldMaterialUnit === 'b' &&
        selectedUnit === 'a' &&
        +this.inventoryItemPurchase.cost_per_unit_a
      ) {
        this.$set(
          this.inventoryItemPurchase,
          'material_cost',
          round(
            +this.inventoryItemPurchase.quantity_ordered *
              +this.inventoryItemPurchase.cost_per_unit_a,
            4,
          ),
        );
      }
      this.oldMaterialUnit = selectedUnit;
    },

    updateMaterialCost(materialQuantity, costPerUnit) {
      if (!+materialQuantity || !+costPerUnit) {
        return;
      }
      this.$set(this.inventoryItemPurchase, 'material_cost', +materialQuantity * +costPerUnit);
    },

    onInquiriesInput(inquiries) {
      if (inquiries.length && this.supplierSelectionMode !== 'sendInquiries') {
        this.supplierSelectionMode = 'sendInquiries';
      }
      this.formMixin_clearErrors('inquiries');
    },

    async onSubmit() {
      const unsavedSupplierInquiries =
        this.inventoryItemPurchase.inquiries?.filter(inquiry => !inquiry.id) || [];
      if (unsavedSupplierInquiries.length && this.inventoryItemPurchase.id) {
        const confirmationDialogValue = await openConfirmDialog({
          title: this.$t('not_sent_inquiries_will_not_be_saved'),
          customActions: [
            { text: this.$t('return_to_editing'), value: 'returnToEditing' },
            { text: this.$t('save_without_sending'), value: 'saveWithoutSending' },
            { text: this.$t('send_and_save'), value: 'sendAndSave' },
          ],
        });

        if (confirmationDialogValue === 'returnToEditing') {
          return;
        }

        if (confirmationDialogValue === 'sendAndSave') {
          // send the inquiries. If it fails, don't proceed to saving the purchase
          const requests = [];
          for (let i = 0; i < unsavedSupplierInquiries.length; i++) {
            requests.push(
              supplierInquiryService.create(unsavedSupplierInquiries[i]).then(res => {
                const updatedInquiries = updateArrayItem(
                  this.inventoryItemPurchase.inquiries,
                  res.data,
                );
                this.$set(this.inventoryItemPurchase, 'inquiries', updatedInquiries);
              }),
            );
          }

          try {
            await Promise.all(requests);
          } catch (e) {
            openSnackbar(this.$t('failed_to_send_inquiries'));
            return;
          }
        }
      }

      // save the purchase
      const payload = JSON.parse(JSON.stringify(this.inventoryItemPurchase));
      payload.quantity_ordered = getInventoryItemQuantityForBackend(
        payload.quantity_ordered,
        payload.material_unit,
        this.unitABRatio,
      );
      await this.crudMixin_createOrUpdate(inventoryItemPurchaseService, payload);
      this.errors.inquiries = collectMultiSelectErrors(this.errors, 'inquiries');
    },
  },
};
</script>
