<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :footer-props="{
      'items-per-page-options': [pagination.rowsPerPage || 50],
    }"
    :headers="tableMixin_displayedHeaders"
    :items="rows"
    :mobile-breakpoint="0"
    :page="pagination.page || 1"
    :server-items-length="pagination.totalItems || -1"
    :loading="loading"
    :class="{ 'content-loading': loading }"
    disable-sort
    @update:page="tableMixin_changePage"
    @click:row="tableMixin_onRowClick"
  >
    <template v-slot:item.type="{ item }">
      {{ $t(`inventory_item_price_history_types.${item.type}`) }}
    </template>

    <template v-slot:item.contact_id="{ item }">
      {{ item.contact ? item.contact.name : '' }}
    </template>

    <template v-slot:item.comments="{ item }">
      <v-tooltip
        v-if="item.comments"
        transition="none"
        max-width="30em"
        content-class="tooltip-in-dialog"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-comment</v-icon>
        </template>
        <div class="text-pre-wrap">{{ item.comments }}</div>
      </v-tooltip>
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu :actions="getRowActions()" :item="item" />
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.type>
          {{ $t(`inventory_item_price_history_types.${item.type}`) }}
        </template>

        <template v-slot:item.contact_id>
          {{ item.contact ? item.contact.name : '' }}
        </template>

        <template v-slot:item.comments>
          <div class="text-pre-wrap">{{ item.comments }}</div>
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import BaseActionMenu from '../base/BaseActionMenu';
import BaseExpandedTableRow from '../base/BaseExpandedTableRow';
import tableMixin from '../../mixins/table-mixin';

export default {
  name: 'InventoryItemPriceHistoryTable',

  components: {
    BaseExpandedTableRow,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    rows: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('date'),
          value: 'date',
        },
        {
          text: this.$t('type'),
          value: 'type',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('contact'),
          value: 'contact_id',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('price_in_euros'),
          value: 'price',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('comments'),
          value: 'comments',
          hidden: 'smAndDown',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ];
    },
  },

  methods: {
    getRowActions() {
      return [
        {
          callback: p => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: p => this.$emit('delete', p),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ];
    },
  },
};
</script>
