<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12" lg="9">
            <v-text-field
              v-model="inventoryItem.title"
              :error-messages="errors['title']"
              :label="formMixin_getRequiredFieldLabel($t('title'))"
              @blur="formMixin_clearErrors('title')"
            />
          </v-col>
        </v-row>
         <v-row dense>
          <v-col cols="12" lg="3">
            <v-text-field
              v-model="inventoryItem.code"
              :error-messages="errors['code']"
              :label="$t('inventory_item_code')"
              @blur="formMixin_clearErrors('code')"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <InventoryItemGroupPicker nudge-bottom="0" left @input="onGroupSelect">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  :value="
                    inventoryItem.inventory_item_group
                      ? inventoryItem.inventory_item_group.title
                      : ''
                  "
                  :error-messages="errors['inventory_item_group_id']"
                  :label="$t('inventory_item_group')"
                  readonly
                />
              </template>
            </InventoryItemGroupPicker>
          </v-col>

          <v-col cols="12" sm="6">
            <v-radio-group
              v-model="inventoryItem.type"
              :error-messages="errors['type']"
              :items="inventoryItemTypes"
              style="min-height: 56px"
              row
              @change="formMixin_clearErrors('type')"
            >
              <v-radio
                v-for="type in inventoryItemTypes"
                :key="type.value"
                :label="type.text"
                :value="type.value"
              />
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <BaseFileDropZone
              v-if="formItem.documents && formItem.documents.length"
              :current-files="formItem.documents"
              :delete-function="file => deleteFile(formItem, file)"
            />

            <v-file-input
              v-model="inventoryItem.documents"
              :error-messages="documentErrors"
              :label="$t('attach_files')"
              accept=".jpeg,.png,.jpg,.gif,.svg,.pdf,.dwg,.dxf"
              multiple
              chips
              small-chips
              @change="formMixin_clearErrors('documents')"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="inventoryItem.unit_a_title"
              :error-messages="errors['unit_a_title']"
              :label="formMixin_getRequiredFieldLabel($t('unit_a_title'))"
              @blur="formMixin_clearErrors('unit_a_title')"
              @input="$set(inventoryItem, 'material_unit', null)"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="inventoryItem.unit_b_title"
              :error-messages="errors['unit_b_title']"
              :label="
                inventoryItem.type === 'bought'
                  ? formMixin_getRequiredFieldLabel($t('unit_b_title'))
                  : $t('unit_b_title')
              "
              @blur="formMixin_clearErrors('unit_b_title')"
              @input="$set(inventoryItem, 'material_unit', null)"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="inventoryItem.unit_a_ratio_amount"
              :error-messages="errors['unit_a_ratio_amount']"
              :label="$t('unit_ratio_amount_label').replace('{0}', unitATitle)"
              @blur="formMixin_clearErrors('unit_a_ratio_amount')"
              @input="updateUnitABRatio($event, inventoryItem.unit_b_ratio_amount)"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="inventoryItem.unit_b_ratio_amount"
              :error-messages="errors['unit_b_ratio_amount']"
              :label="$t('unit_ratio_amount_label').replace('{0}', unitBTitle)"
              @blur="formMixin_clearErrors('unit_b_ratio_amount')"
              @input="updateUnitABRatio(inventoryItem.unit_a_ratio_amount, $event)"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-radio-group
              v-if="inventoryItem.unit_a_title || inventoryItem.unit_b_title"
              v-model="inventoryItem.material_unit"
              :error-messages="errors['material_unit']"
              :label="$t('material_unit')"
              @change="onMaterialUnitChange"
            >
              <v-radio
                v-if="inventoryItem.unit_a_title"
                :label="inventoryItem.unit_a_title"
                value="a"
              />
              <v-radio
                v-if="inventoryItem.unit_b_title"
                :label="inventoryItem.unit_b_title"
                value="b"
              />
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="inventoryItem.unit_a_b_ratio"
              :error-messages="errors['unit_a_b_ratio']"
              :label="unitABRatioLabel"
              @blur="formMixin_clearErrors('unit_a_b_ratio')"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-if="inventoryItem.id"
              :value="quantityInWarehouse"
              :error-messages="errors['quantity_in_warehouse']"
              :label="
                `${$t('quantity_available')} + ${$t('quantity_of_legacy_stock').toLowerCase()}`
              "
              class="pb-2"
              disabled
              @blur="formMixin_clearErrors('quantity_in_warehouse')"
            />

            <v-text-field
              v-model="inventoryItem.quantity_of_legacy_stock"
              :error-messages="errors['quantity_of_legacy_stock']"
              :label="$t('quantity_of_legacy_stock')"
              type="number"
              @blur="formMixin_clearErrors('quantity_of_legacy_stock')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model.number="inventoryItem.min_threshold"
              :error-messages="errors['min_threshold']"
              :label="$t('min_threshold')"
              type="number"
              @blur="formMixin_clearErrors('min_threshold')"
            >
              <template v-slot:append-outer>
                <v-tooltip transition="none" bottom>
                  <template #activator="{ on }">
                    <v-icon v-on="on">mdi-help-circle</v-icon>
                  </template>
                  <div>
                    {{ $t('min_threshold_hint') }}
                  </div>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!inventoryItem.id" class="subtitle-2 ml-3"> * {{ $t('must_be_filled') }} </span>

        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import inventoryItemService, { INVENTORY_ITEM_TYPES } from '@/api/inventory-item-service';
import { round } from '@/util/numbers';
import InventoryItemGroupPicker from '@/components/InventoryItemGroupPicker';
import { getInventoryItemQuantityNumber } from '@/util/inventory-item-quantity';
import BaseFileDropZone from '@/components/base/BaseFileDropZone';
import documentService from '@/api/document-service';

export default {
  name: 'InventoryItemForm',

  components: { BaseFileDropZone, InventoryItemGroupPicker },

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    formItem: Object,
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      inventoryItem: {},
      initialQuantityOfLegacyStock: 0,
    };
  },

  computed: {
    formTitle() {
      return this.$t(this.inventoryItem.id ? 'edit_inventory_item' : 'new_inventory_item');
    },

    inventoryItemTypes() {
      return INVENTORY_ITEM_TYPES;
    },

    documentErrors() {
      let documentErrors = [];
      Object.keys(this.errors).forEach(error => {
        if (!error.includes('documents')) {
          return;
        }
        documentErrors = [...documentErrors, ...this.errors[error]];
      });
      return documentErrors;
    },

    quantityInWarehouse() {
      const {
        quantity_in_warehouse,
        quantity_of_legacy_stock,
        material_unit,
        unit_a_title,
        unit_b_title,
        unit_a_b_ratio,
      } = this.inventoryItem;
      const quantityInWarehouse = getInventoryItemQuantityNumber(
        quantity_in_warehouse,
        material_unit,
        unit_a_b_ratio,
      );
      const unit = material_unit === 'b' ? unit_b_title : unit_a_title;
      return `${+quantity_of_legacy_stock -
        this.initialQuantityOfLegacyStock +
        +quantityInWarehouse} ${unit || ''}`;
    },

    unitATitle() {
      return this.inventoryItem.unit_a_title || 'A';
    },

    unitBTitle() {
      return this.inventoryItem.unit_b_title || 'B';
    },

    unitABRatioLabel() {
      const label = this.$t('unit_a_b_ratio_form_label')
        .replace('{0}', this.unitATitle)
        .replace('{1}', this.unitBTitle);
      return this.inventoryItem.type === 'bought'
        ? this.formMixin_getRequiredFieldLabel(label)
        : label;
    },
  },

  methods: {
    onDialogOpen() {
      this.inventoryItem = JSON.parse(JSON.stringify(this.formItem));
      this.inventoryItem.documents = [];
      this.errors = {};
      this.initialQuantityOfLegacyStock = +this.inventoryItem.quantity_of_legacy_stock;
    },

    updateUnitABRatio(unitAQuantity, unitBQuantity) {
      if (!+unitAQuantity || !+unitBQuantity) {
        return;
      }
      this.$set(this.inventoryItem, 'unit_a_b_ratio', round(+unitAQuantity / +unitBQuantity, 4));
    },

    onGroupSelect(group) {
      this.inventoryItem.inventory_item_group_id = group?.id;
      this.$set(this.inventoryItem, 'inventory_item_group', group);
      this.formMixin_clearErrors('inventory_item_group_id');
    },

    onMaterialUnitChange(unit) {
      this.formMixin_clearErrors('material_unit');
      const { unit_a_b_ratio, quantity_of_legacy_stock } = this.inventoryItem;
      if (!unit_a_b_ratio || !+quantity_of_legacy_stock) {
        return;
      }
      const ratio = unit === 'b' ? 1 / unit_a_b_ratio : unit_a_b_ratio;

      this.$set(
        this.inventoryItem,
        'quantity_of_legacy_stock',
        round(+quantity_of_legacy_stock * ratio, 4),
      );
    },

    async onSubmit() {
      await this.crudMixin_createOrUpdate(inventoryItemService, this.inventoryItem);
      if (
        this.errors.material_unit &&
        !this.inventoryItem.unit_a_title &&
        !this.inventoryItem.unit_a_title
      ) {
        // material_unit has a validation error but its field is not displayed in form
        this.$set(this.errors, 'unit_a_title', [this.$t('required_field')]);
      }
    },

    async deleteFile(inventoryItem, file) {
      await documentService.delete(file);
      this.$set(
        inventoryItem,
        'documents',
        inventoryItem.documents.filter(doc => doc.id !== file.id),
      );
    },
  },
};
</script>
