<template>
  <div>
    <v-data-table
      :expanded="tableMixin_expandedRows"
      :headers="tableMixin_displayedHeaders"
      :items="rows"
      :mobile-breakpoint="0"
      :dense="dense"
      :item-class="() => (showPurchases || tableMixin_hiddenHeaders.length ? 'clickable' : '')"
      :server-items-length="pagination ? pagination.totalItems : -1"
      :page="pagination ? pagination.page : 1"
      :footer-props="
        pagination
          ? {
              'items-per-page-options': [pagination.rowsPerPage],
            }
          : {}
      "
      :disable-pagination="!pagination"
      :hide-default-footer="!pagination"
      disable-sort
      @click:row="showPurchases ? expandRow($event) : tableMixin_onRowClick($event)"
    >
      <template v-slot:item.material.title="{ item }">
        <div :class="{ link: showPurchases }">
          {{ item.material.title }}
        </div>
        <div
          v-if="
            missingAmountsMap[
              `${item.inventory_item_id}_${typeof item.id === 'number' ? item.id : 0}`
            ]
          "
          class="error--text font-weight-medium"
        >
          {{ $t('missing') }}:
          {{
            Math.abs(
              getInventoryItemQuantityNumber(
                missingAmountsMap[
                  `${item.inventory_item_id}_${typeof item.id === 'number' ? item.id : 0}`
                ],
                item.material_unit,
                item.material.unit_a_b_ratio,
              ),
            )
          }}
        </div>
      </template>

      <template v-slot:item.order_part.order.order_no="{ item }">
        <div v-if="item.order_part && item.order_part.order">
          <a
            :href="`${ordersUrl}?search=${item.order_part.order.order_no}`"
            class="link"
            target="_blank"
          >
            {{ item.order_part.order.order_no }}
          </a>
        </div>
      </template>

      <template v-slot:item.material_unit="{ item }">
        <BaseEditableField
          v-if="(item.material.unit_a_title || item.material.unit_b_title) && canEdit"
        >
          {{ getMaterialUnitTitle(item.material_unit, item.material) }}
          <template #inputs>
            <v-radio-group
              :value="item.material_unit"
              class="mt-0"
              @change="onMaterialUnitChange(item, $event)"
            >
              <v-radio
                v-if="item.material.unit_a_title"
                :label="item.material.unit_a_title"
                value="a"
              />
              <v-radio
                v-if="item.material.unit_b_title"
                :label="item.material.unit_b_title"
                value="b"
              />
            </v-radio-group>
          </template>
        </BaseEditableField>
        <template v-else>
          {{ getMaterialUnitTitle(item.material_unit, item.material) || $t('not_set') }}
        </template>
      </template>

      <template v-slot:item.material_price_per_unit="{ item }">
        <BaseEditableField
          v-if="canEdit"
          v-model.number="item.material_price_per_unit"
          justify="end"
          type="number"
          @input="
            updateMaterialPrice(item, $event, item.materialQuantity);
            updateMaterialPricePerManufacturedUnit(
              item,
              item.material_sell_price,
              getOrderPartQuantity(item),
            );
          "
        />
        <BaseNumberCell v-else :value="item.material_price_per_unit" />
      </template>

      <template v-slot:item.order_part.quantity="{ item }">
        <BaseNumberCell :value="item.order_part ? item.order_part.quantity : 0" />
      </template>

      <template v-slot:item.material_quantity_per_unit="{ item }">
        <BaseEditableField
          v-if="canEdit"
          v-model.number="item.material_quantity_per_unit"
          type="number"
          justify="end"
          @input="
            updateMaterialQuantity(item, $event, getOrderPartQuantity(item));
            updateMaterialPrice(item, item.material_price_per_unit, item.materialQuantity);
            updateMaterialPricePerManufacturedUnit(
              item,
              item.material_sell_price,
              getOrderPartQuantity(item),
            );
          "
        />
        <BaseNumberCell v-else :value="item.material_quantity_per_unit" />
      </template>

      <template v-slot:item.material_quantity="{ item }">
        <BaseEditableField
          v-if="canEdit"
          v-model.number="item.materialQuantity"
          type="number"
          justify="end"
          @input="
            onMaterialQuantityInput(item, $event);
            updateMaterialQuantityPerManufacturedUnit(item, $event, getOrderPartQuantity(item));
            updateMaterialPrice(item, item.material_price_per_unit, item.materialQuantity);
            updateMaterialPricePerManufacturedUnit(
              item,
              item.material_sell_price,
              getOrderPartQuantity(item),
            );
          "
        />
        <BaseNumberCell v-else :value="item.materialQuantity" />
      </template>

      <template v-slot:item.material.quantity_in_warehouse="{ item }">
        <BaseNumberCell :value="materialQuantityBalanceMap[item.id].toFixed(4)" />
      </template>

      <template v-slot:item.material_price_per_quantity_unit="{ item }">
        <MaterialPricePerQuantityUnitEditableField
          v-model.number="item.material_price_per_quantity_unit"
          :can-edit="canEdit"
          :purchases="item.material.purchases"
          :material-unit="item.material_unit"
          :material-quantity-per-unit="item.material_quantity_per_unit"
          @input="
            updateMaterialPrice(item, $event, getOrderPartQuantity(item));
            updateMaterialPricePerUnit(item, item.material_sell_price, getOrderPartQuantity(item));
          "
        />
      </template>

      <template v-slot:item.material_sell_price="{ item }">
        <BaseEditableField
          v-if="canEdit"
          v-model.number="item.material_sell_price"
          type="number"
          justify="end"
          @input="
            updateMaterialPricePerUnit(item, $event, item.materialQuantity);
            updateMaterialPricePerManufacturedUnit(item, $event, getOrderPartQuantity(item));
          "
        />
        <BaseNumberCell v-else :value="item.material_sell_price" />
      </template>

      <template v-slot:item.order_part.material_sell_price="{ item }">
        <BaseNumberCell
          :value="round(item.order_part ? item.order_part.material_sell_price : 0, 4)"
        />
      </template>

      <template v-slot:item.order_part.total_sell_price="{ item }">
        <BaseNumberCell :value="getTotalOrderPartSellPrice(item)" />
      </template>

      <template v-slot:item.material_status="{ item }">
        <BaseChipSelect
          :value="item.material_status"
          :items="orderPartMaterialStatuses"
          :outlined="!item.material_status"
          :disabled="!canEdit && !canChangeStatus"
          @change="updateStatus(item, $event)"
        />
      </template>

      <template v-slot:item.warehouse_location_id="{ item }">
        <BaseChipSelect
          v-if="canEdit || canChangeLocation"
          :value="item.warehouse_location_id"
          :items="$store.getters.warehouseLocations"
          :no-data-text="$t('not_set')"
          :outlined="!item.warehouse_location_id"
          item-value="id"
          item-text="name"
          show-search
          @change="updateWarehouseLocation(item, $event)"
        />
        <template v-else>
          {{ item.warehouse_location ? item.warehouse_location.name : $t('not_set') }}
        </template>
      </template>

      <template v-slot:item.actions="{ item }">
        <BaseActionMenu :actions="getRowActions()" :item="item" />
      </template>

      <template v-slot:expanded-item="{ item }">
        <BaseExpandedTableRow
          :colspan="tableMixin_displayedHeaders.length"
          :headers="tableMixin_hiddenHeaders"
          :item="item"
        >
          <template v-slot:item.order_part.order.order_no>
            <div v-if="item.order_part && item.order_part.order">
              <a
                :href="`${ordersUrl}?search=${item.order_part.order.order_no}`"
                class="link"
                target="_blank"
              >
                {{ item.order_part.order.order_no }}
              </a>
            </div>
          </template>

          <template v-slot:item.material_unit>
            <BaseEditableField
              v-if="(item.material.unit_a_title || item.material.unit_b_title) && canEdit"
            >
              {{ getMaterialUnitTitle(item.material_unit, item.material) }}
              <template #inputs>
                <v-radio-group
                  v-model="item.material_unit"
                  class="mt-0"
                  @change="onMaterialUnitChange(item, $event)"
                >
                  <v-radio
                    v-if="item.material.unit_a_title"
                    :label="item.material.unit_a_title"
                    value="a"
                  />
                  <v-radio
                    v-if="item.material.unit_b_title"
                    :label="item.material.unit_b_title"
                    value="b"
                  />
                </v-radio-group>
              </template>
            </BaseEditableField>
            <template v-else>
              {{ getMaterialUnitTitle(item.material_unit, item.material) || $t('not_set') }}
            </template>
          </template>

          <template v-slot:item.material_price_per_unit>
            <BaseEditableField
              v-if="canEdit"
              v-model.number="item.material_price_per_unit"
              type="number"
              @input="
                updateMaterialPrice(item, $event, item.materialQuantity);
                updateMaterialPricePerManufacturedUnit(
                  item,
                  item.material_sell_price,
                  getOrderPartQuantity(item),
                );
              "
            />
            <BaseNumberCell v-else :value="item.material_price_per_unit" />
          </template>

          <template v-slot:item.order_part.quantity>
            <BaseNumberCell :value="item.order_part ? item.order_part.quantity : 0" />
          </template>

          <template v-slot:item.material_quantity_per_unit>
            <BaseEditableField
              v-if="canEdit"
              v-model.number="item.material_quantity_per_unit"
              type="number"
              @input="
                updateMaterialQuantity(item, $event, getOrderPartQuantity(item));
                updateMaterialPrice(item, item.material_price_per_unit, item.materialQuantity);
                updateMaterialPricePerManufacturedUnit(
                  item,
                  item.material_sell_price,
                  getOrderPartQuantity(item),
                );
              "
            />
            <BaseNumberCell v-else :value="item.material_quantity_per_unit" />
          </template>

          <template v-slot:item.material_quantity>
            <BaseEditableField
              v-if="canEdit"
              v-model.number="item.materialQuantity"
              type="number"
              @input="
                updateMaterialQuantityPerManufacturedUnit(item, $event, getOrderPartQuantity(item));
                updateMaterialPrice(item, item.material_price_per_unit, item.materialQuantity);
                updateMaterialPricePerManufacturedUnit(
                  item,
                  item.material_sell_price,
                  getOrderPartQuantity(item),
                );
              "
            />
            <BaseNumberCell v-else :value="item.materialQuantity" />
          </template>

          <template v-slot:item.material.quantity_in_warehouse>
            <BaseNumberCell :value="materialQuantityBalanceMap[item.id].toFixed(4)" />
          </template>

          <template v-slot:item.material_price_per_quantity_unit>
            <MaterialPricePerQuantityUnitEditableField
              v-model.number="item.material_price_per_quantity_unit"
              :can-edit="canEdit"
              :purchases="item.material.purchases"
              :material-unit="item.material_unit"
              :material-quantity-per-unit="item.material_quantity_per_unit"
              @input="
                updateMaterialPrice(item, $event, getOrderPartQuantity(item));
                updateMaterialPricePerUnit(
                  item,
                  item.material_sell_price,
                  getOrderPartQuantity(item),
                );
              "
            />
          </template>

          <template v-slot:item.material_sell_price>
            <BaseEditableField
              v-if="canEdit"
              v-model.number="item.material_sell_price"
              type="number"
              @input="
                updateMaterialPricePerUnit(item, $event, item.materialQuantity);
                updateMaterialPricePerManufacturedUnit(item, $event, getOrderPartQuantity(item));
              "
            />
            <BaseNumberCell v-else :value="item.material_sell_price" />
          </template>

          <template v-slot:item.order_part.material_sell_price>
            <BaseNumberCell
              :value="round(item.order_part ? item.order_part.material_sell_price : 0, 4)"
            />
          </template>

          <template v-slot:item.order_part.total_sell_price>
            <BaseNumberCell :value="getTotalOrderPartSellPrice(item)" />
          </template>

          <template v-slot:item.material_status>
            <BaseChipSelect
              :value="item.material_status"
              :items="orderPartMaterialStatuses"
              :outlined="!item.material_status"
              :disabled="!canEdit && !canChangeStatus"
              @change="updateStatus(item, $event)"
            />
          </template>

          <template v-slot:item.warehouse_location_id>
            <BaseChipSelect
              v-if="canEdit || canChangeLocation"
              :value="item.warehouse_location_id"
              :items="$store.getters.warehouseLocations"
              :no-data-text="$t('not_set')"
              :outlined="!item.warehouse_location_id"
              item-value="id"
              item-text="name"
              show-search
              @change="updateWarehouseLocation(item, $event)"
            />
            <template v-else>
              {{ item.warehouse_location ? item.warehouse_location.name : $t('not_set') }}
            </template>
          </template>

          <template v-if="!workerView" v-slot:append>
            <div v-if="showPurchases" class="my-3">
              <div class="d-flex justify-space-between align-center">
                <h2 class="subtitle-2">
                  {{ $t('purchases') }}
                </h2>
                <div>
                  <InventoryItemPriceHistoryListDialog
                    :inventory-item="item.material"
                    class="mr-2"
                  />
                  <v-btn color="primary" @click="createPurchase(item)">
                    <v-icon left>mdi-plus</v-icon>
                    {{ $t('create_purchase') }}
                  </v-btn>
                </div>
              </div>

              <InventoryItemPurchaseTable
                :rows="inventoryItemPurchaseArray"
                :inventory-item="item.material"
                @edit="crudMixin_openForm('inventoryItemPurchase', $event)"
                @update="updateInventoryItemPurchase"
                @delete="deletePurchase($event, item.material)"
              />
            </div>
          </template>
        </BaseExpandedTableRow>
      </template>
    </v-data-table>

    <BaseDialog
      v-if="!workerView"
      v-model="isInventoryItemPurchaseFormOpen"
      max-width="1000"
      scrollable
      persistent
    >
      <InventoryItemPurchaseForm
        :dialog="isInventoryItemPurchaseFormOpen"
        :form-item="inventoryItemPurchaseFormItem"
        :inventory-item="selectedMaterial"
        @cancel="isInventoryItemPurchaseFormOpen = false"
        @create="purchaseCreated"
        @update="purchaseUpdated"
      />
    </BaseDialog>
  </div>
</template>

<script>
/* eslint-disable no-continue */
import BaseActionMenu from '../base/BaseActionMenu';
import BaseExpandedTableRow from '../base/BaseExpandedTableRow';
import tableMixin from '../../mixins/table-mixin';
import InventoryItemPurchaseTable from '@/components/tables/InventoryItemPurchaseTable';
import crudMixin from '@/mixins/crud-mixin';
import inventoryItemPurchaseService, {
  getDefaultInventoryItemPurchaseFormItem,
} from '@/api/inventory-item-purchase-service';
import InventoryItemPurchaseForm from '@/components/forms/InventoryItemPurchaseForm';
import BaseEditableField from '@/components/base/BaseEditableField';
import BaseNumberCell from '@/components/base/BaseNumberCell';
import { round } from '@/util/numbers';
import BaseChipSelect from '@/components/base/BaseChipSelect';
import orderPartMaterialService, {
  ORDER_PART_MATERIAL_STATUSES,
} from '@/api/order-part-material-service';
import eventBus, {
  OPEN_SNACKBAR,
  UPDATE_INVENTORY_ITEM_QUANTITIES_IN_WAREHOUSE,
} from '@/util/event-bus';
import {
  getInventoryItemQuantityForBackend,
  getInventoryItemQuantityNumber,
} from '@/util/inventory-item-quantity';
import MaterialPricePerQuantityUnitEditableField from '@/components/MaterialPricePerQuantityUnitEditableField';
import InventoryItemPriceHistoryListDialog from '@/components/InventoryItemPriceHistoryListDialog';
import BaseDialog from '@/components/base/BaseDialog';

const debounceTimerIdMap = {};

export default {
  name: 'OrderPartMaterialTable',

  components: {
    BaseDialog,
    InventoryItemPriceHistoryListDialog,
    MaterialPricePerQuantityUnitEditableField,
    BaseChipSelect,
    BaseNumberCell,
    BaseEditableField,
    InventoryItemPurchaseForm,
    InventoryItemPurchaseTable,
    BaseExpandedTableRow,
    BaseActionMenu,
  },

  mixins: [tableMixin, crudMixin],

  props: {
    rows: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      default: () => null,
    },
    part: {
      type: Object,
      default: () => null,
    },
    order: {
      type: Object,
      default: () => null,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canChangeStatus: {
      type: Boolean,
      default: false,
    },
    canChangeLocation: {
      type: Boolean,
      default: false,
    },
    workerView: {
      type: Boolean,
      default: false,
    },
    showPurchases: {
      type: Boolean,
      default: false,
    },
    showOrderPartQuantity: {
      type: Boolean,
      default: false,
    },
    showOrderPartMaterialSellPrice: {
      type: Boolean,
      default: false,
    },
    showOrderPartTotalSellPrice: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      inventoryItemPurchaseArray: [],
      inventoryItemPurchaseFormItem: {},

      isInventoryItemPurchaseFormOpen: false,
      selectedMaterial: {},
      missingAmountsMap: {
        // `${inventoryItemId}_${orderPartMaterialId}`: 10
      },
    };
  },

  computed: {
    headers() {
      let headers = [
        {
          text: this.$t('title'),
          value: 'material.title',
        },
        {
          text: this.$t('order'),
          value: 'order_part.order.order_no',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('order_part_material_unit'),
          value: 'material_unit',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('order_part_material_price_per_unit'),
          value: 'material_price_per_unit',
          align: 'end',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('product_quantity'),
          value: 'order_part.quantity',
          align: 'end',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('order_part_material_quantity_per_unit'),
          value: 'material_quantity_per_unit',
          align: 'end',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('order_part_material_quantity'),
          value: 'material_quantity',
          align: 'end',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('amount_in_warehouse'),
          value: 'material.quantity_in_warehouse',
          align: 'end',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('order_part_material_price_per_quantity_unit'),
          value: 'material_price_per_quantity_unit',
          align: 'end',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('order_part_material_price'),
          value: 'material_sell_price',
          align: 'end',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('material_sell_price'),
          value: 'order_part.material_sell_price',
          align: 'end',
          hidden: 'mdAndDown',
        },
        {
          text: this.$t('total_sell_price'),
          value: 'order_part.total_sell_price',
          align: 'end',
          hidden: 'mdAndDown',
        },
        {
          text: this.$t('status'),
          value: 'material_status',
          align: 'end',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('warehouse_location'),
          value: 'warehouse_location_id',
          align: 'end',
          hidden: 'xsOnly',
        },
        {
          align: 'end',
          value: 'actions',
        },
      ];
      if (this.workerView) {
        headers = headers.filter(
          h =>
            ![
              'material_price_per_unit',
              'material_price_per_quantity_unit',
              'material_sell_price',
            ].includes(h.value),
        );
      }
      if (!this.canEdit) {
        headers = headers.filter(h => h.value !== 'actions');
      }
      if (!this.showOrderPartQuantity) {
        headers = headers.filter(h => h.value !== 'order_part.quantity');
      }
      if (!this.showOrderPartMaterialSellPrice) {
        headers = headers.filter(h => h.value !== 'order_part.material_sell_price');
      }
      if (!this.showOrderPartTotalSellPrice) {
        headers = headers.filter(h => h.value !== 'order_part.total_sell_price');
      }
      if (this.part) {
        headers = headers.filter(h => h.value !== 'order_part.order.order_no');
      }
      return headers;
    },

    orderPartMaterialStatuses() {
      return ORDER_PART_MATERIAL_STATUSES;
    },

    unitsToManufacture() {
      return +this.part?.quantity || 0;
    },

    ordersUrl() {
      return `${process.env.VUE_APP_PUBLIC_PATH}orders`;
    },

    materialWarehouseQuantityMap() {
      const map = {};
      for (let i = 0; i < this.rows.length; i++) {
        const { quantity_in_warehouse, unit_a_b_ratio } = this.rows[i].material;
        const { id, material_unit } = this.rows[i];
        map[id] = getInventoryItemQuantityNumber(
          quantity_in_warehouse,
          material_unit,
          unit_a_b_ratio,
        );
      }
      return map;
    },

    materialQuantityBalanceMap() {
      const map = {};
      for (let i = 0; i < this.rows.length; i++) {
        // material_quantity in snake case is the backend value, not changed by front end
        // materialQuantity in pascal case is the form item value
        const { id, materialQuantity, material_quantity, material_unit } = this.rows[i];
        const { unit_a_b_ratio } = this.rows[i].material;
        const initialQuantity = getInventoryItemQuantityNumber(
          material_quantity,
          material_unit,
          unit_a_b_ratio,
        );
        const quantityChange = +materialQuantity - initialQuantity;
        const quantityInWarehouse = this.materialWarehouseQuantityMap[id] - quantityChange;
        map[id] = quantityInWarehouse || 0;
      }
      return map;
    },
  },

  watch: {
    unitsToManufacture(newValue) {
      for (let i = 0; i < this.rows.length; i++) {
        this.updateMaterialQuantity(
          this.rows[i],
          this.rows[i].material_quantity_per_unit,
          newValue,
        );
        this.updateMaterialPrice(
          this.rows[i],
          this.rows[i].material_price_per_quantity_unit,
          newValue,
        );
      }
    },

    rows: {
      handler(newValue, oldValue) {
        if (newValue?.length === oldValue?.length) {
          return;
        }
        for (let i = 0; i < this.rows.length; i++) {
          if (
            +this.rows[i].material_quantity &&
            +this.rows[i].material.unit_a_b_ratio &&
            this.rows[i].material_unit === 'b'
          ) {
            this.$set(
              this.rows[i],
              'materialQuantity',
              round(+this.rows[i].material_quantity / +this.rows[i].material.unit_a_b_ratio, 4),
            );
          } else {
            this.$set(
              this.rows[i],
              'materialQuantity',
              round(+this.rows[i].material_quantity, 4) || 0,
            );
          }
          this.updateMaterialPrice(
            this.rows[i],
            this.rows[i].material_price_per_unit,
            this.rows[i].materialQuantity,
          );
          this.updateMaterialQuantityPerManufacturedUnit(
            this.rows[i],
            this.rows[i].materialQuantity,
            this.getOrderPartQuantity(this.rows[i]),
          );
          this.updateMaterialPricePerManufacturedUnit(
            this.rows[i],
            this.rows[i].material_sell_price,
            this.getOrderPartQuantity(this.rows[i]),
          );
        }
        this.getMissingAmounts(newValue);
      },
      immediate: true,
    },
  },

  methods: {
    deleteInventoryItemPurchase: inventoryItemPurchaseService.delete,
    getInventoryItemQuantityNumber,
    round,

    async getMissingAmounts(items) {
      if (!this.$isAdmin()) {
        return;
      }
      const filteredItems = items.filter(i => +i.materialQuantity > 0);
      if (!filteredItems.length) {
        return;
      }
      const payload = {
        inventory_items: filteredItems.map(i => ({
          inventory_item_id: i.inventory_item_id,
          required_quantity:
            getInventoryItemQuantityForBackend(
              i.materialQuantity,
              i.material_unit,
              i.material.unit_a_b_ratio,
            ) || 0,
          order_part_material_id: typeof i.id === 'number' ? i.id : null,
        })),
      };
      const { data } = await orderPartMaterialService.getMissingAmounts(payload);
      const newMissingAmounts = {};
      data.forEach(item => {
        newMissingAmounts[`${item.inventory_item_id}_${item.order_part_material_id || 0}`] =
          item.missing_amount;
      });
      this.missingAmountsMap = {
        ...this.missingAmountsMap,
        ...newMissingAmounts,
      };
    },

    getOrderPartQuantity(material) {
      return material.order_part?.quantity || this.part?.quantity || 0;
    },

    getTotalOrderPartSellPrice(item) {
      if (+item.order_part?.quantity && +item.order_part?.material_sell_price) {
        return round(+item.order_part.quantity * +item.order_part.material_sell_price, 4);
      }
      return 0;
    },

    onMaterialUnitChange(row, unit) {
      const { unit_a_b_ratio } = row.material;
      const { material_unit, material_price_per_unit, materialQuantity, material_sell_price } = row;
      const currentUnit = material_unit;
      this.$set(row, 'material_unit', unit);

      if (!currentUnit && unit === 'a') {
        // if not filled A is default
        return;
      }

      if (!+unit_a_b_ratio) {
        return;
      }

      const ratio = unit === 'b' ? +unit_a_b_ratio : 1 / +unit_a_b_ratio;

      let newMaterialQuantity = 0;
      if (+materialQuantity) {
        newMaterialQuantity = round(+materialQuantity / ratio, 4);
        this.$set(row, 'materialQuantity', newMaterialQuantity);
      }

      if (+material_price_per_unit) {
        this.$set(row, 'material_price_per_unit', round(+material_price_per_unit * ratio, 4));
      } else if (+material_sell_price && newMaterialQuantity) {
        this.$set(
          row,
          'material_price_per_unit',
          round(+material_sell_price / newMaterialQuantity, 4),
        );
      }

      if (newMaterialQuantity) {
        this.updateMaterialQuantityPerManufacturedUnit(
          row,
          newMaterialQuantity,
          this.getOrderPartQuantity(row),
        );
      }
    },

    onMaterialQuantityInput(item) {
      clearTimeout(debounceTimerIdMap[item.id]);
      debounceTimerIdMap[item.id] = setTimeout(() => {
        this.getMissingAmounts([item]);
      }, 500);
    },

    updateMaterialQuantity(row, material_quantity_per_unit, units_to_manufacture) {
      if (+material_quantity_per_unit && +units_to_manufacture) {
        const newQuantity = round(+material_quantity_per_unit * +units_to_manufacture, 4);
        this.$set(row, 'materialQuantity', newQuantity);
        this.onMaterialQuantityInput(row, newQuantity);
      }
    },

    updateMaterialQuantityPerManufacturedUnit(row, materialQuantity, units_to_manufacture) {
      if (+materialQuantity && +units_to_manufacture) {
        this.$set(
          row,
          'material_quantity_per_unit',
          round(+materialQuantity / +units_to_manufacture, 4),
        );
      }
    },

    updateMaterialPrice(row, material_price_per_unit, units_to_manufacture) {
      if (+material_price_per_unit && +units_to_manufacture) {
        this.$set(
          row,
          'material_sell_price',
          round(+material_price_per_unit * +units_to_manufacture, 4),
        );
      }
    },

    updateMaterialPricePerManufacturedUnit(row, material_sell_price, units_to_manufacture) {
      if (+material_sell_price && +units_to_manufacture) {
        this.$set(
          row,
          'material_price_per_quantity_unit',
          round(+material_sell_price / +units_to_manufacture, 4),
        );
      }
    },

    updateMaterialPricePerUnit(row, material_sell_price, materialQuantity) {
      if (+material_sell_price && +materialQuantity) {
        this.$set(
          row,
          'material_price_per_unit',
          round(+material_sell_price / +materialQuantity, 4),
        );
      }
    },

    updateStatus(item, newStatus) {
      const updatedItem = {
        ...item,
        material_status: newStatus,
      };
      this.$emit('update', updatedItem);
    },

    updateWarehouseLocation(item, newLocationId) {
      const updatedItem = {
        ...item,
        warehouse_location_id: newLocationId,
      };
      this.$emit('update', updatedItem);
    },

    createPurchase(orderPartMaterial) {
      this.selectedMaterial = orderPartMaterial.material;
      const formItem = {
        ...getDefaultInventoryItemPurchaseFormItem(),
        inventory_item_id: orderPartMaterial.material.id,
        reserved_for_order_parts: [],
        responsible_user_id: this.$store.getters.currentUser.id,
        responsible: this.$store.getters.currentUser,
      };
      if (this.part?.id && this.order?.id) {
        formItem.reserved_for_order_parts.push({
          ...this.part,
          order: {
            id: this.order.id,
            order_no: this.order.order_no,
          },
        });
      }
      this.inventoryItemPurchaseFormItem = formItem;
      this.isInventoryItemPurchaseFormOpen = true;
    },

    purchaseCreated(purchase) {
      this.crudMixin_created('inventoryItemPurchase', purchase, true);
      for (let i = 0; i < this.rows.length; i++) {
        if (this.rows[i].inventory_item_id === purchase.inventory_item_id) {
          // because material quantities might change after updating purchase
          const updatedMaterial = {
            ...this.rows[i].material,
            ...purchase.inventory_item,
            purchases: this.inventoryItemPurchaseArray,
          };
          this.$set(this.rows[i], 'material', updatedMaterial);
        }
      }
      this.getMissingAmounts(this.rows);
    },

    async updateInventoryItemPurchase(item) {
      const { data } = await inventoryItemPurchaseService.update(item);
      eventBus.$emit(OPEN_SNACKBAR, this.$t('inventory_item_purchase_updated'));
      this.purchaseUpdated(data);
    },

    purchaseUpdated(purchase) {
      this.crudMixin_updated('inventoryItemPurchase', purchase);
      for (let i = 0; i < this.rows.length; i++) {
        if (
          this.rows[i].inventory_item_id === purchase.inventory_item_id &&
          this.rows[i].material?.purchases
        ) {
          // because material quantities might change after updating purchase
          const updatedMaterial = {
            ...this.rows[i].material,
            ...purchase.inventory_item,
            purchases: this.inventoryItemPurchaseArray,
          };
          this.$set(this.rows[i], 'material', updatedMaterial);
        }

        // if purchase status changed, update material status as well
        for (let j = 0; j < purchase.order_part_materials.length; j++) {
          if (this.rows[i].id === purchase.order_part_materials[j].id) {
            this.rows[i].material_status = purchase.order_part_materials[j].material_status;
          }
        }
      }
      this.getMissingAmounts(this.rows);
    },

    deletePurchase(purchase, inventoryItem) {
      this.crudMixin_delete(
        inventoryItemPurchaseService.delete,
        'inventoryItemPurchase',
        purchase,
      ).then(() => {
        for (let i = 0; i < this.rows.length; i++) {
          if (
            this.rows[i].inventory_item_id === purchase.inventory_item_id &&
            this.rows[i].material?.purchases
          ) {
            const updatedInventoryItem = {
              ...inventoryItem,
              quantity_in_warehouse:
                +inventoryItem.quantity_in_warehouse - +purchase.quantity_ordered,
              purchases: this.inventoryItemPurchaseArray,
            };
            eventBus.$emit(UPDATE_INVENTORY_ITEM_QUANTITIES_IN_WAREHOUSE, updatedInventoryItem);

            this.$set(this.rows[i], 'material', updatedInventoryItem);
          }
        }
        this.getMissingAmounts(this.rows);
      });
    },

    getRowActions() {
      return [
        {
          callback: p => this.createPurchase(p),
          label: this.$t('create_purchase'),
          icon: 'mdi-plus',
        },
        {
          callback: p => this.$emit('remove', p),
          label: this.$t('remove'),
          icon: 'mdi-close',
        },
      ];
    },

    expandRow(orderPartMaterial) {
      this.tableMixin_expandRow(orderPartMaterial);
      this.inventoryItemPurchaseArray = orderPartMaterial.material?.purchases;
      this.selectedMaterial = orderPartMaterial.material;
    },

    getMaterialUnitTitle(materialUnit, material) {
      const { unit_a_title, unit_b_title } = material;
      return materialUnit === 'b' ? unit_b_title : unit_a_title;
    },
  },
};
</script>
