// eslint-disable-next-line import/prefer-default-export
import { formatDistanceToNow, format } from 'date-fns';
import { lt } from 'date-fns/locale';

// yyyy-MM-dd HH:mm does not work in Safari, this function fixes it by swapping '-' with '/'
function sanitizeDateString(date) {
  if (typeof date === 'object') {
    return date;
  }

  if (!date.includes('T') || !date.includes('Z')) {
    return date.replace(/-/g, '/');
  }

  return date;
}

export function getUTCDate(dateStr) {
  const date = new Date(sanitizeDateString(dateStr));
  if (dateStr.includes('T') && dateStr.includes('Z')) {
    return date;
  }
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}

export function getTimeFromDate(date) {
  return formatDistanceToNow(getUTCDate(date), {
    addSuffix: true,
    locale: lt,
  }).replace(/apie/g, '');
}

export function getDateTime(datetime) {
  try {
    const date = new Date(sanitizeDateString(datetime));
    return format(date, 'yyyy-MM-dd HH:mm');
  } catch (e) {
    return datetime;
  }
}

export function getNewDate(dateString = '') {
  return new Date(sanitizeDateString(dateString)); // for Safari support
}
