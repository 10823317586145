<template>
  <v-card>
    <v-card-title class="d-flex align-center justify-space-between">
      <div>
        {{ $t('material_defects') }}
      </div>
    </v-card-title>

    <v-card-text class="px-0 px-sm-3">
      <MaterialDefectTable
        :rows="materialDefectArray"
        :accepted-file-formats="acceptedFileFormats"
        disable-pagination
        @delete="deleteMaterialDefect"
        @edit="crudMixin_openForm('materialDefect', $event)"
      />
    </v-card-text>

    <v-card-actions>
      <v-spacer />

      <v-btn color="primary" text @click.native="$emit('close')">
        {{ $t('close') }}
      </v-btn>

      <v-btn
        color="primary"
        text
        @click.native="crudMixin_openForm('materialDefect', newMaterialDefectTemplate)"
      >
        <v-icon left>mdi-plus</v-icon>{{ $t('create') }}
      </v-btn>
    </v-card-actions>

    <v-dialog
      v-model="isMaterialDefectFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <MaterialDefectForm
        :dialog="isMaterialDefectFormOpen"
        :form-item="materialDefectFormItem"
        :accepted-file-formats="acceptedFileFormats"
        @cancel="isMaterialDefectFormOpen = false"
        @create="crudMixin_created('materialDefect', $event)"
        @update="crudMixin_updated('materialDefect', $event)"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import crudMixin from '@/mixins/crud-mixin';
import MaterialDefectTable from '@/components/tables/MaterialDefectTable';
import MaterialDefectForm from '@/components/forms/MaterialDefectForm';
import materialDefectService from '@/api/material-defect-service';

export default {
  name: 'MaterialDefects',

  components: { MaterialDefectForm, MaterialDefectTable },

  mixins: [crudMixin],

  props: {
    orderPart: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      acceptedFileFormats: '.jpeg, .jpg, .png, .gif, .svg, .pdf',
      materialDefectArray: [],
      newMaterialDefectTemplate: {},
      isMaterialDefectFormOpen: false,
      materialDefectFormItem: {},
    };
  },

  created() {
    if (this.orderPart.id) {
      // If an order part is passed, CRUD operations will be done on the array reference
      // thus updating orderPart prop. I know mutating is considered 'bad', but I didn't want
      // to add another 50 lines to an already long Orders.vue.
      // This works fine and keeps related logic grouped, so why not
      this.materialDefectArray = this.orderPart.material_defects;
      this.newMaterialDefectTemplate.order_part_id = this.orderPart.id;
    } else {
      // Fetch defects from BE if this ever needs a standalone view
    }
  },

  methods: {
    deleteMaterialDefect(item) {
      this.crudMixin_delete(materialDefectService.delete, 'materialDefect', item);
    },
  },
};
</script>
