<template>
  <v-row dense>
    <v-col class="d-flex justify-space-between align-center" cols="12">
      <h2 class="subtitle-2">
        {{ $t('materials') }}
      </h2>
      <InventoryItemPicker left @click:item="addSelectedInventoryItem" />
    </v-col>

    <v-col cols="12">
      <OrderPartMaterialTable
        v-if="part.materials && part.materials.length"
        :rows="part.materials || []"
        :part="part"
        :order="order"
        linkify-title
        can-edit
        show-purchases
        dense
        @update="updateMaterial"
        @remove="removeSelectedMaterial"
      />
    </v-col>
  </v-row>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import OrderPartMaterialTable from '@/components/tables/OrderPartMaterialTable';
import crudMixin from '@/mixins/crud-mixin';
import { removeItemById, updateItemById } from '@/util/array';
import InventoryItemPicker from '@/components/InventoryItemPicker';
import eventBus, { OPEN_SNACKBAR } from '@/util/event-bus';

export default {
  name: 'OrderPartFormMaterials',

  components: {
    InventoryItemPicker,
    OrderPartMaterialTable,
  },

  mixins: [crudMixin],

  props: {
    part: {
      type: Object,
      default: () => ({}),
    },
    order: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    addSelectedInventoryItem(inventoryItem) {
      if (!this.part.materials) {
        this.$set(this.part, 'materials', []);
      }
      // if (inventoryItem.type !== 'bought') {
      //   eventBus.$emit(OPEN_SNACKBAR, this.$t('can_only_add_bought_materials'));
      //   return;
      // }
      for (let i = 0; i < this.part.materials.length; i++) {
        if (this.part.materials[i].inventory_item_id === inventoryItem.id) {
          eventBus.$emit(OPEN_SNACKBAR, this.$t('material_already_added'));
          return;
        }
      }
      this.part.materials.push({
        id: uuidv4(), // is removed before passing to BE. Didn't want to create a separate key attribute
        order_part_id: this.part.id,
        material_status: 'not_ordered',
        inventory_item_id: inventoryItem.id,
        material_unit: inventoryItem.material_unit || 'a' || '',
        material: inventoryItem,
      });
    },

    updateMaterial(material) {
      this.$set(this.part, 'materials', updateItemById(this.part.materials, material));
    },

    removeSelectedMaterial(inventoryItem) {
      this.$set(this.part, 'materials', removeItemById(this.part.materials, inventoryItem.id));
    },
  },
};
</script>

<style scoped></style>
