<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="inventoryItemGroup.title"
              :error-messages="errors['title']"
              :label="formMixin_getRequiredFieldLabel($t('title'))"
              @blur="formMixin_clearErrors('title')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!inventoryItemGroup.id" class="subtitle-2 ml-3">
          * {{ $t('must_be_filled') }}
        </span>

        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import inventoryItemGroupService from '../../api/inventory-item-group-service';

export default {
  name: 'InventoryItemGroupForm',

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    formItem: Object,
    parentGroupId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      inventoryItemGroup: {},
    };
  },

  computed: {
    formTitle() {
      return this.$t(
        this.inventoryItemGroup.id ? 'edit_inventory_item_group' : 'new_inventory_item_group',
      );
    },
  },

  methods: {
    onDialogOpen() {
      this.inventoryItemGroup = JSON.parse(JSON.stringify(this.formItem));
      this.errors = {};
    },

    onSubmit() {
      this.crudMixin_createOrUpdate(inventoryItemGroupService, {
        ...this.inventoryItemGroup,
        parent_group_id: this.parentGroupId,
      });
    },
  },
};
</script>
