var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"expanded":_vm.tableMixin_expandedRows,"headers":_vm.tableMixin_displayedHeaders,"items":_vm.rows,"loading":_vm.loading,"mobile-breakpoint":0,"item-key":"contact_person_id","disable-pagination":"","disable-sort":"","hide-default-footer":""},on:{"click:row":_vm.tableMixin_onRowClick},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.contact_person)?[(!item.id)?_c('div',[_vm._v(" "+_vm._s(item.contact_person.name)+" ")]):_c('div',{staticClass:"link",on:{"click":function($event){$event.stopPropagation();return _vm.tableMixin_expandRow(item)}}},[_vm._v(" "+_vm._s(item.contact_person.name)+" ")])]:_vm._e(),(item.contact_person && item.contact_person.contact)?_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(item.contact_person.contact.name)+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.id)?_c('BaseChipSelect',{attrs:{"value":item.status,"items":_vm.supplierInquiryStatuses},on:{"change":function($event){return _vm.onStatusChange(item, $event)}}}):_vm._e()]}},{key:"item.last_email",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.getLastMessage(item)))]),(item.messages.length)?_c('div',[_c('span',{staticClass:"link",on:{"click":function($event){$event.stopPropagation();return _vm.tableMixin_expandRow(item)}}},[_vm._v(_vm._s(_vm.$t('show_all_emails_short'))+" ("+_vm._s(item.messages.length)+")"),(_vm.getNewMessagesCount(item) > 0)?[_vm._v(". "+_vm._s(_vm.$t('new_emails_short'))+" ("+_vm._s(_vm.getNewMessagesCount(item))+")")]:_vm._e()],2)]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.getRowActions(item),"item":item,"in-dialog":""}})]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.status",fn:function(){return [(item.id)?_c('BaseChipSelect',{attrs:{"value":item.status,"items":_vm.supplierInquiryStatuses},on:{"change":function($event){return _vm.onStatusChange(item, $event)}}}):_vm._e()]},proxy:true},{key:"append",fn:function(){return [(item.id)?_c('SupplierInquiryMessages',{key:item.id,staticClass:"mb-4",attrs:{"supplier-inquiry-id":item.id}}):_vm._e()]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }