<template>
  <BaseFilterDrawer
    :is-open="isDrawerOpen"
    :selected-filter-count="selectedFilterCount"
    @reset="filterMixin_resetFilter"
    @toggle:drawer="$emit('toggle:drawer', $event)"
  >
    <v-text-field
      :value="filters.search"
      :label="$t('search')"
      clearable
      @input="filterMixin_handleDebounce('search', $event)"
    />

    <BaseAutocomplete
      :key="responsibleUserAutocompleteKey"
      :value="filters.responsible_user_id"
      :search-function="searchUsers"
      :get-by-id-function="getUserById"
      :label="$t('responsible_user')"
      item-text="full_name"
      no-filter
      clearable
      @input="filterMixin_updateFilter('responsible_user_id', $event)"
    />

    <BaseAutocomplete
      :value="filters.created_by"
      :search-function="searchUsers"
      :get-by-id-function="getUserById"
      :label="$t('created_by')"
      item-text="full_name"
      no-filter
      clearable
      @update:initial-item="filterMixin_updateFilter('created_by', $event ? $event.id : null)"
    />

    <BaseDatepickerInput
      :value="filters.created_at_from"
      :label="$t('created_at_from')"
      @input="filterMixin_updateFilter('created_at_from', $event)"
    />

    <BaseDatepickerInput
      :value="filters.created_at_to"
      :label="$t('created_at_to')"
      @input="filterMixin_updateFilter('created_at_to', $event)"
    />

    <BaseDatepickerInput
      :value="filters.deadline_at_from"
      :label="$t('deadline_at_from')"
      @input="filterMixin_updateFilter('deadline_at_from', $event)"
    />

    <BaseDatepickerInput
      :value="filters.deadline_at_to"
      :label="$t('deadline_at_to')"
      @input="filterMixin_updateFilter('deadline_at_to', $event)"
    />

    <div v-if="!offersView" class="mt-4">
      <BaseCheckbox
        :checked="!!filters.is_shipped"
        :label="$t('is_shipped')"
        @change="filterMixin_updateFilter('is_shipped', $event ? 1 : 0)"
      />

      <BaseCheckbox
        :checked="!!filters.is_invoice_issued"
        :label="$t('is_invoice_issued')"
        @change="filterMixin_updateFilter('is_invoice_issued', $event ? 1 : 0)"
      />
    </div>

    <div class="mt-6">
      <v-checkbox
        v-for="status in orderStatuses"
        :key="status.value"
        :input-value="filters.status"
        :label="status.filterLabel"
        :value="status.value"
        hide-details
        @change="filterMixin_updateFilter('status', $event)"
      />
    </div>
  </BaseFilterDrawer>
</template>

<script>
import { ORDER_STATUSES } from '../../api/order-service';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';
import BaseAutocomplete from '@/components/base/BaseAutocomplete';
import userService from '@/api/user-service';
import BaseCheckbox from '@/components/base/BaseCheckbox';
import filterMixin from '@/mixins/filter-mixin';
import BaseFilterDrawer from '@/components/filters/BaseFilterDrawer';

export default {
  name: 'OrderFilter',

  components: { BaseFilterDrawer, BaseCheckbox, BaseAutocomplete, BaseDatepickerInput },

  mixins: [filterMixin],

  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    offersView: {
      type: Boolean,
      default: false,
    },
    isDrawerOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      responsibleUserAutocompleteKey: 0,
    };
  },

  computed: {
    orderStatuses() {
      return ORDER_STATUSES;
    },
  },

  watch: {
    '$route.query.responsible_user_id': {
      handler() {
        // when responsible_user_id gets set by clicking on a user in OrderTable
        // this is needed to reload the component, so the selected user is visible in it
        this.responsibleUserAutocompleteKey += 1;
      },
    },
  },

  methods: {
    searchUsers: userService.search,

    getUserById(id) {
      return userService.getById(id, true);
    },
  },
};
</script>

<style scoped></style>
