<template>
  <v-autocomplete
    :value="value"
    :search-input.sync="query"
    :error-messages="errorMessages"
    :loading="isDataLoading"
    :disabled="disabled"
    :hint="autoCompleteHint"
    :label="label"
    :item-text="itemText"
    :items="computedItems"
    :item-value="itemValue"
    :clearable="clearable"
    :prepend-icon="prependedIcon"
    :append-icon="appendedIcon"
    :append-outer-icon="appendedOuterIcon"
    :hide-selected="hideSelected"
    :multiple="multiple"
    :chips="chips"
    :deletable-chips="deletableChips"
    hide-no-data
    return-object
    @blur="$emit('blur', $event)"
    @click:append="$emit('click:append')"
    @click:append-outer="$emit('click:append-outer')"
    @input="onInput"
  >
    <template v-if="useCustomItemSlot" v-slot:item="{ item }">
      <slot name="item" :item="item" />
    </template>

    <template v-if="useCustomSelectionSlot" v-slot:selection="{ item, on, selected, attr }">
      <slot name="selection" :item="item" :on="on" :attr="attr" :selected="selected" />
    </template>
  </v-autocomplete>
</template>

<script>
// component is deprecated, don't use in new code
// use BaseAutocomplete.vue instead
export default {
  name: 'BaseAutocompleteDeprecated',

  props: {
    label: {
      type: String,
      default: '',
    },

    itemText: {
      type: String,
      default: 'name',
    },

    itemValue: {
      type: String,
      default: 'id',
    },

    value: {
      type: [Object, Array, null],
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    appendedIcon: {
      type: String,
      default: '',
    },

    appendedOuterIcon: {
      type: String,
      default: '',
    },

    prependedIcon: {
      type: String,
      default: 'mdi-magnify',
    },

    searchFunction: {
      type: Function,
      required: true,
    },

    hideSelected: {
      type: Boolean,
      default: true,
    },

    useCustomItemSlot: {
      // when set to true, will expect a custom item slot in the parent
      type: Boolean,
      default: false,
    },

    useCustomSelectionSlot: {
      // when set to true, will expect a custom selection slot in the parent
      type: Boolean,
      default: false,
    },

    hint: {
      type: String,
      default: null,
    },

    multiple: {
      // when set to true currently supports only object arrays
      type: Boolean,
      default: false,
    },

    chips: {
      type: Boolean,
      default: false,
    },

    deletableChips: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      items: [],
      isDataLoading: false,
      query: '',
      timerID: null,
    };
  },

  computed: {
    autoCompleteHint() {
      if (typeof this.hint === 'string') {
        return this.hint;
      }
      if (this.value && !this.multiple) {
        return '';
      }
      if (this.multiple && this.value.length) {
        return '';
      }
      return !this.query || this.query.length < 3 ? this.$t('enter_three_or_more_symbols') : '';
    },

    computedItems() {
      if (!this.multiple) {
        return this.items;
      }

      const items = [...this.items];

      for (let i = 0; i < this.value.length; i++) {
        let found = false;
        for (let j = 0; j < this.items.length; j++) {
          if (this.items[j][this.itemValue] === this.value[i][this.itemValue]) {
            found = true;
            break;
          }
        }
        if (!found) {
          items.push(this.value[i]);
        }
      }

      return items;
    },
  },

  watch: {
    query(val) {
      clearTimeout(this.timerID);
      if (!val) {
        // this.$emit('input', null);
        return;
      }
      if (val.length < 3) {
        return;
      }
      if (this.value && this.value[this.itemText] === val) {
        return;
      }
      this.timerID = setTimeout(() => {
        this.getItems(val);
      }, 500);
    },

    value(val) {
      if (!val) {
        return;
      }

      if (!this.multiple) {
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i][this.itemValue] === val[this.itemValue]) {
            return;
          }
        }
        this.items.push(val);
      }
    },
  },

  created() {
    if (this.multiple) {
      this.items.push(...this.value);
    } else if (this.value) {
      this.items.push(this.value);
    }
  },

  methods: {
    onInput(val) {
      this.$emit('input', val);
    },

    async getItems(v) {
      this.isDataLoading = true;
      try {
        const res = await this.searchFunction(v);
        this.items = res.data.data ? res.data.data : res.data;
      } catch (e) {
        this.items = [];
      }
      this.isDataLoading = false;
    },
  },
};
</script>
