import http from './http';

const endpoint = 'api/inventory-item-dispatches';

const inventoryItemDispatchService = {
  model: 'inventoryItemDispatch',

  getPage: (page, params) => http.get(endpoint, { params: { ...params, page } }),

  create: inventoryItemDispatch => http.post(`${endpoint}`, inventoryItemDispatch),

  update: inventoryItemDispatch => {
    const inventoryItemDispatchCopy = inventoryItemDispatch;
    if (!inventoryItemDispatchCopy.order_part_id) {
      delete inventoryItemDispatchCopy.order_part_id;
    }
    if (!inventoryItemDispatchCopy.inventory_item_purchase_id) {
      delete inventoryItemDispatchCopy.inventory_item_purchase_id;
    }
    return http.put(`${endpoint}/${inventoryItemDispatch.id}`, inventoryItemDispatchCopy);
  },

  delete: inventoryItemDispatch => http.delete(`${endpoint}/${inventoryItemDispatch.id}`),
};

export default inventoryItemDispatchService;
