<template>
  <div class="pb-3">
    <div class="d-flex justify-space-between align-center py-2">
      <span class="subtitle-2">
        {{ $t('measurements') }}
      </span>
      <v-btn v-if="!workerView" color="primary" @click="createMeasurement">
        <v-icon left>mdi-plus</v-icon>
        {{ $t('create_measurement') }}
      </v-btn>
    </div>
    <v-data-table
      :expanded="tableMixin_expandedRows"
      :headers="tableMixin_displayedHeaders"
      :items="measurements"
      :loading="isDataLoading"
      :mobile-breakpoint="0"
      disable-sort
      disable-pagination
      hide-default-footer
      @click:row="tableMixin_onRowClick"
    >
      <template v-slot:item.values="{ item }">
        {{ item.values.join(', ') }}
      </template>

      <template v-slot:item.actions="{ item }">
        <BaseActionMenu
          v-if="!workerView"
          :actions="actions"
          :item="item"
        />
        <v-btn
          v-else-if="canFillInMeasures"
          class="float-right my-2"
          color="primary"
          large
          block
          @click="editMeasurement(item)"
        >
          {{ $t('fill_in') }}
        </v-btn>
      </template>

      <template v-slot:expanded-item="{ item }">
        <BaseExpandedTableRow
          :colspan="tableMixin_displayedHeaders.length"
          :headers="tableMixin_hiddenHeaders"
          :item="item"
        >
          <template v-slot:item.values>
            {{ item.values.join(', ') }}
          </template>
        </BaseExpandedTableRow>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import BaseActionMenu from '../base/BaseActionMenu';
import BaseExpandedTableRow from '../base/BaseExpandedTableRow';
import tableMixin from '../../mixins/table-mixin';
import { OPEN_MEASUREMENT_FORM, REMOVE_MEASUREMENT } from '../../store/mutation-types';
import crudMixin from '../../mixins/crud-mixin';
import measurementService from '../../api/measurement.service';
import { FETCH_MEASUREMENTS } from '../../store/action-types';

export default {
  name: 'MeasurementTable',

  components: {
    BaseExpandedTableRow,
    BaseActionMenu,
  },

  mixins: [tableMixin, crudMixin],

  props: {
    orderPartProcessId: {
      type: Number,
      required: true,
    },
    workerView: Boolean,
    canFillInMeasures: Boolean,
  },

  data() {
    return {
      actions: [
        {
          callback: p => this.editMeasurement(p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: p => this.deleteMeasurement(p),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ],
      headers: [
        {
          text: this.$t('title'),
          value: 'name',
        },
        {
          text: this.$t('repetitions'),
          value: 'repetitions',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('values'),
          value: 'values',
          hidden: 'xsOnly',
        },
        {
          value: 'actions',
        },
      ],
      isDataLoading: true,
    };
  },

  computed: {
    measurements() {
      return this.$store.state.measurements.measurements;
    },
  },

  watch: {
    async orderPartProcessId(newValue) {
      this.isDataLoading = true;
      await this.$store.dispatch(FETCH_MEASUREMENTS, newValue);
      this.isDataLoading = false;
    },
  },

  async created() {
    await this.$store.dispatch(FETCH_MEASUREMENTS, this.orderPartProcessId);
    this.isDataLoading = false;
  },

  methods: {
    createMeasurement() {
      this.$store.commit(OPEN_MEASUREMENT_FORM, {
        values: [],
        order_part_process_id: this.orderPartProcessId,
      });
    },
    deleteMeasurement(measurement) {
      this.crudMixin_delete(measurementService.delete, 'measurement', measurement).then(() => {
        this.$store.commit(REMOVE_MEASUREMENT, measurement);
      });
    },
    editMeasurement(measurement) {
      this.$store.commit(OPEN_MEASUREMENT_FORM, measurement);
    },
  },
};
</script>
