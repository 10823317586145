<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-text class="px-1 pt-1">
        <DocumentLibrary
          :selected-documents="selectedDocuments"
          embedded
          @click:document="onDocumentClick"
        />

        <v-row class="px-6 pt-6" style="min-height: 106px" dense>
          <template v-if="documentToAdd.id">
            <v-col cols="12" sm="6" class="d-flex align-center">
              {{ $t('selected_document') }}:
              <span class="black--text ml-1">{{ documentToAdd.file_name }}</span>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model="documentType"
                :items="documentTypes"
                :label="$t('document_type')"
              />
            </v-col>
          </template>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('cancel')">
          {{ $t('close') }}
        </v-btn>

        <v-btn type="submit" color="primary" text>
          {{ $t('add') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import dialogMixin from '@/mixins/dialog-mixin';
import { ORDER_PART_DOCUMENT_TYPES } from '@/api/order-part-service';
import DocumentLibrary from '@/views/DocumentLibrary';

export default {
  name: 'DocumentLibraryPicker',

  components: { DocumentLibrary },

  mixins: [dialogMixin],

  data() {
    return {
      documentToAdd: {},
      selectedDocuments: [],
      documentType: '',
    };
  },

  computed: {
    documentTypes() {
      return ORDER_PART_DOCUMENT_TYPES;
    },
  },

  methods: {
    onDialogOpen() {
      this.documentToAdd = {};
      this.selectedDocuments = [];
      if (!this.documentType) {
        this.documentType = this.documentTypes[0].value;
      }
    },

    onDocumentClick(document) {
      this.selectedDocuments = [document];
      if (!document.is_folder) {
        this.documentToAdd = document;
      }
    },

    onSubmit() {
      this.$emit('submit', { document: this.documentToAdd, type: this.documentType });
    },
  },
};
</script>
