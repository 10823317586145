<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model.number="inventoryItemDispatch.quantity"
              :error-messages="
                errors['quantity'] ||
                  errors['inventory_item_purchase_id'] ||
                  errors['order_part_id']
              "
              :label="formMixin_getRequiredFieldLabel($t('quantity'))"
              type="number"
              @blur="formMixin_clearErrors('quantity')"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="inventoryItemDispatch.comment"
              :error-messages="errors['comment']"
              :label="$t('invoice_no')"
              rows="1"
              auto-grow
              @blur="formMixin_clearErrors('comment')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!inventoryItemDispatch.id" class="subtitle-2 ml-3">
          * {{ $t('must_be_filled') }}
        </span>

        <v-spacer />

        <v-btn color="primary" text @click.native="onClose">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '@/mixins/crud-mixin';
import dialogMixin from '@/mixins/dialog-mixin';
import formMixin from '@/mixins/form-mixin';
import inventoryItemDispatchService from '@/api/inventory-item-dispatch-service';
import clone from 'just-clone';
import {
  getInventoryItemQuantityForBackend,
  getInventoryItemQuantityNumber,
} from '@/util/inventory-item-quantity';

export default {
  name: 'InventoryItemDispatchForm',

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    // should only pass one of orderPart or inventoryItemPurchase at most
    // If inventoryItemPurchase or orderPart is passed, inventoryItem is required
    orderPart: {
      type: Object,
      default: () => ({}),
    },

    inventoryItemPurchase: {
      type: Object,
      default: () => ({}),
    },
    inventoryItem: {
      type: Object,
      default: () => ({}),
    },
    // ===============================

    formItem: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      inventoryItemDispatch: {},
    };
  },

  computed: {
    formTitle() {
      return this.$t(
        this.inventoryItemDispatch.id
          ? 'edit_inventory_item_dispatch'
          : 'new_inventory_item_dispatch',
      );
    },

    materialUnit() {
      if (this.inventoryItemDispatch.purchase?.material_unit) {
        // when editing a dispatch from an inventory item purchase
        return this.inventoryItemDispatch.purchase.material_unit;
      }

      if (this.inventoryItemDispatch.part?.inventory_item?.material_unit) {
        // when editing a dispatch from an order part
        return this.inventoryItemDispatch.part.inventory_item.material_unit;
      }

      if (this.inventoryItemPurchase.id) {
        // when creating a dispatch from an inventory item purchase
        return this.inventoryItemPurchase.material_unit;
      }

      if (this.inventoryItem.id) {
        // when creating a dispatch from an order part
        return this.inventoryItem.material_unit;
      }

      return 'a';
    },

    unitABRatio() {
      if (this.inventoryItemDispatch.purchase?.inventory_item?.unit_a_b_ratio) {
        // when editing a dispatch from an inventory item purchase
        return this.inventoryItemDispatch.purchase.inventory_item.unit_a_b_ratio;
      }

      if (this.inventoryItemDispatch.part?.inventory_item?.unit_a_b_ratio) {
        // when editing a dispatch from an order part
        return this.inventoryItemDispatch.part.inventory_item.unit_a_b_ratio;
      }

      if (this.inventoryItem.id) {
        // when creating a dispatch from an order part or an inventory item purchase
        return this.inventoryItem.unit_a_b_ratio;
      }

      return 1;
    },

    initialQuantity() {
      if (this.formItem.quantity) {
        // when editing a dispatch or quantity was set before opening the form
        return this.formItem.quantity;
      }

      if (this.inventoryItemPurchase.id && this.inventoryItemPurchase.dispatches) {
        // when creating a dispatch from an inventory item purchase
        const quantityDispatched = this.inventoryItemPurchase.dispatches.reduce(
          (sum, dispatch) => +sum + +dispatch.quantity,
          0,
        );
        return +this.inventoryItemPurchase.quantity_ordered - quantityDispatched;
      }

      if (this.orderPart.id) {
        // when editing a dispatch from an order part
        return +this.orderPart.quantity_in_warehouse - +this.orderPart.quantity_issued;
      }

      return 0;
    },
  },

  methods: {
    onDialogOpen() {
      this.inventoryItemDispatch = JSON.parse(JSON.stringify(this.formItem));
      const quantity = getInventoryItemQuantityNumber(
        this.initialQuantity,
        this.materialUnit,
        this.unitABRatio,
      );
      this.$set(this.inventoryItemDispatch, 'quantity', quantity);

      if (!this.inventoryItemDispatch.id && this.inventoryItemPurchase.id) {
        // when creating a dispatch from an inventory item purchase
        this.inventoryItemDispatch.inventory_item_purchase_id = this.inventoryItemPurchase.id;
      }
      if (!this.inventoryItemDispatch.id && this.orderPart.id) {
        // when creating a dispatch from an order part
        this.inventoryItemDispatch.order_part_id = this.orderPart.id;
      }
      this.errors = {};
    },

    async onSubmit() {
      const payload = clone(this.inventoryItemDispatch);
      payload.quantity = getInventoryItemQuantityForBackend(
        this.inventoryItemDispatch.quantity,
        this.materialUnit,
        this.unitABRatio,
      );
      await this.crudMixin_createOrUpdate(inventoryItemDispatchService, payload);
      this.transformValidationErrors();
    },

    transformValidationErrors() {
      // If dispatched quantity is exceeded backend throws this specific error
      // on either inventory_item_purchase_id or order_part_id key
      // depending on whether the dispatch is from order part or inventory item purchase
      if (
        this.errors.inventory_item_purchase_id &&
        this.errors.inventory_item_purchase_id[0] ===
          'Pasirinkta negaliojanti inventory item purchase id reikšmė.'
      ) {
        this.errors.inventory_item_purchase_id = this.$t('ordered_dispatched_quantity_error');
      }
      if (
        this.errors.order_part_id &&
        this.errors.order_part_id[0] === 'Pasirinkta negaliojanti Užsakymo dalis reikšmė.'
      ) {
        this.errors.order_part_id = this.$t('ordered_dispatched_quantity_error');
      }
    },

    onClose() {
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
