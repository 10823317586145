<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :headers="tableMixin_displayedHeaders"
    :items="rows"
    :loading="loading"
    :mobile-breakpoint="0"
    item-key="contact_person_id"
    disable-pagination
    disable-sort
    hide-default-footer
    @click:row="tableMixin_onRowClick"
  >
    <template v-slot:item.name="{ item }">
      <template v-if="item.contact_person">
        <div v-if="!item.id">
          {{ item.contact_person.name }}
        </div>
        <div v-else class="link" @click.stop="tableMixin_expandRow(item)">
          {{ item.contact_person.name }}
        </div>
      </template>

      <div v-if="item.contact_person && item.contact_person.contact" class="grey--text">
        {{ item.contact_person.contact.name }}
      </div>
    </template>

    <template v-slot:item.status="{ item }">
      <BaseChipSelect
        v-if="item.id"
        :value="item.status"
        :items="supplierInquiryStatuses"
        @change="onStatusChange(item, $event)"
      />
    </template>

    <template v-slot:item.last_email="{ item }">
      <div>{{ getLastMessage(item) }}</div>
      <div v-if="item.messages.length">
        <span class="link" @click.stop="tableMixin_expandRow(item)"
          >{{ $t('show_all_emails_short') }} ({{ item.messages.length }})<template
            v-if="getNewMessagesCount(item) > 0"
            >. {{ $t('new_emails_short') }} ({{ getNewMessagesCount(item) }})</template
          >
        </span>
      </div>
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu :actions="getRowActions(item)" :item="item" in-dialog />
    </template>

    <template v-slot:expanded-item="{ item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.status>
          <BaseChipSelect
            v-if="item.id"
            :value="item.status"
            :items="supplierInquiryStatuses"
            @change="onStatusChange(item, $event)"
          />
        </template>

        <template v-slot:append>
          <SupplierInquiryMessages
            v-if="item.id"
            :key="item.id"
            :supplier-inquiry-id="item.id"
            class="mb-4"
          />
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import BaseActionMenu from '../base/BaseActionMenu';
import BaseExpandedTableRow from '../base/BaseExpandedTableRow';
import tableMixin from '../../mixins/table-mixin';
import BaseChipSelect from '@/components/base/BaseChipSelect';
import supplierInquiryService from '@/api/supplier-inquiry-service';
import { updateArrayItem } from '@/util/array';
import { openSnackbar } from '@/util/event-bus';
import SupplierInquiryMessages from '@/components/SupplierInquiryMessages';

export default {
  name: 'SupplierInquiryTable',

  components: {
    SupplierInquiryMessages,
    BaseChipSelect,
    BaseExpandedTableRow,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    rows: {
      type: Array,
      required: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    inventoryItemPurchaseId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      tableRowKey: 'contact_person_id',
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name',
        },
        {
          text: this.$t('email'),
          value: 'contact_person.email',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('status'),
          value: 'status',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('last_email'),
          value: 'last_email',
          hidden: 'xsOnly',
          hideInExpandedRow: true,
        },
        {
          value: 'actions',
          align: 'end',
        },
      ];
    },

    supplierInquiryStatuses() {
      return [
        { value: 'inquired', text: this.$t('inquired'), color: 'yellow lighten-4' },
        { value: 'ordered', text: this.$t('ordered'), color: 'blue lighten-4' },
      ];
    },
  },

  methods: {
    getLastMessage(item) {
      if (!item.messages?.length) {
        return '';
      }
      const { sender_name, body } = item.messages[0];
      return `${sender_name}: ${body}`;
    },

    getNewMessagesCount(item) {
      return item.messages.reduce((count, message) => (!message.is_seen ? count + 1 : count), 0);
    },

    getRowActions(item) {
      const actions = [];
      if (!item.id && this.inventoryItemPurchaseId) {
        actions.push({
          callback: p => this.sendInquiry(p),
          loading: this.$store.getters.loading['post:api/supplier-inquiries'],
          label: this.$t('send'),
          icon: 'mdi-send',
        });
      }

      if (!item.id) {
        actions.push({
          callback: p => this.$emit('remove', p),
          label: this.$t('remove'),
          icon: 'mdi-close',
        });
      }
      return actions;
    },

    async onStatusChange(inquiry, newStatus) {
      const payload = {
        ...inquiry,
        status: newStatus,
      };
      const { data } = await supplierInquiryService.changeStatus(payload);
      openSnackbar(this.$t('supplier_inquiry_updated'));
      const newValue = updateArrayItem(this.rows, data);
      this.$emit('input', newValue);
    },

    async sendInquiry(inquiry) {
      const { data } = await supplierInquiryService.create(inquiry);
      const newValue = updateArrayItem(this.rows, data, 'contact_person_id');
      this.$emit('input', newValue);
    },
  },
};
</script>
