/* eslint-disable import/prefer-default-export,no-param-reassign */
import { addDays, format, isWeekend, isBefore } from 'date-fns';

const HOLIDAYS = [
  '01-01',
  '02-16',
  '03-11',
  '05-01',
  '06-24',
  '07-06',
  '08-15',
  '11-01',
  '11-02',
  '12-24',
  '12-25',
  '12-26',
];

const holidays = new Set();

const yearsProcessed = new Set(); // so it doesn't process holidays every single time the functions are called

// Calculates Easter in the Gregorian/Western (Catholic and Protestant) calendar
// based on the algorithm by Oudin (1940) from http://www.tondering.dk/claus/cal/easter.php
function getEaster(year) {
  const f = Math.floor;
  // Golden Number - 1
  const G = year % 19;
  const C = f(year / 100);
  // related to Epact
  const H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30;
  // number of days from 21 March to the Paschal full moon
  const I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11));
  // weekday for the Paschal full moon
  const J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7;
  // number of days from 21 March to the Sunday on or before the Paschal full moon
  const L = I - J;
  const month = 3 + f((L + 40) / 44);
  const day = L + 28 - 31 * f(month / 4);
  return `${year}/${month - 1}/${day}`;
}

function addHolidays(year) {
  for (let i = 0; i < HOLIDAYS.length; i++) {
    holidays.add(`${year}-${HOLIDAYS[i]}`);
  }
  const firstEasterDay = new Date(getEaster(year));
  const secondEasterDay = addDays(firstEasterDay, 1);
  holidays.add(format(firstEasterDay, 'yyyy-MM-dd'));
  holidays.add(format(secondEasterDay, 'yyyy-MM-dd'));
  yearsProcessed.add(year);
}

function getNextWorkDay(date) {
  if (holidays.has(format(date, 'yyyy-MM-dd')) || isWeekend(date)) {
    date = getNextWorkDay(addDays(date, 1));
  }
  return date;
}

export function addWorkDays(dateFrom, amountOfDays) {
  let date = new Date(dateFrom);
  let days = Math.abs(amountOfDays);
  let year;
  const step = amountOfDays > 0 ? 1 : -1;

  while (days > 0) {
    date = addDays(date, step);
    days -= 1;

    year = format(date, 'yyyy');
    if (!yearsProcessed.has(year)) {
      addHolidays(year);
    }

    date = getNextWorkDay(date);
  }

  return date;
}

export function getWorkDayCount(dateFrom, dateTo) {
  let workDays = 0;
  let year;

  for (let d = dateFrom; isBefore(d, dateTo); d = addDays(d, 1)) {
    year = format(d, 'yyyy');
    if (!yearsProcessed.has(year)) {
      addHolidays(year);
    }

    if (!holidays.has(format(d, 'yyyy-MM-dd')) && !isWeekend(d)) {
      workDays += 1;
    }
  }
  return workDays;
}
