import http from './http';

const endpoint = 'api/contact-persons';

const contactPersonService = {
  model: 'contactPerson',

  getById: id => http.get(`${endpoint}/${id}`),

  search: query => http.get(`${endpoint}/find/${query}`),

  searchClients: query => http.get(`${endpoint}/find/${query}?is_client=true`),

  searchSuppliers: query => http.get(`${endpoint}/find/${query}?is_supplier=true`),
};

export default contactPersonService;
