<template>
  <div>
    <v-textarea
      v-model="message.body"
      :error-messages="errors['body']"
      :label="bodyLabel"
      :outlined="outlineInputs"
      :rows="bodyRows"
      class="mb-2"
      auto-grow
      @input="formMixin_clearErrors('body')"
    />

    <v-btn
      :loading="sending"
      :disabled="sending"
      :block="bigSubmitButton"
      :class="{ 'mt-2': bigSubmitButton }"
      color="primary"
      @click="submitAnswer"
    >
      {{ $t('send') }}
    </v-btn>
  </div>
</template>

<script>
import formMixin from '@/mixins/form-mixin';
import supplierInquiryMessageService from '@/api/supplier-inquiry-message-service';
import { openSnackbar } from '@/util/event-bus';
import { mapErrorsToInputs } from '@/util/forms';
import { mapGetters } from 'vuex';
import i18n from '@/i18n/i18n-config';

export default {
  name: 'SupplierInquiryMessageForm',

  mixins: [formMixin],

  props: {
    outlineInputs: {
      type: Boolean,
      default: false,
    },
    supplierInquiryId: {
      type: Number,
      default: null,
    },
    bigSubmitButton: {
      type: Boolean,
      default: false,
    },
    bodyLabel: {
      type: String,
      default: () => i18n.t('answer'),
    },
    bodyRows: {
      type: String,
      default: '2',
    },
  },

  data() {
    return {
      message: {},
      errors: {},
    };
  },

  computed: {
    ...mapGetters(['loading']),

    sending() {
      return this.loading['post:api/supplier-inquiry-messages'];
    },
  },

  methods: {
    async submitAnswer() {
      const payload = {
        ...this.message,
        supplier_inquiry_id: this.supplierInquiryId,
      };
      try {
        const { data } = await supplierInquiryMessageService.create(payload);
        openSnackbar(this.$t('answer_sent'));
        this.$emit('created', data);
        this.message = {};
      } catch (e) {
        this.errors = mapErrorsToInputs(e);
      }
    },
  },
};
</script>

<style scoped></style>
