<template>
  <div>
    <v-menu
      v-model="isProcessMenuOpen"
      :close-on-content-click="false"
      :left="left"
      :nudge-bottom="nudgeBottom"
      offset-y
      offset-overflow
    >
      <template #activator="{ on }">
        <div v-on="on" class="d-inline">
          <slot name="activator">
            <v-btn class="mb-3" color="primary">
              <v-icon left>mdi-plus</v-icon>
              {{ $t('add_processes') }}
            </v-btn>
          </slot>
        </div>
      </template>
      <v-sheet>
        <v-text-field
          v-model="query"
          :label="$t('filter')"
          class="px-3 py-3 d-flex align-center"
          clearable
          dense
          hide-details
          outlined
          single-line
        />
        <v-divider />
        <v-list max-height="300" :height="top ? '300px' : 'auto'" class="overflow-y-auto" dense>
          <v-list-item v-if="!filteredProcessGroups.length" key="no-results">
            <v-list-item-title class="grey--text">
              {{ $t('no_results') }}
            </v-list-item-title>
          </v-list-item>
          <template v-for="item in filteredProcessGroups">
            <v-list-group v-if="item.processes" :key="`group-${item.id}`">
              <template #activator>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="process in item.processes"
                :key="process.id"
                @click="onMenuSelect(process)"
              >
                <v-list-item-title>
                  {{ process.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-item v-else :key="`process-${item.id}`" @click="onMenuSelect(item)">
              <v-list-item-title class="d-flex align-center justify-space-between">
                <div>{{ item.title }}</div>
                <v-progress-circular
                  v-if="loading[`get:api/processes/${item.id}/machinery-availability`]"
                  color="primary"
                  size="18"
                  width="2"
                  class="mr-1"
                  indeterminate
                />
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-sheet>
    </v-menu>

    <v-dialog
      v-model="isMachineryDialogOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="620"
      scrollable
      @click:outside="onMachineryCancel"
    >
      <v-card>
        <v-card-title>
          {{ $t('process_is_being_added').replace('{0}', selectedProcess.title) }}
        </v-card-title>
        <v-card-text>
          <p>
            {{ $t('machinery_busy_until_will_be_set_as_process_start_date') }}
          </p>
          <MachineryList :machineries="machineries" @click:row="onMachinerySelect" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="onMachineryCancel">{{
            $t('add_without_setting_estimated_start')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import processService from '@/api/process-service';
import { mapGetters } from 'vuex';
import MachineryList from '@/components/MachineryList';
import { format, setHours } from 'date-fns';

export default {
  name: 'ProcessSelectMenu',

  components: { MachineryList },

  props: {
    processGroups: {
      type: Array,
      default: () => [],
    },
    hiddenProcesses: {
      type: Array,
      default: () => [],
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    nudgeBottom: {
      type: String,
      default: '8',
    },
    checkMachineryAvailabilityOnSelect: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isProcessMenuOpen: false,
      query: '',

      isMachineryDialogOpen: false,
      selectedProcess: {},
      machineries: [],
    };
  },

  computed: {
    ...mapGetters(['loading']),

    // Returns a sorted array of process groups with processes that match query.
    // If process group has only one process, moves that process out to the top level.
    filteredProcessGroups() {
      const query = this.query ? this.query.toLowerCase() : '';
      const groups = [];
      for (let i = 0; i < this.processGroups.length; i++) {
        const group = this.processGroups[i];
        const processes = [];
        for (let j = 0; j < group.processes.length; j++) {
          const process = group.processes[j];
          if (
            !this.isProcessHidden(process) &&
            process.title
              .trim()
              .toLowerCase()
              .indexOf(query) > -1
          ) {
            processes.push(process);
          }
        }
        if (processes.length > 1) {
          groups.push({
            id: group.id,
            title: group.name,
            processes,
          });
        } else if (processes.length === 1) {
          groups.push(processes[0]);
        }
      }
      return groups.sort((g1, g2) => (g1.title < g2.title ? -1 : 1));
    },
  },

  methods: {
    isProcessHidden(process) {
      for (let k = 0; k < this.hiddenProcesses.length; k++) {
        if (this.hiddenProcesses[k].id === process.id) {
          return true;
        }
      }
      return false;
    },

    onMenuSelect(process) {
      if (this.loading[`get:api/processes/${process.id}/machinery-availability`]) {
        return;
      }
      if (this.checkMachineryAvailabilityOnSelect) {
        this.selectedProcess = process;
        this.fetchMachineryAvailability(process);
        return;
      }
      this.$emit('select', process);
      if (this.closeOnSelect) {
        this.isProcessMenuOpen = false;
      }
    },

    onMachinerySelect(machinery) {
      this.isMachineryDialogOpen = false;
      let busyUntil = null;
      if (machinery.busy_until) {
        busyUntil = format(setHours(new Date(machinery.busy_until), 8), 'yyyy-MM-dd HH:mm');
      }
      this.$emit('select', this.selectedProcess, busyUntil);
    },

    onMachineryCancel() {
      this.isMachineryDialogOpen = false;
      this.$emit('select', this.selectedProcess);
    },

    async fetchMachineryAvailability(process) {
      const { data } = await processService.getMachineryAvailability(process.id);
      if (!data.length) {
        this.$emit('select', this.selectedProcess);
        return;
      }
      this.machineries = data;
      this.isMachineryDialogOpen = true;
    },
  },
};
</script>
