var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:{ 'content-loading': _vm.loading },attrs:{"expanded":_vm.tableMixin_expandedRows,"footer-props":{
    'items-per-page-options': [_vm.pagination.rowsPerPage || 50],
  },"headers":_vm.tableMixin_displayedHeaders,"items":_vm.rows,"mobile-breakpoint":0,"page":_vm.pagination.page || 1,"server-items-length":_vm.pagination.totalItems || -1,"loading":_vm.loading,"disable-sort":""},on:{"update:page":_vm.tableMixin_changePage,"click:row":_vm.tableMixin_onRowClick},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("inventory_item_price_history_types." + (item.type))))+" ")]}},{key:"item.contact_id",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.contact ? item.contact.name : '')+" ")]}},{key:"item.comments",fn:function(ref){
  var item = ref.item;
return [(item.comments)?_c('v-tooltip',{attrs:{"transition":"none","max-width":"30em","content-class":"tooltip-in-dialog","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-comment")])]}}],null,true)},[_c('div',{staticClass:"text-pre-wrap"},[_vm._v(_vm._s(item.comments))])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.getRowActions(),"item":item}})]}},{key:"expanded-item",fn:function(ref){
  var headers = ref.headers;
  var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.type",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(("inventory_item_price_history_types." + (item.type))))+" ")]},proxy:true},{key:"item.contact_id",fn:function(){return [_vm._v(" "+_vm._s(item.contact ? item.contact.name : '')+" ")]},proxy:true},{key:"item.comments",fn:function(){return [_c('div',{staticClass:"text-pre-wrap"},[_vm._v(_vm._s(item.comments))])]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }