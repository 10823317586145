<template>
  <div class="wrapper">
    <slot />
    <div v-if="loading" class="overlay" :style="`z-index: ${zIndex}`">
      <v-progress-circular v-if="!hideSpinner" color="primary" size="70" width="4" indeterminate />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseLoadingOverlay',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    hideSpinner: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: [Number, String],
      default: 50,
    },
  },
};
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
}

.wrapper {
  position: relative;
}
</style>
