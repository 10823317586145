<template>
  <div class="page-wrapper">
    <div class="d-flex justify-space-between px-4 pt-2 pb-4 flex-wrap">
      <div
        class="d-flex align-center py-2 justify-space-between justify-sm-start"
        :class="{ 'full-width': $vuetify.breakpoint.xsOnly }"
      >
        <h1 class="title mr-3">
          {{ pageType === 'order' ? $t('orders') : $t('offers') }}
        </h1>
        <v-btn-toggle
          v-model="displayAs"
          color="primary"
          rounded
          dense
          mandatory
          @change="storeDisplayAs"
        >
          <v-btn value="table" color="white">
            <span>{{ $t('table') }}</span>
          </v-btn>

          <v-btn value="cards" color="white">
            <span>{{ $t('cards') }}</span>
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="d-flex flex-wrap py-2 justify-end justify-sm-start mx-auto mx-sm-0">
        <BasePrimaryActionButton
          :label="$t(pageType === 'offer' ? 'create_offer' : 'create_order')"
          class="mr-3"
          @click="crudMixin_openForm('order', { ...newOrderTemplate, type: pageType })"
        />
        <OrderFilter
          :is-drawer-open="isOrderFilterDrawerOpen"
          :filters="orderFilterParams"
          :default-filters="defaultOrderFilterParams"
          class="mr-md-3 mb-3 mb-md-0"
          @toggle:drawer="isOrderFilterDrawerOpen = $event"
          @change="updateFilter"
          @reset="resetFilter"
        />
      </div>
    </div>

    <OrderTable
      :filter-params="orderFilterParams"
      :expanded-order="expandedOrder"
      :rows="decoratedOrders"
      :pagination="orderPagination"
      :loading="$store.getters.loading['get:api/orders']"
      :offers-view="pageType === 'offer'"
      :display-as="displayAs"
      :force-content-wrapping="isOrderFilterDrawerOpen"
      @change-page="getPaginatedOrders"
      @clone="cloneOrder"
      @delete="deleteOrder"
      @edit="crudMixin_openForm('order', $event)"
      @generate-offer="generateOffer"
      @new-estimation="crudMixin_openForm('estimation', $event)"
      @new-group-item="newGroupOrder"
      @new-order-defect="crudMixin_openForm('orderDefect', $event)"
      @new-part="createOrderPart"
      @row-expand="expandOrder"
      @reorder="reorderOrders"
      @update:order="updateOrder"
      @update:filter="$router.push({ name: 'orders', query: $event })"
      @update:items-per-page="updateItemsPerPage"
    >
      <template v-slot:expanded-item-append>
        <BaseFileDropZone
          :accept="documentFileFormats"
          :current-files="orderDocumentArray"
          :upload-button-text="$t('attach_document')"
          :label="$t('order_documents')"
          :delete-function="deleteDocument"
          :upload-function="uploadDocumentFiles"
          multiple
        />

        <v-divider class="my-4" />

        <div v-for="(part, index) in orderPartArray" :key="part.id">
          <OrderPartDetails
            :part="part"
            :order="expandedOrder"
            :index="index + 1"
            :document-file-formats="schemeFileFormats"
            :update-count="orderPartUpdateCount"
            @click:inventory-item-information="viewInventoryItemDetails"
            @click:material-defects="openMaterialDefectsDialog(part)"
            @delete="crudMixin_delete(onOrderPartDelete, 'orderPart', $event)"
            @edit="editOrderPart($event, expandedOrder)"
            @new-item="createOrderPart(expandedOrder)"
            @update="updateOrderPart"
            @update:process-status="updateOrderPartProcess"
          />
          <v-divider v-if="index < orderPartArray.length - 1" class="my-4" />
        </div>

        <div class="text-right">
          <v-btn color="primary" @click="createOrderPart(expandedOrder)">
            <v-icon left>mdi-plus</v-icon>
            {{ $t('create_order_part') }}
          </v-btn>
        </div>

        <v-divider class="my-4" />

        <OrderDefectList
          v-if="expandedOrder.type === 'order'"
          :defects="orderDefectArray"
          @delete="crudMixin_delete(onOrderDefectDelete, 'orderDefect', $event)"
          @edit="crudMixin_openForm('orderDefect', $event)"
          @new-item="
            crudMixin_openForm('orderDefect', {
              order_id: expandedOrder.id,
            })
          "
        />

        <div v-else class="text-right mb-4">
          <v-btn color="primary" @click="crudMixin_openForm('estimation', expandedOrder)">
            <v-icon left>mdi-file</v-icon>
            {{ $t('prepare_estimation') }}
          </v-btn>
        </div>
      </template>
    </OrderTable>

    <v-dialog
      v-model="isOrderFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <OrderForm
        :dialog="isOrderFormOpen"
        :form-item="orderFormItem"
        @create="orderCreated"
        @update="orderUpdated"
        @cancel="isOrderFormOpen = false"
      />
    </v-dialog>

    <v-dialog
      v-model="isOrderDetailsDialogOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="1500"
      scrollable
    >
      <v-card>
        <v-card-title>
          <template v-if="expandedOrder.group"> {{ expandedOrder.group.name }}</template>
          {{ expandedOrder.order_no }}
        </v-card-title>
        <v-card-text>
          <BaseFileDropZone
            :accept="documentFileFormats"
            :current-files="orderDocumentArray"
            :upload-button-text="$t('attach_document')"
            :label="$t('order_documents')"
            :delete-function="deleteDocument"
            :upload-function="uploadDocumentFiles"
            multiple
          />

          <v-divider class="my-4" />

          <div v-for="(part, index) in orderPartArray" :key="part.id">
            <OrderPartDetails
              :part="part"
              :order="expandedOrder"
              :index="index + 1"
              :document-file-formats="schemeFileFormats"
              :update-count="orderPartUpdateCount"
              @click:inventory-item-information="viewInventoryItemDetails"
              @click:material-defects="openMaterialDefectsDialog(part)"
              @delete="crudMixin_delete(onOrderPartDelete, 'orderPart', $event)"
              @edit="editOrderPart($event, expandedOrder)"
              @new-item="createOrderPart(expandedOrder)"
              @update="updateOrderPart"
              @update:process-status="updateOrderPartProcess"
            />
            <v-divider v-if="index < orderPartArray.length - 1" class="my-4" />
          </div>

          <div class="text-right">
            <v-btn color="primary" @click="createOrderPart(expandedOrder)">
              <v-icon left>mdi-plus</v-icon>
              {{ $t('create_order_part') }}
            </v-btn>
          </div>

          <v-divider class="my-4" />

          <OrderDefectList
            v-if="expandedOrder.type === 'order'"
            :defects="orderDefectArray"
            @delete="crudMixin_delete(onOrderDefectDelete, 'orderDefect', $event)"
            @edit="crudMixin_openForm('orderDefect', $event)"
            @new-item="
              crudMixin_openForm('orderDefect', {
                order_id: expandedOrder.id,
              })
            "
          />

          <div v-else class="text-right mb-4">
            <v-btn color="primary" @click="crudMixin_openForm('estimation', expandedOrder)">
              <v-icon left>mdi-file</v-icon>
              {{ $t('prepare_estimation') }}
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="expandOrder(expandedOrder)">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isOrderPartFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="1500"
      persistent
      scrollable
    >
      <OrderPartForm
        :dialog="isOrderPartFormOpen"
        :form-item="orderPartFormItem"
        :process-groups="processGroupArray"
        :order="orderPartOrder"
        @create="orderPartCreated"
        @update="orderPartUpdated"
        @cancel="isOrderPartFormOpen = false"
      />
    </v-dialog>

    <v-dialog
      v-model="isOrderDefectFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <OrderDefectForm
        :dialog="isOrderDefectFormOpen"
        :form-item="orderDefectFormItem"
        @cancel="isOrderDefectFormOpen = false"
        @create="crudMixin_created('orderDefect', $event)"
        @update="crudMixin_updated('orderDefect', $event)"
      />
    </v-dialog>

    <MeasurementsFormDialog />

    <InventoryItemDetails
      :is-open="isInventoryItemDetailsDialogOpen"
      :item="inventoryItem"
      @close="isInventoryItemDetailsDialogOpen = false"
      @click:order-link="inventoryItem = {}"
    />

    <v-dialog
      v-model="isMaterialDefectsDialogOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="900"
      scrollable
    >
      <MaterialDefects
        :key="selectedOrderPart.id"
        :order-part="selectedOrderPart"
        @close="isMaterialDefectsDialogOpen = false"
      />
    </v-dialog>

    <v-dialog
      v-model="isOrderIssuanceDialogOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="600"
      persistent
      scrollable
    >
      <OrderIssuanceForm
        :dialog="isOrderIssuanceDialogOpen"
        :form-item="orderFormItem"
        @cancel="isOrderIssuanceDialogOpen = false"
        @update="orderUpdated"
      />
    </v-dialog>

    <v-dialog
      v-model="isEstimationFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <EstimationForm
        :dialog="isEstimationFormOpen"
        :order="estimationFormItem"
        @cancel="isEstimationFormOpen = false"
      />
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { getWorkDayCount } from '@/api/holiday-service';
import OrderForm from '../components/forms/OrderForm';
import OrderTable from '../components/tables/OrderTable';
import crudMixin from '../mixins/crud-mixin';
import orderService, { getDefaultOrderFilterParams } from '../api/order-service';
import OrderPartForm from '../components/forms/OrderPartForm';
import orderPartService from '../api/order-part-service';
import eventBus, {
  OPEN_SNACKBAR,
  openConfirmDialog,
  UPDATE_INVENTORY_ITEM_QUANTITIES_IN_WAREHOUSE,
} from '../util/event-bus';
import OrderPartDetails from '../components/OrderPartDetails';
import { downloadFileFromAPI } from '../util/files';
import orderDocumentService from '../api/order-document-service';
import OrderDefectForm from '../components/forms/OrderDefectForm';
import OrderDefectList from '../components/tables/OrderDefectList';
import orderDefectService from '../api/order-defect-service';
import { removeItemById, updateItemById } from '../util/array';
import BaseFileDropZone from '../components/base/BaseFileDropZone';
import MeasurementsFormDialog from '../components/forms/MeasurementsFormDialog';
import processGroupService from '../api/process-group-service';
import InventoryItemDetails from '@/components/InventoryItemDetails';
import MaterialDefects from '@/views/MaterialDefects';
import OrderIssuanceForm from '@/components/forms/OrderIssuanceForm';
import { scrollToTargetAdjusted } from '@/util/scrolling';
import BasePrimaryActionButton from '@/components/base/BasePrimaryActionButton';
import OrderFilter from '@/components/filters/OrderFilter';
import clone from 'just-clone';
import { parseArrayQueryParam } from '@/util/url';
import EstimationForm from '@/components/forms/EstimationForm';

export default {
  name: 'Orders',

  components: {
    EstimationForm,
    OrderFilter,
    BasePrimaryActionButton,
    OrderIssuanceForm,
    MaterialDefects,
    InventoryItemDetails,
    MeasurementsFormDialog,
    BaseFileDropZone,
    OrderDefectList,
    OrderDefectForm,
    OrderPartDetails,
    OrderPartForm,
    OrderForm,
    OrderTable,
  },

  mixins: [crudMixin],

  data() {
    return {
      documentFileFormats: '.jpeg,.png,.jpg,.gif,.svg,.pdf,.dwg,.dxf',
      schemeFileFormats: '.jpeg,.png,.jpg,.gif,.svg,.pdf',
      estimationFormItem: {},
      isEstimationFormOpen: false,
      isOrderDefectFormOpen: false,
      isOrderPartFormOpen: false,
      isOrderFormOpen: false,
      newOrderPartTemplate: {
        status: 'not_started',
        material_delivered_by: 'us',
        material_status: 'not_ordered',
        processes: [],
        materials: [],
        document_library_items: [],
        quantity: 1,
      },
      newOrderTemplate: {
        type: 'order',
        status: 'not_started',
        group: {},
        quantity: 1,
      },
      onDownloadFile: downloadFileFromAPI,
      onOrderDefectDelete: orderDefectService.delete,
      onOrderPartDelete: orderPartService.delete,
      expandedOrder: {},
      orderArray: [],
      orderDefectArray: [],
      orderDefectFormItem: {},
      orderDocumentArray: [],
      orderFilterParams: {},
      defaultOrderFilterParams: getDefaultOrderFilterParams(),
      orderFormItem: {},
      orderPagination: {
        page: 1,
      },
      orderPartArray: [],
      orderPartFormItem: {},
      orderPartOrder: {},
      processGroupArray: [],

      inventoryItem: {},
      isInventoryItemDetailsDialogOpen: false,

      selectedOrderPart: {},
      isMaterialDefectsDialogOpen: false,

      isOrderIssuanceDialogOpen: false,

      displayAs: 'table', // or 'cards'
      isOrderDetailsDialogOpen: false,

      isOrderFilterDrawerOpen: false,
      orderPartUpdateCount: 0, // to force recalculations in OrderPartDetails > OrderPartMaterialTable
    };
  },

  computed: {
    decoratedOrders() {
      // appends workDaysTillDeadline for overdue indicators next to orders and their parts
      const today = new Date();

      return this.orderArray.map(o => {
        const order = { ...o };
        if (order.deadline_at && order.status !== 'completed') {
          order.workDaysTillDeadline = order.deadline_at
            ? getWorkDayCount(today, new Date(order.deadline_at))
            : 0;
        }

        if (order.status === 'completed') {
          order.hasCompletedProcessesWithInsufficientManufacturedQuantity = false;
          return order;
        }

        for (let i = 0; i < order.parts.length; i++) {
          const part = order.parts[i];
          for (let j = 0; j < part.processes.length; j++) {
            const process = part.processes[j];
            if (process.status === 'completed' && process.quantity < process.planned_quantity) {
              order.hasCompletedProcessesWithInsufficientManufacturedQuantity = true;
              return order;
            }
          }
        }
        return order;
      });
    },

    pageType() {
      return this.$route.name === 'orders' ? 'order' : 'offer';
    },
  },

  beforeRouteUpdate(to, from, next) {
    this.orderFilterParams = clone(to.query);
    this.getPaginatedOrders(1, this.orderFilterParams);
    next();
  },

  created() {
    this.getProcessGroups();
    const displayAs = localStorage.getItem('ordersDisplayAs');
    if (displayAs) {
      this.displayAs = displayAs;
    }
    eventBus.$on(UPDATE_INVENTORY_ITEM_QUANTITIES_IN_WAREHOUSE, this.updateInventoryItemQuantities);

    let params = clone(this.$route.query);
    params = parseArrayQueryParam(params, 'status', 'string');
    this.orderFilterParams = params;
    this.getPaginatedOrders(1, this.orderFilterParams);
  },

  beforeDestroy() {
    eventBus.$off(
      UPDATE_INVENTORY_ITEM_QUANTITIES_IN_WAREHOUSE,
      this.updateInventoryItemQuantities,
    );
  },

  methods: {
    async cloneOrder(order) {
      const confirmed = await openConfirmDialog({
        title: this.$t('confirm_order_copy').replace('{0}', order.order_no),
        confirmText: this.$t('yes'),
        cancelText: this.$t('no'),
      });
      if (!confirmed) {
        return;
      }

      eventBus.$emit(OPEN_SNACKBAR, {
        text: this.$t('order_copy_is_being_created'),
        showProgress: true,
      });
      orderService.clone(order).then(res => {
        eventBus.$emit(OPEN_SNACKBAR, this.$t('order_copy_created'));
        const orderIndex = this.orderArray.findIndex(o => o.id === order.id);
        if (orderIndex === -1) {
          return;
        }
        if (this.orderPagination.page > 1) {
          this.getPaginatedOrders(1, this.orderFilterParams);
        } else {
          this.orderArray = [res.data, ...this.orderArray];
          this.orderPagination.total += 1;
        }
      });
    },

    createOrderPart(order) {
      this.newOrderPartTemplate.order_id = order.id;
      this.newOrderPartTemplate.quantity = order.quantity || 1;
      this.newOrderPartTemplate.deadline_at = order.deadline_at;
      this.orderPartOrder = order;
      this.crudMixin_openForm('orderPart', this.newOrderPartTemplate);
    },

    editOrderPart(orderPart, order) {
      this.orderPartOrder = order;
      this.crudMixin_openForm('orderPart', orderPart);
    },

    deleteOrder(order) {
      const successMsg = this.$t(`${this.pageType}_deleted`);
      this.crudMixin_delete(orderService.delete, 'order', order, successMsg);
    },

    documentsUploaded(documents) {
      documents.forEach(d => this.orderDocumentArray.push(d));
    },

    expandOrder(order) {
      if (this.expandedOrder.id === order.id) {
        this.expandedOrder = {};
        this.isOrderDetailsDialogOpen = false;
        return;
      }
      this.expandedOrder = order;
      this.orderPartArray = order.parts;
      this.orderDefectArray = order.defects;
      this.orderDocumentArray = order.documents;
      if (this.displayAs === 'cards') {
        this.isOrderDetailsDialogOpen = true;
      }
    },

    async generateOffer(order) {
      eventBus.$emit(OPEN_SNACKBAR, {
        text: this.$t('offer_is_being_generated'),
        timeout: -1,
        showProgress: true,
      });
      const { data } = await orderService.generateOffer(order);
      eventBus.$emit(OPEN_SNACKBAR, this.$t('offer_generated'));
      this.$router.push({ name: 'offers', query: { search: data.offer_no } });
    },

    viewInventoryItemDetails(inventoryItem) {
      this.inventoryItem = inventoryItem;
      this.isInventoryItemDetailsDialogOpen = true;
    },

    openMaterialDefectsDialog(orderPart) {
      this.selectedOrderPart = orderPart;
      this.isMaterialDefectsDialogOpen = true;
    },

    async reorderOrders(newIndex, oldIndex) {
      const newOrder = this.orderArray[newIndex].order;
      const currentArray = [...this.orderArray];
      const selectedOrder = this.orderArray[oldIndex];
      this.orderArray.splice(oldIndex, 1);
      this.orderArray.splice(newIndex, 0, selectedOrder);
      try {
        await orderService.reorder(selectedOrder, newOrder);
        if (newIndex > oldIndex) {
          for (let i = oldIndex; i < newIndex; i++) {
            this.orderArray[i].order--;
          }
        } else {
          for (let i = oldIndex; i > newIndex; i--) {
            this.orderArray[i].order++;
          }
        }
        selectedOrder.order = newOrder;
      } catch (e) {
        console.error(e);
        this.orderArray = currentArray;
        eventBus.$emit(OPEN_SNACKBAR, this.$t('order_reorder_failed'));
      }
    },

    async getPaginatedOrders(pageNo, params, scrollToTop = true) {
      await this.crudMixin_getPage(
        orderService.getPage,
        orderService.model,
        pageNo,
        {
          ...this.orderFilterParams,
          type: this.pageType,
        },
        false,
        scrollToTop,
      );
      if (this.orderArray.length === 1 && params && this.orderArray[0].order_no === params.search) {
        this.expandOrder(this.orderArray[0]);
        if (this.$route.query.orderPartId) {
          await this.$nextTick();
          scrollToTargetAdjusted(`order-part-${this.$route.query.orderPartId}-row`);
        }
      } else {
        this.expandedOrder = {};
      }
    },

    updateItemsPerPage(itemsPerPage) {
      this.orderFilterParams.page_size = itemsPerPage;
      this.getPaginatedOrders(this.orderPagination.page, this.orderFilterParams, false);
    },

    async getProcessGroups() {
      try {
        const res = await processGroupService.getAll();
        // this.processGroupArray = res.data.map(p => ({ ...p, process: p }));
        this.processGroupArray = res.data;
      } catch (e) {
        this.processGroupArray = [];
      }
    },

    newGroupOrder(group) {
      this.orderFormItem = {
        ...this.newOrderTemplate,
        group,
      };
      this.isOrderFormOpen = true;
    },

    orderCreated(order) {
      order.defects = [];
      if (order.type === this.pageType) {
        this.crudMixin_created('order', order, true);
      }
    },

    orderUpdated(updatedOrder) {
      this.orderPartUpdateCount += 1;
      if (this.orderFormItem.type !== updatedOrder.type) {
        this.orderArray = this.orderArray.filter(o => o.id !== updatedOrder.id);
        this.orderPagination.totalItems--;
      } else {
        for (let i = 0; i < this.orderArray.length; i++) {
          const order = this.orderArray[i];
          if (updatedOrder.group && updatedOrder.group.id === order.group?.id) {
            this.$set(order.group, 'name', updatedOrder.group.name);
          }
        }
        updatedOrder.defects = this.orderFormItem.defects; // does not return from BE on update
        this.crudMixin_updated('order', updatedOrder);
        for (let i = 0; i < this.decoratedOrders.length; i++) {
          if (this.decoratedOrders[i].id === updatedOrder.id) {
            this.orderPartArray = this.decoratedOrders[i].parts;
            this.orderDefectArray = this.decoratedOrders[i].defects;
            this.orderDocumentArray = this.decoratedOrders[i].documents;
          }
        }
      }
    },

    orderPartCreated(orderPart) {
      for (let i = 0; i < this.orderArray.length; i++) {
        if (this.orderArray[i].id === orderPart.order_id) {
          this.orderArray[i].parts.push(orderPart);
        }
      }
      this.orderPartUpdateCount += 1;
    },

    orderPartUpdated(orderPart) {
      this.crudMixin_updated('orderPart', orderPart);
      for (let i = 0; i < orderPart.materials.length; i++) {
        this.updateInventoryItemQuantities(orderPart.materials[i].material);
      }
      this.orderPartUpdateCount += 1;
      this.fetchOrder(orderPart.order_id);
    },

    async fetchOrder(id) {
      const { data } = await orderService.getById(id);
      updateItemById(this.orderArray, data);
      if (this.expandedOrder.id === id) {
        this.expandedOrder = data;
      }
    },

    updateInventoryItemQuantities(inventoryItem) {
      for (let i = 0; i < this.orderArray.length; i++) {
        const order = this.orderArray[i];
        for (let j = 0; j < order.parts.length; j++) {
          const part = order.parts[j];
          for (let k = 0; k < part.materials.length; k++) {
            const material = part.materials[k];
            if (material.material.id === inventoryItem.id) {
              material.material.quantity_in_warehouse = inventoryItem.quantity_in_warehouse;
            }
          }
        }
      }
    },

    updateFilter(newFilters) {
      Object.keys(newFilters).forEach(key => {
        if (!newFilters[key]) {
          delete newFilters[key];
        }
      });
      this.$router.push({ name: this.$route.name, query: newFilters });
    },

    resetFilter() {
      this.$router.push({ name: this.$route.name, query: getDefaultOrderFilterParams() });
    },

    updateOrder(updatedOrder) {
      if (updatedOrder.is_shipped && updatedOrder.is_invoice_issued && !updatedOrder.invoice_no) {
        this.orderFormItem = JSON.parse(JSON.stringify(updatedOrder));
        this.isOrderIssuanceDialogOpen = true;
        return;
      }
      this.crudMixin_update(orderService.update, orderService.model, updatedOrder).then(res => {
        const order = {
          ...updatedOrder,
          ...res.data,
        };
        this.crudMixin_updated(orderService.model, order);
        if (this.expandedOrder.id === order.id) {
          this.expandedOrder.id = null; // to make sure the order doesn't collapse
          this.expandOrder(order);
        }
      });
    },

    updateOrderPart(updatedOrderPart) {
      orderPartService.update(updatedOrderPart).then(res => {
        updateItemById(this.orderPartArray, res.data);
        eventBus.$emit(OPEN_SNACKBAR, this.$t('order_part_updated'));
        this.fetchOrder(updatedOrderPart.order_id);
      });
    },

    updateOrderPartProcess(updatedProcess) {
      orderPartService.updateProcess(updatedProcess).then(res => {
        updateItemById(this.orderPartArray, res.data);
        if (res.data.order) {
          this.crudMixin_updated('order', {
            ...this.expandedOrder,
            ...res.data.order,
          });
        }
        eventBus.$emit(OPEN_SNACKBAR, this.$t('process_updated'));
        this.fetchOrder(this.expandedOrder.id);
      });
    },

    async uploadDocumentFiles(files) {
      const payload = {
        files,
        orderID: this.expandedOrder.id,
      };
      const { data } = await orderDocumentService.upload(payload);
      this.documentsUploaded(data);
    },

    async deleteDocument(document) {
      await orderDocumentService.delete(document);
      this.orderDocumentArray = removeItemById(this.orderDocumentArray, document.id);
    },

    storeDisplayAs(displayAs) {
      localStorage.setItem('ordersDisplayAs', displayAs);
    },
  },
};
</script>
