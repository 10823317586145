<template>
  <BaseAutocompleteDeprecated
    :value="value"
    :search-function="searchOrderParts"
    :error-messages="errorMessages"
    :label="label"
    item-text="order.order_no"
    item-value="id"
    multiple
    chips
    deletable-chips
    use-custom-item-slot
    use-custom-selection-slot
    @input="$emit('input', $event)"
  >
    <template v-slot:item="{ item }">{{ item.order.order_no }}.{{ item.order_part_no }}</template>

    <template v-slot:selection="{ item }">
      <v-chip close @click:close="removeOrderPart(item)">
        {{ item.order.order_no }}.{{ item.order_part_no }}
      </v-chip>
    </template>
  </BaseAutocompleteDeprecated>
</template>

<script>
import orderPartService from '@/api/order-part-service';
import i18n from '@/i18n/i18n-config';
import BaseAutocompleteDeprecated from '@/components/base/BaseAutocompleteDeprecated';

export default {
  name: 'OrderPartMultipleAutocomplete',

  components: { BaseAutocompleteDeprecated },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: () => i18n.t('reserved_for_order_parts'),
    },
  },

  methods: {
    searchOrderParts: orderPartService.search,

    removeOrderPart(orderPart) {
      const newValue = this.value.filter(p => p.id !== orderPart.id);
      this.$emit('input', newValue);
    },
  },
};
</script>
