<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="document.name"
              :error-messages="errors['name']"
              :label="formMixin_getRequiredFieldLabel($t('name'))"
              autofocus
              @blur="formMixin_clearErrors('name')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!document.id" class="subtitle-2 ml-3"> * {{ $t('must_be_filled') }} </span>

        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import documentLibraryService from '@/api/document-library-service';

export default {
  name: 'DocumentLibraryForm',

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    formItem: Object,
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      document: {},
    };
  },

  computed: {
    formTitle() {
      return this.$t(
        this.document.id ? `edit_${this.document.is_folder ? 'folder' : 'document'}` : 'new_folder',
      );
    },
  },

  methods: {
    onDialogOpen() {
      this.document = JSON.parse(JSON.stringify(this.formItem));
      this.document.name = this.document.file_name;
      this.errors = {};
    },

    onSubmit() {
      if (this.document.id) {
        this.crudMixin_update(
          documentLibraryService.rename,
          'document',
          this.document,
          this.$t(`${this.document.is_folder ? 'folder' : 'document'}_updated`),
        );
      } else {
        this.crudMixin_create(
          documentLibraryService.createFolder,
          'document',
          this.document,
          this.$t('folder_created'),
        );
      }
    },
  },
};
</script>
