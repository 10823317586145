<template>
  <v-expansion-panels v-model="expandedPanelIndex" flat hover>
    <v-expansion-panel class="grey lighten-4">
      <v-expansion-panel-header>{{ supplierInquiriesHeader }}</v-expansion-panel-header>

      <v-expansion-panel-content>
        <ContactPersonAutocomplete
          v-model="selectedContactPersonId"
          class="mb-4"
          suppliers-only
          @update:initial-item="addSupplierInquiry"
        />

        <SupplierInquiryTable
          v-if="value.length"
          :rows="value"
          :loading="$store.getters.loading['get:api/supplier-inquiries']"
          :inventory-item-purchase-id="inventoryItemPurchaseId"
          class="mb-4"
          @remove="removeSupplierInquiry"
          @input="$emit('input', $event)"
        />

        <BaseFormErrors v-if="errorMessages.length" :error-messages="errorMessages" class="mb-4" />

        <v-textarea
          v-model="emailBodyTemplate"
          :label="$t('email_body_template')"
          :loading="
            $store.getters.loading[
              `get:api/inventory-items/${this.inventoryItemId}/get-last-purchase-email-body`
            ]
          "
          rows="2"
          auto-grow
          @input="onEmailBodyTemplateInput"
        >
          <template v-slot:append>
            <BaseInfoTooltip :text="$t('supplier_inquiry_email_template_hint')" in-dialog />
          </template>
        </v-textarea>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ContactPersonAutocomplete from '@/components/autocompletes/ContactPersonAutocomplete';
import { openSnackbar } from '@/util/event-bus';
import SupplierInquiryTable from '@/components/tables/SupplierInquiryTable';
import BaseFormErrors from '@/components/base/BaseFormErrors';
import supplierInquiryService from '@/api/supplier-inquiry-service';
import inventoryItemService from '@/api/inventory-item-service';
import clone from 'just-clone';
import BaseInfoTooltip from '@/components/base/BaseInfoTooltip';

export default {
  name: 'SupplierInquiries',

  components: { BaseInfoTooltip, BaseFormErrors, SupplierInquiryTable, ContactPersonAutocomplete },

  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    inventoryItemId: {
      type: Number,
      default: null,
    },
    inventoryItemPurchaseId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      emailBodyTemplate: '',
      selectedContactPersonId: null,
      expandedPanelIndex: null,
    };
  },

  computed: {
    selectedContactPersonIdsSet() {
      return new Set(this.value.map(inquiry => inquiry.contact_person_id));
    },

    answeredInquiriesCount() {
      return this.value.reduce((count, item) => (item.is_answered ? count + 1 : count), 0);
    },

    supplierInquiriesHeader() {
      let header = this.$t('supplier_inquiries');
      const submittedInquiriesCount = this.value.reduce((sum, inq) => (inq.id ? sum + 1 : sum), 0);
      if (submittedInquiriesCount > 0) {
        header += ` (${this.answeredInquiriesCount}/${submittedInquiriesCount} ${this.$t(
          'answered_inquiries',
        ).toLowerCase()})`;
      }
      return header;
    },
  },

  watch: {
    inventoryItemId(newValue) {
      this.fetchLastInventoryItemPurchaseEmailBody(newValue);
    },
  },

  async created() {
    if (this.inventoryItemPurchaseId) {
      // fetch on create when a new purchase is being created
      // otherwise fetch in the watcher when inventory item id changes
      await this.fetchSupplierInquiries();
    }

    if (this.inventoryItemId) {
      this.fetchLastInventoryItemPurchaseEmailBody(this.inventoryItemId);
    }

    if (this.answeredInquiriesCount > 0) {
      this.expandedPanelIndex = 0;
    }
  },

  methods: {
    async fetchSupplierInquiries() {
      const { data } = await supplierInquiryService.getAll({
        inventory_item_purchase_id: this.inventoryItemPurchaseId,
        no_pagination: true,
      });
      this.$emit('input', data.data || data);
    },

    async fetchLastInventoryItemPurchaseEmailBody(inventoryItemId) {
      try {
        if (inventoryItemId) {
          const { data } = await inventoryItemService.getLastPurchaseEmailBody(inventoryItemId);
          this.emailBodyTemplate = data.template || '';
        }
      } catch (e) {
        console.warn(e);
      }
    },

    async addSupplierInquiry(contactPerson) {
      this.resetAutocompleteQuery();
      if (!contactPerson) {
        return;
      }
      if (this.selectedContactPersonIdsSet.has(contactPerson.id)) {
        openSnackbar(`${this.$t('this_contact_person_is_already_added')}.`);
        return;
      }

      const newSupplierInquiry = {
        contact_person: contactPerson,
        contact_person_id: contactPerson.id,
        email_body_template: this.emailBodyTemplate,
        inventory_item_purchase_id: this.inventoryItemPurchaseId,
        messages: [],
      };
      const newValue = [...this.value, newSupplierInquiry];
      this.$emit('input', newValue);
    },

    removeSupplierInquiry(inquiry) {
      const newValue = this.value.filter(i => i.email !== inquiry.email);
      this.$emit('input', newValue);
    },

    async resetAutocompleteQuery() {
      await this.$nextTick();
      this.selectedContactPersonId = null;
    },

    onEmailBodyTemplateInput(emailBodyTemplate) {
      const newValue = clone(this.value);
      for (let i = 0; i < newValue.length; i++) {
        if (!newValue[i].id) {
          newValue[i].email_body_template = emailBodyTemplate;
        }
      }
      this.$emit('input', newValue);
    },
  },
};
</script>

<style scoped></style>
