<template>
  <v-dialog
    :value="value"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :max-width="maxWidth"
    :scrollable="scrollable"
    :persistent="persistent"
    :content-class="contentClass"
    transition="slide-y-reverse-transition"
    @input="onDialogInput"
  >
    <slot v-if="isContentRendered"></slot>
  </v-dialog>
</template>

<script>
// Wrapper for v-dialog.
// It is used when the component setup logic is dependent on it's lifecycle hooks (e.g. created())
// putting a component inside AppDialog instead of v-dialog will ensure that the component is
// recreated every time the dialog is opened, while retaining the functionality of v-dialog.
// If more v-dialog props are needed, you can check them here:
// https://vuetifyjs.com/en/components/dialogs
export default {
  name: 'BaseDialog',

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    maxWidth: {
      type: String,
      default: '800px',
    },

    persistent: {
      type: Boolean,
      default: false,
    },

    scrollable: {
      type: Boolean,
      default: false,
    },

    contentClass: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      fullscreenBreakpoint: '',
      isContentRendered: false,
    };
  },

  created() {
    this.isContentRendered = this.value;
  },

  watch: {
    value(newValue) {
      if (newValue) {
        this.isContentRendered = true;
      } else {
        // timeout here ensures that that component is removed from DOM
        // once dialog closing animation is finished
        setTimeout(() => {
          this.isContentRendered = false;
        }, 150);
      }
    },
  },

  methods: {
    onDialogInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>
