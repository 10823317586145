<template>
  <v-menu v-model="isOpen" :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ on: menu }">
      <v-tooltip :disabled="!tooltip" :content-class="tooltipClasses" max-width="30em" bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-chip
            v-on="{ ...menu, ...tooltip }"
            :color="color"
            :class="chipClasses"
            :outlined="!value"
            :text-color="textColor"
            :close="clearable && !!value"
            small
            @click:close="$emit('input', '')"
            @click.stop
          >
            {{ chipText }}
          </v-chip>
        </template>
        <div>
          {{ tooltip }}
        </div>
      </v-tooltip>
    </template>
    <v-date-picker
      :value="value"
      :locale="locale"
      first-day-of-week="1"
      no-title
      show-week
      scrollable
      @input="onInput"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'BaseChipDatepicker',

  props: {
    value: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    chipClasses: {
      type: String,
      default: 'my-1',
    },
    tooltipClasses: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    chipText() {
      let text = '';
      if (this.label) {
        text += `${this.label} `;
      }
      text += this.value || this.noDataText || this.$t('unspecified').toLowerCase();
      return text;
    },

    locale() {
      return this.$store.state.settings.locale;
    },
  },

  methods: {
    onInput(value) {
      this.$emit('input', value);
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>
.chip-icon {
  margin-right: 6px;
}
</style>
