<template>
  <div>
    <v-menu
      v-model="isMenuOpen"
      :close-on-content-click="false"
      :close-on-click="
        !isInventoryItemGroupFormOpen && !isInventoryItemFormOpen && !crudMixin_isDeleteDialogOpen
      "
      :nudge-bottom="nudgeBottom"
      :nudge-left="nudgeLeft"
      :nudge-top="nudgeTop"
      :nudge-right="nudgeRight"
      :left="left"
      :top="top"
      :min-width="$vuetify.breakpoint.smAndUp ? 600 : 300"
      offset-y
    >
      <template v-slot:activator="{ on }">
        <slot name="activator" :on="on">
          <v-btn v-on="on" color="primary">
            <v-icon left>mdi-plus</v-icon>
            {{ $t('add_materials') }}
          </v-btn>
        </slot>
      </template>
      <v-card>
        <v-card-title>
          <v-btn
            v-if="inventoryItemGroupBreadcrumbs.length && globalSearchQuery.length < 3"
            class="mr-2"
            icon
            @click="viewParent"
          >
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
          <h2 class="title">
            {{ title }}
          </h2>
          <v-tooltip transition="none" max-width="30em" bottom>
            <template #activator="{ on }">
              <v-icon v-on="on" color="info" class="ml-3">mdi-information</v-icon>
            </template>

            <div>
              <div class="mb-3">
                {{ $t('inventory_item_add_hint') }}
              </div>
              <div>{{ $t('inventory_item_group_open_hint') }}</div>
            </div>
          </v-tooltip>
          <v-spacer />
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="primary" text>
                <v-icon left>mdi-plus</v-icon>
                {{ $t('create') }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="crudMixin_openForm('inventoryItemGroup', newInventoryItemGroupTemplate)"
              >
                <v-list-item-title>{{ $t('inventory_item_group_accusative') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="createInventoryItem">
                <v-list-item-title>{{ $t('material_accusative') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>

        <v-card-text class="px-0">
          <div class="px-3 pb-1">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="localSearchQuery"
                  :label="$t('search_this_group')"
                  :disabled="!!globalSearchQuery"
                  class="mt-0"
                  prepend-inner-icon="mdi-magnify"
                  clearable
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  :value="globalSearchQuery"
                  :label="$t('search_all_materials')"
                  :hint="
                    !globalSearchQuery || globalSearchQuery.length < 3
                      ? $t('enter_three_or_more_symbols')
                      : ''
                  "
                  class="mt-0"
                  prepend-inner-icon="mdi-magnify"
                  clearable
                  @input="onGlobalSearchInput"
                />
              </v-col>
            </v-row>
          </div>

          <v-progress-circular
            v-if="loadingGlobalSearchResults"
            color="primary"
            class="mt-6 mx-3"
            indeterminate
          />
          <InventoryItemGroupTable
            v-else
            :rows="filteredItems"
            :pagination="pagination"
            :loading="$store.getters.loading['get:api/inventory-item-groups']"
            :height="300"
            @dblclick:row="viewChildren"
            @row-click="onRowClick"
            @change-page="getInventoryItemGroups"
            @edit="onEditClick"
            @delete="deleteGroup"
          />
        </v-card-text>
      </v-card>
    </v-menu>

    <v-dialog
      v-model="isInventoryItemGroupFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <InventoryItemGroupForm
        :dialog="isInventoryItemGroupFormOpen"
        :form-item="inventoryItemGroupFormItem"
        :parent-group-id="selectedGroup.id"
        @cancel="isInventoryItemGroupFormOpen = false"
        @create="crudMixin_created('inventoryItemGroup', $event)"
        @update="crudMixin_updated('inventoryItemGroup', $event)"
      />
    </v-dialog>

    <v-dialog
      v-model="isInventoryItemFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <InventoryItemForm
        :dialog="isInventoryItemFormOpen"
        :form-item="inventoryItemFormItem"
        @cancel="isInventoryItemFormOpen = false"
        @create="inventoryItemCreated"
        @update="inventoryItemUpdated"
      />
    </v-dialog>
  </div>
</template>

<script>
import crudMixin from '@/mixins/crud-mixin';
import inventoryItemGroupService from '@/api/inventory-item-group-service';
import InventoryItemGroupTable from '@/components/tables/InventoryItemGroupTable';
import InventoryItemGroupForm from '@/components/forms/InventoryItemGroupForm';
import inventoryItemService from '@/api/inventory-item-service';
import InventoryItemForm from '@/components/forms/InventoryItemForm';
import { updateItemById } from '@/util/array';

export default {
  name: 'InventoryItemPicker',

  components: { InventoryItemForm, InventoryItemGroupForm, InventoryItemGroupTable },

  mixins: [crudMixin],

  props: {
    nudgeTop: {
      type: String,
      default: '0',
    },
    nudgeBottom: {
      type: String,
      default: '8',
    },
    nudgeLeft: {
      type: String,
      default: '0',
    },
    nudgeRight: {
      type: String,
      default: '0',
    },
    left: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isMenuOpen: false,
      inventoryItemGroupArray: [],
      inventoryItemGroupPagination: {
        page: 1,
        rowsPerPage: 50,
      },
      inventoryItemGroupFilterParams: {
        parent_group_id: null,
      },

      inventoryItemGroupFormItem: {},
      newInventoryItemGroupTemplate: {},
      isInventoryItemGroupFormOpen: false,
      loadingInventoryItemGroups: true,
      inventoryItemGroupBreadcrumbs: [],

      isInventoryItemFormOpen: false,
      inventoryItemFormItem: {},

      localSearchQuery: '',
      globalSearchQuery: '',
      globalSearchTimerId: null,
      loadingGlobalSearchResults: false,
      inventoryItemArray: [],
    };
  },

  computed: {
    selectedGroup() {
      return this.inventoryItemGroupBreadcrumbs.length
        ? this.inventoryItemGroupBreadcrumbs[this.inventoryItemGroupBreadcrumbs.length - 1]
        : { id: null };
    },

    title() {
      if (this.globalSearchQuery?.length > 2) {
        return this.$t('search_results').replace('{0}', this.globalSearchQuery);
      }
      return this.selectedGroup.title || this.$t('materials');
    },

    filteredInventoryItemGroups() {
      const q = this.localSearchQuery?.trim().toLowerCase() || '';
      return this.inventoryItemGroupArray.filter(g => g.title.toLowerCase().includes(q));
    },

    filteredInventoryItems() {
      const q = this.localSearchQuery?.trim().toLowerCase() || '';
      return (
        this.selectedGroup?.inventory_items
          ?.filter(i => i.title.toLowerCase().includes(q))
          .map(i => ({
            ...i,
            isInventoryItem: true,
            inventory_item_group: this.selectedGroup,
            inventory_item_group_id: this.selectedGroup?.id,
          })) || []
      );
    },

    filteredItems() {
      if (this.globalSearchQuery?.length > 2) {
        return this.inventoryItemArray.map(i => ({ ...i, isInventoryItem: true }));
      }
      return [...this.filteredInventoryItemGroups, ...this.filteredInventoryItems];
    },

    pagination() {
      const pagination = { ...this.inventoryItemGroupPagination };
      pagination.totalItems += this.selectedGroup?.inventory_items?.length || 0;
      return pagination;
    },
  },

  created() {
    this.getInventoryItemGroups();
  },

  methods: {
    getInventoryItemGroups(page = 1) {
      return this.crudMixin_getPage(
        inventoryItemGroupService.getPage,
        inventoryItemGroupService.model,
        page,
        this.inventoryItemGroupFilterParams,
      );
    },

    deleteGroup(group) {
      this.crudMixin_delete(inventoryItemGroupService.delete, 'inventoryItemGroup', group);
    },

    viewChildren(group) {
      this.inventoryItemGroupFilterParams.parent_group_id = group.id;
      this.getInventoryItemGroups().then(() => {
        this.inventoryItemGroupBreadcrumbs.push(group);
      });
    },

    viewParent() {
      const group = this.inventoryItemGroupBreadcrumbs[
        this.inventoryItemGroupBreadcrumbs.length - 1
      ];
      this.inventoryItemGroupFilterParams.parent_group_id = group.parent_group_id;
      this.getInventoryItemGroups().then(() => {
        this.inventoryItemGroupBreadcrumbs.pop();
      });
    },

    onRowClick(item) {
      if (item.isInventoryItem) {
        this.$emit('click:item', item);
      } else if (!item.isInventoryItem) {
        this.$emit('click:group', item);
      }
    },

    onGlobalSearchInput(query) {
      if (!query || query.length < 3) {
        this.globalSearchQuery = query || '';
        return;
      }
      this.localSearchQuery = '';
      clearTimeout(this.globalSearchTimerId);
      this.globalSearchTimerId = setTimeout(async () => {
        this.globalSearchQuery = query;
        this.loadingGlobalSearchResults = true;
        const { data } = await inventoryItemService.search(query);
        this.loadingGlobalSearchResults = false;
        this.inventoryItemArray = data.data || [];
        this.timeoutId = null;
      }, 500);
    },

    createInventoryItem() {
      const template = {
        type: 'manufactured',
      };
      if (this.selectedGroup?.id) {
        template.inventory_item_group_id = this.selectedGroup.id;
        template.inventory_item_group = this.selectedGroup;
      }
      this.crudMixin_openForm('inventoryItem', template);
    },

    inventoryItemCreated(item) {
      this.crudMixin_created('inventoryItem', item);
      for (let i = 0; i < this.inventoryItemGroupBreadcrumbs.length; i++) {
        const group = this.inventoryItemGroupBreadcrumbs[i];
        if (+group.id === +item.inventory_item_group_id && group.inventory_items) {
          group.inventory_items.push(item);
          break;
        }
      }
    },

    inventoryItemUpdated(item) {
      this.crudMixin_updated('inventoryItem', item);
      for (let i = 0; i < this.inventoryItemGroupBreadcrumbs.length; i++) {
        const group = this.inventoryItemGroupBreadcrumbs[i];
        if (+group.id === +item.inventory_item_group_id && group.inventory_items) {
          updateItemById(group.inventory_items, item);
          break;
        }
      }
    },

    onEditClick(item) {
      if (item.isInventoryItem) {
        this.crudMixin_openForm('inventoryItem', item);
      } else {
        this.crudMixin_openForm('inventoryItemGroup', item);
      }
    },
  },
};
</script>
