<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title class="d-flex align-center" style="min-height: 62px">
        <v-btn v-if="breadcrumbs.length" class="mr-2" icon @click="goUp">
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <DocumentLibraryTable
              :selected-items="selectedFolders"
              :rows="documentArray"
              :loading="isDataLoading"
              hide-actions
              @dblclick:row="onItemDoubleClick"
              @row-click="selectFolder"
              @click:row="selectFolder"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!document.id" class="subtitle-2 ml-3"> * {{ $t('must_be_filled') }} </span>

        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import documentLibraryService from '@/api/document-library-service';
import DocumentLibraryTable from '@/components/tables/DocumentLibraryTable';

export default {
  name: 'DocumentLibraryMoveForm',

  components: { DocumentLibraryTable },

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    formItem: Object,
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      document: {},
      selectedFolders: [],

      documentFilterParams: {},
      pageTitle: '',
      isDataLoading: false,
      documentArray: [], // folders only
      breadcrumbs: [],
    };
  },

  computed: {
    formTitle() {
      let title = `${this.$t('move_to')}: `;
      if (this.selectedFolders.length) {
        title += this.selectedFolders[0].file_name;
      } else {
        title += this.$t('to_root_folder').toLowerCase();
      }
      return title;
    },
  },

  methods: {
    onDialogOpen() {
      this.document = JSON.parse(JSON.stringify(this.formItem));
      this.document.name = this.document.file_name;
      this.errors = {};
      this.pageTitle = this.$t('documents');
      this.documentFilterParams = {};
      this.documentArray = [];
      this.selectedFolders = [];
      this.getFolder();
    },

    async getFolder() {
      this.isDataLoading = true;
      await this.crudMixin_getAll(
        documentLibraryService.getFolder,
        'document',
        this.documentFilterParams,
      );
      this.documentArray = this.documentArray.filter(doc => doc.is_folder);
    },

    selectFolder(folder) {
      this.selectedFolders = [folder];
    },

    onItemDoubleClick(item) {
      if (!item.is_folder) {
        return;
      }
      this.breadcrumbs.push({
        id: this.documentFilterParams.parent_folder_id,
        file_name: this.pageTitle,
      });
      this.pageTitle = item.file_name;
      this.documentFilterParams.parent_folder_id = item.id;
      this.getFolder();
    },

    goUp() {
      const location = this.breadcrumbs.pop();
      this.documentFilterParams.parent_folder_id = location.id;
      this.pageTitle = location.file_name;

      if (location.id) {
        this.selectFolder(location);
      } else {
        this.selectedFolders = [];
      }
      this.getFolder();
    },

    onSubmit() {
      this.document.destination_folder_id = this.selectedFolders[0]
        ? this.selectedFolders[0].id
        : null;
      this.crudMixin_update(
        documentLibraryService.move,
        'document',
        this.document,
        this.$t(`${this.document.is_folder ? 'folder' : 'document'}_moved`),
      );
    },
  },
};
</script>
