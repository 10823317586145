<template>
  <div>
    <v-badge
      :value="selectedFilterCount > 0"
      :content="selectedFilterCount"
      color="error"
      overlap
      bordered
    >
      <v-btn class="ml-3" color="primary" outlined @click="toggleDrawer(!isDrawerOpen)">
        <v-icon left>mdi-filter-variant</v-icon>
        {{ $t(isDrawerOpen ? 'hide_filters' : 'show_filters') }}
      </v-btn>
    </v-badge>
    <v-navigation-drawer
      :value="isDrawerOpen"
      height="100%"
      disable-route-watcher
      app
      clipped
      right
      @input="toggleDrawer"
    >
      <div class="px-3">
        <slot></slot>

        <v-btn color="primary" class="mt-8" block text @click="$emit('reset')">
          <v-icon left>mdi-filter-variant-remove</v-icon>
          {{ $t('reset_filters') }}
        </v-btn>

        <v-btn color="primary" class="mt-4" block text @click="toggleDrawer(false)">
          {{ $t('hide_filters') }}
        </v-btn>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: 'BaseFilterDrawer',

  props: {
    isOpen: {
      type: [Boolean, null],
      default: null,
    },
    selectedFilterCount: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      isOpenInternal: false,
    };
  },

  computed: {
    isDrawerOpen() {
      // can be controlled externally but also internally
      return this.isOpen === null ? this.isOpenInternal : this.isOpen;
    },
  },

  methods: {
    toggleDrawer(value) {
      if (value === this.isDrawerOpen) {
        return;
      }
      if (this.isOpen === null) {
        this.isOpenInternal = value;
      } else {
        this.$emit('toggle:drawer', value);
      }
    },
  },
};
</script>

<style scoped></style>
