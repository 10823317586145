import documentLibraryService from '@/api/document-library-service';
import { downloadFile } from '@/util/files';

// handles opening of files in a file viewer
const documentLibraryMixin = {
  data() {
    return {
      selectedFileUrl: '',
      selectedFileTitle: '',
      loadingFileIdMap: {},
      isFileViewerDialogOpen: false,
    };
  },

  methods: {
    openFile(file) {
      if (this.loadingFileIdMap[file.id]) {
        return;
      }
      this.$set(this.loadingFileIdMap, file.id, true);
      documentLibraryService
        .downloadFile(file)
        .then(res => {
          let fileType = '';
          const parts = file.file_name.split('.');
          if (parts.length > 1) {
            fileType = parts[parts.length - 1];
          }

          let blob = '';

          if (fileType.toLowerCase() === 'pdf') {
            blob = new Blob([res.data], { type: 'application/pdf' });
          } else if (['jpg', 'jpeg', 'png', 'svg', 'gif', 'bmp'].includes(fileType.toLowerCase())) {
            blob = new Blob([res.data], { type: `image/${fileType}` });
          }

          if (blob) {
            this.selectedFileUrl = window.URL.createObjectURL(blob);
            this.selectedFileTitle = file.file_name;
            this.isFileViewerDialogOpen = true;
          } else {
            downloadFile(res.data, file.file_name);
          }
        })
        .finally(() => {
          this.$delete(this.loadingFileIdMap, file.id);
        });
    },
  },
};

export default documentLibraryMixin;
