<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title> {{ order.order_no }} {{ $t('estimation').toLowerCase() }} </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6">
            <BaseAutocomplete
              v-model="estimation.client_contact_id"
              :initial-item="estimation.client"
              :search-function="clientSearchFunction"
              :error-messages="errors.client_contact_id"
              :label="$t('client')"
              :key="estimation.id"
              @input="formMixin_clearErrors('client_contact_id')"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="estimation.name"
              :error-messages="errors['name']"
              :label="$t('name')"
              @blur="formMixin_clearErrors('name')"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="estimation.date"
              :error-messages="errors['date']"
              :label="$t('date')"
              @input="formMixin_clearErrors('date')"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="estimation.sum"
              :error-messages="errors['sum']"
              :label="$t('sum_in_euros')"
              type="number"
              @blur="formMixin_clearErrors('sum')"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="estimation.comment"
              :error-messages="errors['comment']"
              :label="$t('comment')"
              rows="1"
              auto-grow
              @blur="formMixin_clearErrors('comment')"
            />
          </v-col>
          <v-col cols="12">
            <v-row v-for="(l, index) in estimation.lines" :key="index" dense>
              <v-col cols="12" md="4" class="d-flex align-center">
                {{ $t('offer_part') }} {{ $t('number_short') }} {{ index + 1 }}
                <v-btn class="ml-3" icon @click="estimation.lines.splice(index, 1)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="l.title"
                  :error-messages="errors[`lines.${index}.title`]"
                  :label="formMixin_getRequiredFieldLabel($t('title'))"
                  @blur="formMixin_clearErrors(`lines.${index}.title`)"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="l.price"
                  :label="formMixin_getRequiredFieldLabel($t('price_in_euros'))"
                  :error-messages="errors[`lines.${index}.price`]"
                  type="number"
                  @blur="formMixin_clearErrors(`lines.${index}.price`)"
                  @input="setSum"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn color="primary" text @click="estimation.lines.push({})">
              <v-icon left>mdi-plus</v-icon>
              {{ $t('add_part') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!estimation.id" class="subtitle-2 ml-3"> * {{ $t('must_be_filled') }} </span>

        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('send') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { format } from 'date-fns';
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import estimationService from '../../api/estimation-service';
import contactService from '../../api/contact-service';
import BaseAutocomplete from '../base/BaseAutocomplete';
import BaseDatepickerInput from '../base/BaseDatepickerInput';

export default {
  name: 'EstimationForm',

  components: { BaseDatepickerInput, BaseAutocomplete },

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    order: Object,
  },

  data() {
    return {
      clientSearchFunction: contactService.searchClients,
      errors: {},
      isRequestPending: false,
      estimation: {
        lines: [],
      },
      timerID: null,
    };
  },

  methods: {
    onDialogOpen() {
      const estimation = {
        client: this.order.client,
        client_contact_id: this.order.client_contact_id,
        name: this.order.group?.name,
        date: format(new Date(), 'yyyy-MM-dd'),
        comment: this.order.comments,
        lines: this.order.parts.map(p => ({
          title: p.material,
          price: p.material_sell_price,
        })),
      };
      const lines = [];
      let price = 0;
      for (let i = 0; i < this.order.parts.length; i++) {
        const part = this.order.parts[i];
        price += +part.material_sell_price;
        lines.push({
          id: part.id,
          title: part.material_type,
          price: +part.material_sell_price,
        });
      }
      estimation.sum = price;
      estimation.lines = lines;
      this.estimation = estimation;
      this.errors = {};
    },

    setSum() {
      clearTimeout(this.timerID);
      this.timerID = setTimeout(() => {
        const totalSum = this.estimation.lines.reduce((sum, l) => sum + +l.price, 0);
        this.$set(this.estimation, 'sum', totalSum);
      }, 500);
    },

    onSubmit() {
      this.crudMixin_create(
        estimationService.send,
        estimationService.model,
        this.estimation,
        this.$t('estimation_sent'),
      );
    },
  },
};
</script>
