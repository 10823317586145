<template>
  <v-container fluid class="grey lighten-4 body-2">
    <v-row dense>
      <v-col v-if="loading && !orders.length" cols="12" class="text-center">
        {{ $t('loading') }}...
      </v-col>
      <v-col v-else-if="!orders.length" class="text-center">
        {{ $t('no_data') }}
      </v-col>
      <v-col v-for="item in orders" :key="item.id" cols="12" sm="6" md="4" lg="3">
        <v-sheet
          class="elevation-1 pt-1 pb-2 px-2 fill-height d-flex flex-column justify-space-between"
        >
          <div class="d-flex align-center justify-space-between flex-wrap">
            <h3 class="subtitle-1">
              <span class="link" @click="$emit('click:order-no', item)">{{ item.order_no }}</span>
            </h3>
            <v-tooltip
              v-if="item.workDaysTillDeadline >= 0 && item.workDaysTillDeadline <= 14"
              transition="none"
              bottom
            >
              <template #activator="{ on }">
                <v-icon
                  v-on="on"
                  :color="item.workDaysTillDeadline <= 7 ? 'error' : 'yellow darken-2'"
                  class="ml-1"
                  small
                  >mdi-clock</v-icon
                >
              </template>
              <div v-if="item.workDaysTillDeadline > 0">
                {{ item.workDaysTillDeadline }} {{ $t('work_days_till_deadline') }}
              </div>
              <div v-else>{{ $t('is_overdue') }}!</div>
            </v-tooltip>
            <v-spacer />
            <BaseChipSelect
              :value="item.status"
              :items="orderStatuses"
              :tooltip="item.status === 'canceled' ? item.cancellation_comment : ''"
              small
              left
              @change="$emit('change:status', item, $event)"
            />
            <BaseActionMenu
              :item="item"
              :actions="getItemActions(item)"
              :max-buttons="1"
              small-buttons
              simple
              left
            />
          </div>

          <div class="d-flex flex-column py-1">
            <div v-if="item.group && item.group.name">
              {{ $t('order_group') }}: {{ item.group.name }}
            </div>
            <div v-if="item.client">
              {{ $t('client_contact_id') }}:
              <v-menu :close-on-content-click="false" offset-y nudge-bottom="8">
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="link">
                    {{ item.client.name }}
                  </span>
                </template>
                <ContactCard :contact-id="item.client.id" />
              </v-menu>
            </div>
            <div v-if="item.client_order_no">
              {{ $t('client_order_no') }}: {{ item.client_order_no }}
            </div>
            <div v-if="item.responsible_user && item.responsible_user.person">
              {{ $t('responsible_user') }}: {{ item.responsible_user.person.full_name }}
            </div>
            <div v-if="item.deadline_at" class="d-flex align-center">
              {{ $t('deadline_at') }}: {{ item.deadline_at }}
            </div>
          </div>

          <div class="d-flex flex-wrap">
            <div class="half-width">
              <template v-if="orderProcesses[item.id].length">
                <div>
                  {{ $t('processes') }}
                </div>
                <div class="d-flex flex-wrap" style="max-width: 128px">
                  <span
                    v-for="p in orderProcesses[item.id]"
                    :key="p.id"
                    class="bubble"
                    :style="`background-color: ${orderPartProcessStatuses[p.status].hexColor}`"
                  />
                </div>
              </template>
            </div>
            <div class="half-width">
              <template v-if="orderMaterials[item.id].length">
                <div class="text-right">
                  {{ $t('materials') }}
                </div>
                <MaterialBubbles :materials="orderMaterials[item.id]" class="justify-end ml-auto" />
              </template>
            </div>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContactCard from '@/components/ContactCard';
import { getOrderPartProcessStatusMap } from '@/api/order-part-service';
import { INVENTORY_ITEM_PURCHASE_STATUSES } from '@/api/inventory-item-purchase-service';
import { ORDER_STATUSES } from '@/api/order-service';
import BaseChipSelect from '@/components/base/BaseChipSelect';
import BaseActionMenu from '@/components/base/BaseActionMenu';
import MaterialBubbles from '@/components/MaterialBubbles';

export default {
  name: 'OrderCardsGrid',

  components: { MaterialBubbles, BaseActionMenu, BaseChipSelect, ContactCard },

  props: {
    orders: {
      type: Array,
      required: true,
    },
    orderProcesses: {
      type: Object,
      default: () => ({}),
    },
    orderMaterials: {
      type: Object,
      default: () => ({}),
    },
    getItemActions: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    orderPartProcessStatuses() {
      return getOrderPartProcessStatusMap();
    },

    orderPartMaterialColors() {
      const colorMap = {};
      for (let i = 0; i < INVENTORY_ITEM_PURCHASE_STATUSES.length; i++) {
        colorMap[INVENTORY_ITEM_PURCHASE_STATUSES[i].value] =
          INVENTORY_ITEM_PURCHASE_STATUSES[i].hexColor;
      }
      return colorMap;
    },

    orderStatuses() {
      return ORDER_STATUSES;
    },
  },
};
</script>

<style scoped></style>
