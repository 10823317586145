import http from './http';

const endpoint = 'api/supplier-inquiries';

const supplierInquiryService = {
  model: 'supplierInquiry',

  getAll: params => http.get(endpoint, { params }),

  getById: supplierInquiry => http.get(`${endpoint}/${supplierInquiry.id}`),

  create: supplierInquiry => http.post(`${endpoint}`, supplierInquiry),

  update: supplierInquiry => http.put(`${endpoint}/${supplierInquiry.id}`, supplierInquiry),

  changeStatus: supplierInquiry => http.patch(`${endpoint}/${supplierInquiry.id}`, supplierInquiry),

  delete: supplierInquiry => http.delete(`${endpoint}/${supplierInquiry.id}`),
};

export default supplierInquiryService;
