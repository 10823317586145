<template>
  <v-menu v-model="isOpen" :disabled="disabled" bottom offset-y :close-on-content-click="false">
    <template #activator="{ on }">
      <div v-on="on" :class="`justify-${justify} align-${align}`" class="clickable d-flex">
        <slot>
          {{ value }}
        </slot>
        <v-icon v-if="!disabled" :class="{ 'error--text': hasError }" class="ml-1" small
          >mdi-pencil</v-icon
        >
      </div>
    </template>
    <v-form @submit.prevent="onOk">
      <v-card>
        <v-card-text class="pb-0">
          <slot name="inputs">
            <slot name="prepend"></slot>
            <v-text-field
              v-model="inputValue"
              :type="type"
              :placeholder="placeholder"
              :label="label"
              class="mt-0 pt-0"
              autofocus
              hide-details
              @input="$emit('input', $event)"
            />
          </slot>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer />
          <v-btn color="primary" type="submit" text>
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-menu>
</template>

<script>
export default {
  name: 'BaseEditableField',

  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    justify: {
      type: String,
      default: 'start',
    },
    type: {
      type: String,
      default: 'text',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: 'center',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    closeCounter: {
      // when this goes up the menu will close
      // to control it's open/close state externally
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      inputValue: '',
      isOpen: false,
    };
  },

  watch: {
    isOpen(newValue) {
      if (newValue) {
        this.inputValue = this.value;
        this.$emit('open');
      } else {
        this.$emit('close');
      }
    },

    closeCounter() {
      this.isOpen = false;
    },
  },

  methods: {
    onOk() {
      this.isOpen = false;
      this.$emit('submit', this.inputValue);
    },
  },
};
</script>
