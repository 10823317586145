<template>
  <BaseTableLoader :loading="loading">
    <v-data-table
      :expanded="tableMixin_expandedRows"
      :headers="tableMixin_displayedHeaders"
      :items="decoratedRows"
      :mobile-breakpoint="0"
      :class="[{ 'clickable-rows': !canEdit }, { 'not-editable-table': canEdit }]"
      :item-key="itemKey"
      ref="processTable"
      dense
      disable-sort
      disable-pagination
      hide-default-footer
      @click:row="onRowClick"
    >
      <template v-slot:item.handle>
        <v-icon v-if="canEdit" class="draggable">
          mdi-drag
        </v-icon>
      </template>

      <template v-slot:item.process.title="{ item, index }">
        <v-menu :close-on-content-click="false" offset-y nudge-bottom="8">
          <template v-slot:activator="{ on }">
            <div v-on="on" class="link">
              {{ item.process.title }}
            </div>
          </template>
          <ProcessCard :process-id="item.process_id" :order-part-process-id="item.id" />
        </v-menu>
        <div v-if="order && orderPart" class="grey--text">
          {{ order.order_no }}.{{ orderPart.order_part_no }}.{{ index + 1 }}
        </div>
      </template>

      <template v-slot:item.quantity="{ item }">
        <BaseEditableField
          v-model.number="item.quantity"
          :disabled="!canEdit"
          @input="$emit('change', decoratedRows)"
        >
          <template v-slot:inputs>
            <div class="d-flex">
              <v-text-field
                v-model.number="item.quantity"
                :label="$t('quantity_produced_in_units')"
                class="mt-0"
                autofocus
                hide-details
                @input="$emit('change', decoratedRows)"
              />
              <div class="px-3 pt-5">/</div>
              <v-text-field
                v-model.number="item.planned_quantity"
                :label="$t('needed_quantity_in_units')"
                class="mt-0"
                hide-details
                @input="$emit('change', decoratedRows)"
              />
            </div>
          </template>
          <span
            :class="{
              'error--text font-weight-medium': item.isManufacturedQuantityInsufficient,
            }"
            >{{ item.quantity }}/{{ item.planned_quantity }}</span
          >
          <v-tooltip v-if="item.isManufacturedQuantityInsufficient" transition="none" bottom>
            <template #activator="{ on }">
              <v-icon v-on="on" class="ml-1" color="error" small>mdi-alert-circle</v-icon>
            </template>
            <div>
              {{ $t('process_finished_with_insufficient_manufactured_quantity') }}
            </div>
          </v-tooltip>
        </BaseEditableField>
      </template>

      <template v-slot:item.comment="{ item }">
        <BaseEditableField
          v-model="item.comment"
          :disabled="!canEdit"
          @input="$emit('change', decoratedRows)"
        >
          <v-tooltip v-if="item.comment" max-width="35em" bottom>
            <template #activator="{ on }">
              <v-icon v-on="on" class="default-cursor" small @click.stop>
                mdi-message-reply-text
              </v-icon>
            </template>
            <div class="text-preserve-whitespace">{{ item.comment }}</div>
          </v-tooltip>
        </BaseEditableField>
      </template>

      <template v-if="canEdit" v-slot:item.estimated_start_at="{ item }">
        <BaseDateTimePicker
          v-model="item.estimated_start_at"
          @input="$emit('change', decoratedRows)"
        >
          {{ item.estimated_start_at }} <v-icon small>mdi-pencil</v-icon>
        </BaseDateTimePicker>
      </template>

      <template v-slot:item.estimated_duration_in_minutes="{ item }">
        <BaseEditableField
          v-model.number="item.estimated_duration_in_minutes"
          :disabled="!canEdit"
          :placeholder="indicateRequiredFields ? $t('required_field') : ''"
          @input="$emit('change', decoratedRows)"
        />
        <div v-if="item.estimatedTotalDuration">
          {{ $t('total') }}: {{ item.estimatedTotalDuration }}
        </div>
      </template>

      <template v-slot:item.tariff="{ item }">
        <BaseEditableField :disabled="!canEdit">
          {{ item.employee_salary_amount_per_unit }}
          <span v-if="item.employee_salary_amount_per_unit"
            >€/{{
              $t(
                item.employee_salary_calculation_param === 'minutes'
                  ? 'minute_short'
                  : 'unit_short',
              )
            }}</span
          >

          <template #inputs>
            <v-radio-group
              v-model="item.employee_salary_calculation_param"
              class="mt-0"
              @change="$emit('change', decoratedRows)"
            >
              <v-radio
                v-for="option in calculationParamOptions"
                :key="option.value"
                :label="option.text"
                :value="option.value"
              />
            </v-radio-group>

            <v-text-field
              v-model.number="item.employee_salary_amount_per_unit"
              :placeholder="
                `€/${$t(
                  item.employee_salary_calculation_param === 'minutes'
                    ? 'minute_short'
                    : 'unit_short',
                )}`
              "
              type="number"
              single-line
              autofocus
              @input="$emit('change', decoratedRows)"
            />
          </template>
        </BaseEditableField>
      </template>

      <template v-slot:item.status="{ item }">
        <OrderPartProcessStatusChip
          :process="item"
          can-edit
          @change="updateProcessStatus(item, $event)"
        />
      </template>

      <template v-slot:item.actions="{ item }">
        <BaseActionMenu :actions="actions" :item="item" />
      </template>

      <template v-slot:expanded-item="{ item }">
        <BaseExpandedTableRow
          :colspan="tableMixin_displayedHeaders.length"
          :headers="tableMixin_hiddenHeaders"
          :item="item"
        >
          <template v-slot:item.quantity>
            <BaseEditableField
              v-model.number="item.quantity"
              :disabled="!canEdit"
              @input="$emit('change', decoratedRows)"
            >
              <template v-slot:inputs>
                <div class="d-flex">
                  <v-text-field
                    v-model.number="item.quantity"
                    :label="$t('quantity_produced_in_units')"
                    class="mt-0"
                    autofocus
                    hide-details
                    @input="$emit('change', decoratedRows)"
                  />
                  <div class="px-3 pt-5">/</div>
                  <v-text-field
                    v-model.number="item.planned_quantity"
                    :label="$t('needed_quantity_in_units')"
                    class="mt-0"
                    hide-details
                    @input="$emit('change', decoratedRows)"
                  />
                </div>
              </template>
              <span
                :class="{
                  'error--text font-weight-medium': item.isManufacturedQuantityInsufficient,
                }"
                >{{ item.quantity }}/{{ item.planned_quantity }}</span
              >
            </BaseEditableField>
          </template>

          <template v-if="canEdit" v-slot:item.comment>
            <BaseEditableField v-model="item.comment" @input="$emit('change', decoratedRows)">
              <v-tooltip v-if="item.comment" max-width="35em" bottom>
                <template #activator="{ on }">
                  <v-icon v-on="on" class="default-cursor" small @click.stop>
                    mdi-message-reply-text
                  </v-icon>
                </template>
                <div class="text-preserve-whitespace">{{ item.comment }}</div>
              </v-tooltip>
              <span v-else>-</span>
            </BaseEditableField>
          </template>

          <template v-if="canEdit" v-slot:item.estimated_start_at>
            <BaseDateTimePicker
              v-model="item.estimated_start_at"
              @input="$emit('change', decoratedRows)"
            >
              {{ item.estimated_start_at }} <v-icon small>mdi-pencil</v-icon>
            </BaseDateTimePicker>
          </template>

          <template v-slot:item.estimated_duration_in_minutes>
            <BaseEditableField
              v-model.number="item.estimated_duration_in_minutes"
              :disabled="!canEdit"
              :placeholder="indicateRequiredFields ? $t('required_field') : ''"
              @input="$emit('change', decoratedRows)"
            />
            <div v-if="item.estimatedTotalDuration">
              {{ $t('total') }}: {{ item.estimatedTotalDuration }}
            </div>
          </template>

          <template v-slot:item.tariff>
            <BaseEditableField :disabled="!canEdit">
              {{ item.employee_salary_amount_per_unit || '-' }}
              <span v-if="item.employee_salary_amount_per_unit"
                >€/{{
                  $t(
                    item.employee_salary_calculation_param === 'minutes'
                      ? 'minute_short'
                      : 'unit_short',
                  )
                }}</span
              >

              <template #inputs>
                <v-radio-group
                  v-model="item.employee_salary_calculation_param"
                  class="mt-0"
                  @change="$emit('change', decoratedRows)"
                >
                  <v-radio
                    v-for="option in calculationParamOptions"
                    :key="option.value"
                    :label="option.text"
                    :value="option.value"
                  />
                </v-radio-group>

                <v-text-field
                  v-model.number="item.employee_salary_amount_per_unit"
                  :placeholder="
                    `€/${$t(
                      item.employee_salary_calculation_param === 'minutes'
                        ? 'minute_short'
                        : 'unit_short',
                    )}`
                  "
                  type="number"
                  single-line
                  autofocus
                  @input="$emit('change', decoratedRows)"
                />
              </template>
            </BaseEditableField>
          </template>

          <template v-slot:item.status>
            <OrderPartProcessStatusChip
              :process="item"
              can-edit
              @change="updateProcessStatus(item, $event)"
            />
          </template>

          <MeasurementTable
            v-if="!canEdit"
            :key="item.id"
            :order-part-process-id="item.id"
            class="py-2"
          />
        </BaseExpandedTableRow>
      </template>
    </v-data-table>
  </BaseTableLoader>
</template>

<script>
import Sortable from 'sortablejs';
import BaseActionMenu from '../base/BaseActionMenu';
import BaseExpandedTableRow from '../base/BaseExpandedTableRow';
import BaseTableLoader from '../base/BaseTableLoader';
import tableMixin from '../../mixins/table-mixin';
import BaseDateTimePicker from '../base/BaseDateTimePicker';
import MeasurementTable from './MeasurementTable';
import { getDateTime } from '../../util/date';
import ProcessCard from '../ProcessCard';
import BaseEditableField from '../base/BaseEditableField';
import { getEstimatedTotalDuration } from '@/api/process-service';
import clone from 'just-clone';
import OrderPartProcessStatusChip from '@/components/OrderPartProcessStatusChip';

export default {
  name: 'OrderPartProcessTable',

  components: {
    OrderPartProcessStatusChip,
    BaseEditableField,
    ProcessCard,
    MeasurementTable,
    BaseDateTimePicker,
    BaseTableLoader,
    BaseExpandedTableRow,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  model: {
    prop: 'rows',
    event: 'change',
  },

  props: {
    rows: Array,
    loading: {
      type: Boolean,
      default: false,
    },
    // when true, hides process measurements (expanded rows),
    // allows inline editing and reordering
    canEdit: {
      type: Boolean,
      default: false,
    },
    hideProcessStatuses: {
      type: Boolean,
      default: false,
    },
    hideProcessQuantities: {
      type: Boolean,
      default: false,
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    order: {
      type: Object,
      default: () => null,
    },
    orderPart: {
      type: Object,
      default: null,
    },
    indicateRequiredFields: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      actions: [
        {
          callback: p => this.$emit('remove', p),
          label: this.$t('remove'),
          icon: 'mdi-close',
        },
      ],
    };
  },

  computed: {
    calculationParamOptions() {
      return [
        { text: this.$t('for_unit'), value: 'pcs' },
        { text: this.$t('for_minute'), value: 'minutes' },
      ];
    },

    decoratedRows() {
      return this.rows.map(row => {
        const r = clone(row);
        if (r.estimated_start_at) {
          r.estimated_start_at = getDateTime(r.estimated_start_at);
        }
        r.estimatedTotalDuration = this.getEstimatedTotalDuration(r);

        if (!this.canEdit && r.status === 'completed' && r.quantity < r.planned_quantity) {
          r.isManufacturedQuantityInsufficient = true;
        }

        return r;
      });
    },

    headers() {
      const headers = [
        {
          text: this.$t('title'),
          value: 'process.title',
        },
        {
          text: this.$t('quantity_in_units'),
          value: 'quantity',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('comment'),
          value: 'comment',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('estimated_start_at'),
          value: 'estimated_start_at',
          hidden: 'smAndDown',
        },
        {
          text: this.indicateRequiredFields
            ? `${this.$t('estimated_duration_in_seconds_unit')}*`
            : this.$t('estimated_duration_in_seconds_unit'),
          value: 'estimated_duration_in_minutes',
          hidden: 'mdAndDown',
        },
        {
          text: this.$t('tariff'),
          value: 'tariff',
          hidden: 'mdAndDown',
        },
        {
          text: this.$t('status'),
          value: 'status',
          hidden: 'xsOnly',
        },
      ];
      if (this.hideProcessQuantities) {
        headers.splice(1, 1);
      }
      if (this.hideProcessStatuses) {
        headers.splice(-1, 1);
      }
      if (this.canEdit) {
        headers.unshift({ value: 'handle', width: 10 });
        headers.push({ value: 'actions' });
      }
      return headers;
    },
  },

  async mounted() {
    await this.$nextTick();
    if (!this.$refs.processTable?.$el) {
      return;
    }
    Sortable.create(this.$refs.processTable.$el.querySelector('tbody'), {
      animation: 150,
      handle: '.draggable',
      onStart: () => {
        this.tableMixin_expandedRows = [];
      },
      onEnd: ({ newIndex, oldIndex }) => {
        this.$emit('reorder', newIndex, oldIndex);
      },
    });
  },

  methods: {
    getEstimatedTotalDuration,

    updateProcessStatus(process, newStatus) {
      if (this.canEdit) {
        // eslint-disable-next-line no-param-reassign
        process.status = newStatus;
        this.$emit('change', this.decoratedRows);
      } else {
        this.$emit('update:process-status', {
          ...process,
          status: newStatus,
        });
      }
    },

    onRowClick(item) {
      if (this.canEdit) {
        this.tableMixin_onRowClick(item);
      } else {
        this.tableMixin_expandRow(item);
      }
    },
  },
};
</script>
