<template>
  <v-dialog
    :value="isOpen"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    scrollable
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-title v-if="inventoryItem.id">
        {{ inventoryItem.title }}
      </v-card-title>

      <v-card-text>
        <v-progress-circular v-if="isDataLoading" color="primary" indeterminate />

        <div v-else-if="inventoryItem.id">
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3">
              <div class="subtitle-2">
                {{ $t('type') }}
              </div>
              {{ $t(`inventory_item_types.${inventoryItem.type}`) }}
            </v-col>

            <v-col v-if="inventoryItem.type !== 'bought'" cols="12" sm="6" md="4" lg="3">
              <div class="subtitle-2">
                {{ $t('quantity_in_progress') }}
              </div>
              <BaseNumberCell :value="inventoryItem.quantity_in_progress" />
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3">
              <div class="subtitle-2">
                {{ $t('quantity_available_short') }}
              </div>
              <div class="d-flex align-center">
                <BaseNumberCell :value="inventoryItem.quantity_in_warehouse" />
                <v-tooltip v-if="isBelowThreshold" transition="none" bottom>
                  <template #activator="{ on }">
                    <v-icon v-on="on" class="ml-1" color="error" small
                      >mdi-chevron-down-circle</v-icon
                    >
                  </template>
                  <div>
                    {{ $t('quantity_exceeded_min_threshold') }}
                  </div>
                </v-tooltip>
              </div>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3">
              <div class="subtitle-2">
                {{ $t('min_threshold') }}
              </div>
              <BaseNumberCell :value="inventoryItem.min_threshold" />
            </v-col>
          </v-row>

          <div class="subtitle-2 mt-4">{{ $t('used_in_order_parts') }}:</div>
          <InventoryItemPartsTable
            :items="inventoryItem.order_parts"
            :inventory-item="inventoryItem"
            disable-pagination
            disable-actions
            hide-location-field
            hide-status-field
          />
        </div>

        <div v-else>
          {{ $t('failed_to_fetch_data') }}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('close')">
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from '@/mixins/crud-mixin';
import inventoryItemService from '@/api/inventory-item-service';
import InventoryItemPartsTable from '@/components/tables/InventoryItemPartsTable';
import BaseNumberCell from '@/components/base/BaseNumberCell';

export default {
  name: 'InventoryItemDetails',

  components: { BaseNumberCell, InventoryItemPartsTable },

  mixins: [crudMixin],

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isDataLoading: false,
      inventoryItem: {},
    };
  },

  computed: {
    isBelowThreshold() {
      return (
        this.inventoryItem.min_threshold >= 0 &&
        this.inventoryItem.min_threshold >
          +this.inventoryItem.quantity_in_progress + +this.inventoryItem.quantity_in_warehouse
      );
    },
  },

  watch: {
    item: {
      handler(newValue) {
        if (newValue?.id && newValue.id !== this.inventoryItem.id) {
          this.inventoryItem = newValue;
          this.getInventoryItem(this.inventoryItem.id);
        }
      },
      immediate: true,
    },
  },

  methods: {
    async getInventoryItem(id) {
      this.isDataLoading = true;
      await this.crudMixin_getById(inventoryItemService.getById, inventoryItemService.model, id);
      if (this.inventoryItem.order_parts?.length) {
        this.$emit('fetch:order-part', this.inventoryItem.order_parts[0].id);
      }
    },
  },
};
</script>
