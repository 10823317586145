const filterMixin = {
  props: {
    filters: {
      type: Object,
      required: true,
    },
    defaultFilters: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      filterMixin_debounceTimerId: null,
    };
  },

  computed: {
    selectedFilterCount() {
      let count = 0;

      function isEqual(filter, defaultFilter) {
        return filter && defaultFilter && JSON.stringify(filter) === JSON.stringify(defaultFilter);
      }

      Object.keys(this.filters).forEach(key => {
        if (this.filters[key] && !isEqual(this.filters[key], this.defaultFilters[key])) {
          count += 1;
        }
      });
      return count;
    },
  },

  methods: {
    filterMixin_handleDebounce(key, value) {
      clearTimeout(this.filterMixin_debounceTimerId);
      this.filterMixin_debounceTimerId = setTimeout(() => {
        this.filterMixin_updateFilter(key, value);
      }, 500);
    },

    filterMixin_updateFilter(key, value) {
      if (this.filters[key] === value) {
        return;
      }

      const params = { ...this.filters };
      if (value) {
        params[key] = value;
      } else {
        delete params[key];
      }
      if (params.page) {
        // reset back to page 1 when changing filter
        delete params.page;
      }
      this.$emit('change', params);
    },

    filterMixin_resetFilter() {
      this.$emit('reset');
    },
  },
};

export default filterMixin;
