<template>
  <div>
    <SupplierInquiryMessageForm
      :supplier-inquiry-id="computedSupplierInquiryId"
      class="mb-8"
      @created="crudMixin_created(model, $event, true)"
    />

    <v-progress-circular
      v-if="$store.getters.loading['get:api/supplier-inquiry-messages'] && !isNextPageLoading"
      color="primary"
      indeterminate
    />

    <template v-else>
      <div
        v-for="(message, index) in supplierInquiryMessageArray"
        :key="message.id"
        :class="{ 'pb-4 border-b': index < supplierInquiryMessageArray.length - 1 }"
        class="mt-4"
      >
        <div class="d-flex align-center">
          <span class="font-weight-medium">{{ message.sender_name }}</span>
          <v-chip
            v-if="!message.is_seen"
            color="error"
            class="ml-2 px-2 font-weight-medium"
            small
            >{{ $t('new_email_short') }}</v-chip
          >
        </div>
        <div class="text-pre-line">{{ message.body }}</div>
      </div>

      <v-btn
        v-if="hasMorePages"
        :disabled="isNextPageLoading"
        :loading="isNextPageLoading"
        color="primary"
        class="mt-4"
        text
        @click="fetchSupplierInquiryMessages(supplierInquiryMessagePagination.page + 1)"
      >
        {{ $t('show_more') }}
      </v-btn>
    </template>
  </div>
</template>

<script>
import supplierInquiryMessageService from '@/api/supplier-inquiry-message-service';
import crudMixin from '@/mixins/crud-mixin';
import SupplierInquiryMessageForm from '@/components/forms/SupplierInquiryMessageForm';

export default {
  name: 'SupplierInquiryMessages',

  components: { SupplierInquiryMessageForm },

  mixins: [crudMixin],

  props: {
    supplierInquiryId: {
      type: Number,
      default: null,
    },
    supplierInquiryHash: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      model: 'supplierInquiryMessage',
      supplierInquiryMessageArray: [],
      supplierInquiryMessagePagination: {},
      isNextPageLoading: false,
    };
  },

  computed: {
    computedSupplierInquiryId() {
      if (this.supplierInquiryId) {
        return this.supplierInquiryId;
      }
      return this.supplierInquiryMessageArray.length > 0
        ? this.supplierInquiryMessageArray[0].supplier_inquiry_id
        : null;
    },

    hasMorePages() {
      const { page, rowsPerPage, total } = this.supplierInquiryMessagePagination;
      if (!total) {
        return false;
      }
      return rowsPerPage * page < total;
    },
  },

  created() {
    this.fetchSupplierInquiryMessages();
  },

  methods: {
    async fetchSupplierInquiryMessages(page = 1) {
      const params = {};
      if (this.supplierInquiryId) {
        params.supplier_inquiry_id = this.supplierInquiryId;
      } else if (this.supplierInquiryHash) {
        params.supplier_inquiry_hash = this.supplierInquiryHash;
      } else {
        return;
      }
      if (page > 1) {
        this.isNextPageLoading = true;
      }
      await this.crudMixin_getPage(
        supplierInquiryMessageService.getPage,
        this.model,
        page,
        params,
        true,
      );
      this.isNextPageLoading = false;
    },
  },
};
</script>

<style scoped></style>
