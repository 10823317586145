import http from './http';
import i18n from '@/i18n/i18n-config';

const endpoint = 'api/order-part-materials';

const orderPartMaterialService = {
  model: 'orderPartMaterial',

  getPage: (page, params) => http.get(endpoint, { params: { ...params, page } }),

  create: orderPartMaterial => http.post(endpoint, orderPartMaterial),

  patch: orderPartMaterial => {
    const payload = {
      warehouse_location_id: orderPartMaterial.warehouse_location_id,
      material_status: orderPartMaterial.material_status,
    };
    return http.patch(`${endpoint}/${orderPartMaterial.id}`, payload);
  },

  delete: orderPartMaterial =>
    http.delete(`${endpoint}/${orderPartMaterial.id}`, orderPartMaterial),

  getMissingAmounts: orderPartMaterials =>
    http.post(`${endpoint}/check-missing-material-amount-in-bulk`, orderPartMaterials),
};

export const ORDER_PART_MATERIAL_STATUSES = [
  {
    text: i18n.t('not_ordered'),
    value: 'not_ordered',
    color: 'grey lighten-4',
    filterLabel: i18n.t('show_not_ordered'),
  },
  {
    text: i18n.t('inquired'),
    value: 'inquired',
    color: 'yellow lighten-4',
    filterLabel: i18n.t('show_inquired'),
  },
  {
    text: i18n.t('ordered'),
    value: 'ordered',
    color: 'blue lighten-4',
    filterLabel: i18n.t('show_ordered'),
  },
  {
    text: i18n.t('arrived'),
    value: 'arrived',
    color: 'green accent-1',
    filterLabel: i18n.t('show_arrived'),
  },
];

export default orderPartMaterialService;
