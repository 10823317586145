<template>
  <v-row class="mb-3" dense>
    <v-col cols="12" class="d-flex justify-space-between align-center">
      <h2 class="subtitle-2 font-weight-medium">
        {{ $t('documents') }}
      </h2>

      <v-btn v-if="editable" color="primary" @click="isDocumentPickerOpen = true">
        <v-icon left>
          mdi-folder-open
        </v-icon>
        {{ $vuetify.breakpoint.xsOnly ? $t('add_document') : $t('select_from_document_list') }}
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-chip
        v-for="(document, index) in documents"
        :key="`${document.id}-${document.pivot.type}`"
        :close="!loadingFileIdMap[document.id] && editable"
        :disabled="loadingFileIdMap[document.id]"
        class="mr-1 mb-1"
        small
        @click="openFile(document)"
        @click:close="removeDocument(index)"
      >
        {{ document.file_name }} ({{ $t(`order_part_document_types.${document.pivot.type}`) }})
        <v-progress-circular
          v-if="loadingFileIdMap[document.id]"
          width="2"
          size="16"
          class="ml-2"
          indeterminate
        />
      </v-chip>
    </v-col>

    <v-dialog
      v-if="editable"
      v-model="isDocumentPickerOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="900"
      persistent
      scrollable
    >
      <DocumentLibraryPicker
        :dialog="isDocumentPickerOpen"
        @cancel="isDocumentPickerOpen = false"
        @submit="addSelectedDocument"
      />
    </v-dialog>

    <BaseFileViewer
      v-model="isFileViewerDialogOpen"
      :file-url="selectedFileUrl"
      :title="selectedFileTitle"
      :type="selectedFileTitle.toLowerCase().indexOf('pdf') > -1 ? 'pdf' : 'img'"
    />
  </v-row>
</template>

<script>
import DocumentLibraryPicker from '@/components/DocumentLibraryPicker';
import BaseFileViewer from '@/components/base/BaseFileViewer';
import documentLibraryMixin from '@/mixins/document-library-mixin';

export default {
  name: 'OrderPartDocumentPicker',

  components: { BaseFileViewer, DocumentLibraryPicker },

  mixins: [documentLibraryMixin],

  props: {
    documents: {
      type: Array,
      required: true,
    },

    editable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isDocumentPickerOpen: false,
    };
  },

  methods: {
    addSelectedDocument({ document, type }) {
      this.isDocumentPickerOpen = false;
      for (let i = 0; i < this.documents.length; i++) {
        if (this.documents[i].id === document.id) {
          this.documents.splice(i, 1);
          break;
        }
      }
      this.documents.push({
        ...document,
        pivot: {
          type,
        },
      });
    },

    removeDocument(index) {
      this.documents.splice(index, 1);
    },
  },
};
</script>

<style scoped></style>
