<template>
  <BaseEditableField
    v-if="canEdit"
    :value="value"
    :close-counter="menuCloseCounter"
    type="number"
    justify="end"
    @input="$emit('input', $event)"
  >
    <template v-if="priceSuggestions.length" v-slot:prepend>
      <div class="mb-4">
        <div class="text-pre-wrap">{{ priceSuggestionsHint }}</div>
        <v-chip
          v-for="price in priceSuggestions"
          :key="price"
          @click="onPriceSuggestionClick(price)"
        >
          {{ price }}
        </v-chip>
      </div>
      <div>{{ $t('or_enter_custom_price') }}:</div>
    </template>
  </BaseEditableField>
  <BaseNumberCell v-else :value="value" />
</template>

<script>
import BaseEditableField from '@/components/base/BaseEditableField';
import BaseNumberCell from '@/components/base/BaseNumberCell';
import { round } from '@/util/numbers';

export default {
  name: 'MaterialPricePerQuantityUnitEditableField',

  components: { BaseNumberCell, BaseEditableField },

  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    purchases: {
      type: Array,
      default: () => [],
    },
    materialUnit: {
      type: String,
      default: 'a',
    },
    materialQuantityPerUnit: {
      type: [Number, String],
      default: 0,
    },
  },

  data() {
    return {
      menuCloseCounter: 0,
    };
  },

  computed: {
    priceSuggestionsHint() {
      let hint = this.$t('select_from_purchase_gross_costs');
      if (this.materialQuantityPerUnit) {
        hint += '.\n';
        hint += this.$t('price_will_be_multiplied_by_material_quantity_per_unit').replace(
          '{0}',
          this.materialQuantityPerUnit,
        );
      }
      return hint;
    },

    priceSuggestions() {
      const priceKey =
        this.materialUnit === 'a' ? 'gross_cost_per_unit_a' : 'gross_cost_per_unit_b';
      const prices = this.purchases.map(purchase => +purchase[priceKey]).filter(price => price > 0);

      // remove duplicates
      return Array.from(new Set(prices));
    },
  },

  methods: {
    onPriceSuggestionClick(price) {
      let p = price;
      if (+this.materialQuantityPerUnit) {
        p = round(p * +this.materialQuantityPerUnit);
      }
      this.$emit('input', p);
      this.menuCloseCounter += 1;
    },
  },
};
</script>

<style scoped></style>
