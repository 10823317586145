<template>
  <v-data-table
    :expanded="[expandedOrder]"
    :footer-props="{
      'items-per-page-options': [50, 100, 250, 500],
    }"
    :items-per-page="+pagination.rowsPerPage"
    :headers="tableMixin_displayedHeaders"
    :items="displayedItems"
    :mobile-breakpoint="0"
    :page="pagination.page"
    :loading="loading"
    :server-items-length="pagination.totalItems"
    :class="{ 'content-loading': loading, 'force-wrap': forceContentWrapping }"
    :hide-default-header="displayAs === 'cards'"
    class="order-table"
    ref="table"
    disable-sort
    @update:page="tableMixin_changePage"
    @update:items-per-page="$emit('update:items-per-page', $event)"
  >
    <template v-if="displayAs === 'cards'" v-slot:body="{ items }">
      <v-divider />
      <OrderCardsGrid
        :orders="items"
        :order-processes="orderProcesses"
        :order-materials="orderMaterials"
        :get-item-actions="getRowActions"
        :loading="loading"
        @click:order-no="$emit('row-expand', $event)"
        @change:status="onStatusChange"
      />
    </template>

    <template v-slot:item.handle>
      <v-icon class="draggable">
        mdi-drag
      </v-icon>
    </template>

    <template v-slot:item.order_no="{ item }">
      <div class="d-flex align-center" style="min-width: 130px;">
        <span class="link" @click.stop="$emit('row-expand', item)"> {{ item.order_no }} </span>
        <v-tooltip
          v-if="item.workDaysTillDeadline >= 0 && item.workDaysTillDeadline <= 14"
          transition="none"
          bottom
        >
          <template #activator="{ on }">
            <v-icon
              v-on="on"
              :color="item.workDaysTillDeadline <= 7 ? 'error' : 'yellow darken-2'"
              class="ml-1"
              small
              >mdi-clock</v-icon
            >
          </template>
          <div v-if="item.workDaysTillDeadline > 0">
            {{ item.workDaysTillDeadline }} {{ $t('work_days_till_deadline') }}
          </div>
          <div v-else>{{ $t('is_overdue') }}!</div>
        </v-tooltip>
        <v-tooltip
          v-if="item.hasCompletedProcessesWithInsufficientManufacturedQuantity"
          transition="none"
          bottom
        >
          <template #activator="{ on }">
            <v-icon v-on="on" color="error" class="ml-1" small>mdi-alert-circle</v-icon>
          </template>
          <div>
            {{ $t('has_completed_processes_with_insufficient_manufactured_quantity') }}
          </div>
        </v-tooltip>
      </div>
      <div class="d-flex flex-wrap" style="max-width: 128px">
        <span
          v-for="p in orderProcesses[item.id]"
          :key="p.id"
          class="bubble"
          :style="`background-color: ${orderPartProcessStatuses[p.status].hexColor}`"
        />
      </div>
    </template>

    <template v-slot:item.client="{ item }">
      <v-menu v-if="item.client" :close-on-content-click="false" offset-y nudge-bottom="8">
        <template v-slot:activator="{ on }">
          <span v-on="on" class="link">
            {{ item.client.name }}
          </span>
        </template>
        <ContactCard :contact-id="item.client.id" />
      </v-menu>
      <div v-if="item.client_order_no" class="grey--text">
        {{ item.client_order_no }}
      </div>
    </template>

    <template v-slot:item.group="{ item }">
      <span class="mr-1">
        {{ item.group && item.group.name }}
      </span>
      <v-icon @click.stop="$emit('new-group-item', item.group)">mdi-file-document-box-plus</v-icon>
    </template>

    <template v-slot:item.materials="{ item }">
      <MaterialBubbles :materials="orderMaterials[item.id]" />
    </template>

    <template v-slot:item.createdBy="{ item }">
      <span
        class="link"
        @click="
          $emit('update:filter', {
            ...filterParams,
            search: item.createdBy,
          })
        "
      >
        {{ item.createdBy }} </span
      ><br />
      <span class="grey--text text-no-wrap">{{ item.createdAtFormatted }}</span>
    </template>

    <template v-slot:item.responsible_user="{ item }">
      <div
        class="link"
        @click="
          $emit('update:filter', {
            ...filterParams,
            responsible_user_id: item.responsible_user_id,
          })
        "
      >
        {{ item.responsible_user.person.full_name }}
      </div>
      <div
        v-if="item.deadline_at"
        :class="item.workDaysTillDeadline >= 0 ? 'error--text font-weight-bold' : 'grey--text'"
      >
        {{ item.deadline_at }}
      </div>
    </template>

    <template v-slot:item.status="{ item }">
      <BaseChipSelect
        :value="item.status"
        :items="orderStatuses"
        :tooltip="item.status === 'canceled' ? item.cancellation_comment : ''"
        @change="onStatusChange(item, $event)"
      />
    </template>

    <template v-slot:item.is_offer_sent="{ item }">
      <div>
        <v-icon v-if="item.is_offer_sent" color="success">
          mdi-checkbox-marked-circle-outline
        </v-icon>
      </div>
    </template>

    <template v-slot:item.is_shipped="{ item }">
      <v-icon
        :color="item.is_shipped ? 'primary' : ''"
        @click.stop="onCheckboxChange(item, 'is_shipped')"
        >mdi-checkbox-{{ item.is_shipped ? 'marked' : 'blank-outline' }}</v-icon
      >
    </template>

    <template v-slot:item.is_invoice_issued="{ item }">
      <v-icon
        :color="item.is_invoice_issued ? 'primary' : ''"
        @click.stop="onCheckboxChange(item, 'is_invoice_issued')"
        >mdi-checkbox-{{ item.is_invoice_issued ? 'marked' : 'blank-outline' }}</v-icon
      >
    </template>

    <template v-slot:item.comments="{ item }">
      <BaseTruncatedField :text="item.comments" :truncated-length="50" />
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu :actions="getRowActions(item)" :item="item" simple />
    </template>

    <template v-slot:expanded-item="{ item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.group>
          <div class="d-flex align-center">
            <span class="mr-1">{{ item.group && item.group.name }}</span>
            <v-btn icon @click.stop="$emit('new-group-item', item.group)">
              <v-icon>mdi-file-document-box-plus</v-icon>
            </v-btn>
          </div>
        </template>

        <template v-slot:item.materials>
          <MaterialBubbles :materials="orderMaterials[item.id]" />
        </template>

        <template v-slot:item.client>
          <v-menu v-if="item.client" :close-on-content-click="false" offset-y nudge-bottom="8">
            <template v-slot:activator="{ on }">
              <span v-on="on" class="link">
                {{ item.client.name }}
              </span>
            </template>
            <ContactCard :contact-id="item.client.id" />
          </v-menu>
          <div v-if="item.client_order_no" class="grey--text">
            {{ item.client_order_no }}
          </div>
        </template>

        <template v-slot:item.createdBy>
          <span
            class="link"
            @click="
              $emit('update:filter', {
                ...filterParams,
                search: item.createdBy,
              })
            "
          >
            {{ item.createdBy }} </span
          ><br />
          <span class="grey--text">{{ item.createdAtFormatted }}</span>
        </template>

        <template v-slot:item.responsible_user>
          <div
            class="link"
            @click="
              $emit('update:filter', {
                ...filterParams,
                responsible_user_id: item.responsible_user_id,
              })
            "
          >
            {{ item.responsible_user.person.full_name }}
          </div>
          <div
            v-if="item.deadline_at"
            :class="item.workDaysTillDeadline >= 0 ? 'error--text font-weight-bold' : 'grey--text'"
          >
            {{ item.deadline_at }}
          </div>
        </template>

        <template v-slot:item.status>
          <BaseChipSelect
            :value="item.status"
            :items="orderStatuses"
            @change="onStatusChange(item, $event)"
          />
          <p v-if="item.status === 'canceled' && item.cancellation_comment">
            {{ item.cancellation_comment }}
          </p>
        </template>

        <template v-slot:item.is_offer_sent>
          <div>
            <v-icon v-if="item.is_offer_sent" color="success">
              mdi-checkbox-marked-circle-outline
            </v-icon>
          </div>
        </template>

        <template v-slot:item.is_shipped>
          <v-icon
            :color="item.is_shipped ? 'primary' : ''"
            @click.stop="onCheckboxChange(item, 'is_shipped')"
            >mdi-checkbox-{{ item.is_shipped ? 'marked' : 'blank-outline' }}</v-icon
          >
        </template>

        <template v-slot:item.is_invoice_issued>
          <v-icon
            :color="item.is_invoice_issued ? 'primary' : ''"
            @click.stop="onCheckboxChange(item, 'is_invoice_issued')"
            >mdi-checkbox-{{ item.is_invoice_issued ? 'marked' : 'blank-outline' }}</v-icon
          >
        </template>

        <template v-slot:append>
          <slot name="expanded-item-append" :order="item" />
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import Sortable from 'sortablejs';
import BaseActionMenu from '../base/BaseActionMenu';
import BaseExpandedTableRow from '../base/BaseExpandedTableRow';
import tableMixin from '../../mixins/table-mixin';
import BaseTruncatedField from '../base/BaseTruncatedField';
import BaseChipSelect from '../base/BaseChipSelect';
import { ORDER_STATUSES } from '../../api/order-service';
import { getOrderPartProcessStatusMap } from '../../api/order-part-service';
import ContactCard from '../ContactCard';
import OrderCardsGrid from '@/components/OrderCardsGrid';
import MaterialBubbles from '@/components/MaterialBubbles';

export default {
  name: 'OrderTable',

  components: {
    MaterialBubbles,
    OrderCardsGrid,
    ContactCard,
    BaseChipSelect,
    BaseTruncatedField,
    BaseExpandedTableRow,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    expandedOrder: {
      type: Object,
      default: () => ({}),
    },
    rows: Array,
    pagination: Object,
    loading: {
      type: Boolean,
      default: false,
    },
    offersView: {
      type: Boolean,
      default: false,
    },
    filterParams: {
      type: Object,
      default: () => ({}),
    },
    displayAs: {
      type: String,
      default: 'table',
    },
    forceContentWrapping: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      orderStatuses: ORDER_STATUSES,
      orderPartProcessStatuses: getOrderPartProcessStatusMap(),
    };
  },

  computed: {
    displayedItems() {
      return this.rows.map(r => ({
        ...r,
        createdAtFormatted: r.created_at ? r.created_at.slice(0, 10) : '',
        createdBy: r.creator?.person?.full_name,
      }));
    },

    headers() {
      let headers = [
        {
          value: 'handle',
          width: 10,
        },
        {
          text: this.$t(this.offersView ? 'offer_no' : 'order_no'),
          value: 'order_no',
        },
        {
          text: `${this.$t('client_contact_id')} / ${this.$t('client_order_no')}`,
          value: 'client',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('order_group'),
          value: 'group',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('materials'),
          value: 'materials',
          hidden: 'mdAndDown',
        },
        {
          text: this.$t('created_by'),
          value: 'createdBy',
          hidden: 'mdAndDown',
        },
        {
          text: `${this.$t('responsible_user')} / ${this.$t('deadline_at')}`,
          value: 'responsible_user',
          hidden: 'mdAndDown',
        },
        {
          text: this.$t('status'),
          value: 'status',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('is_offer_sent'),
          value: 'is_offer_sent',
          hidden: 'mdAndDown',
        },
        {
          text: this.$t('is_shipped'),
          value: 'is_shipped',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('is_invoice_issued'),
          value: 'is_invoice_issued',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('comment'),
          value: 'comments',
          hidden: 'mdAndDown',
        },
        {
          value: 'actions',
        },
      ];
      if (!this.offersView) {
        headers = headers.filter(h => h.value !== 'is_offer_sent');
      } else {
        headers = headers.filter(h => !['is_shipped', 'is_invoice_issued'].includes(h.value));
      }
      return headers;
    },

    orderProcesses() {
      const orderProcesses = {};
      for (let i = 0; i < this.rows.length; i++) {
        const order = this.rows[i];
        const processes = [];
        for (let j = 0; j < order.parts.length; j++) {
          const part = order.parts[j];
          for (let k = 0; k < part.processes.length; k++) {
            const p = part.processes[k];
            processes.push({
              id: p.id,
              title: p.process.title,
              status: p.status,
            });
          }
        }
        orderProcesses[order.id] = processes;
      }
      return orderProcesses;
    },

    orderMaterials() {
      const orderMaterials = {};
      for (let i = 0; i < this.rows.length; i++) {
        const order = this.rows[i];
        const materials = [];
        for (let j = 0; j < order.parts.length; j++) {
          const p = order.parts[j];
          for (let k = 0; k < p.materials.length; k++) {
            const m = p.materials[k];
            materials.push({
              id: `material-${m.id}`,
              title: m.material?.title,
              quantity_in_warehouse: m.material?.quantity_in_warehouse || 0,
              material_status: m.material_status,
              material_unit: m.material_unit,
              unit_b_title: m.material?.unit_b_title || '',
              unit_a_b_ratio: m.material?.unit_a_b_ratio || 1,
            });
          }
        }
        orderMaterials[order.id] = materials;
      }
      return orderMaterials;
    },

    title() {
      return this.$t(this.offersView ? 'offers' : 'orders');
    },
  },

  watch: {
    loading(newValue) {
      if (newValue || this.displayAs !== 'table') {
        return;
      }
      this.initReordering();
    },

    displayAs: {
      handler(newValue) {
        if (newValue !== 'table') {
          return;
        }
        this.initReordering();
      },
      immediate: true,
    },
  },

  methods: {
    getRowActions(item) {
      const actions = [
        {
          callback: p => this.$emit('new-part', p),
          label: this.$t('create_order_part'),
          icon: 'mdi-plus',
        },
      ];
      if (this.offersView) {
        actions.push({
          callback: p => this.$emit('new-estimation', p),
          label: this.$t('prepare_estimation'),
          icon: 'mdi-file',
        });
      } else {
        actions.push({
          callback: p => this.$emit('new-order-defect', { order_id: p.id }),
          label: this.$t('new_order_defect'),
          icon: 'mdi-select-off',
        });
      }
      actions.push({
        callback: p => this.$emit('clone', p),
        label: this.$t('create_copy'),
        icon: 'mdi-content-copy',
      });
      actions.push({
        callback: p => this.$emit('generate-offer', p),
        loading: this.$store.getters.loading[`post:api/orders/${item.id}/generate-offer`],
        label: this.$t('generate_offer'),
        icon: 'mdi-file-document-box',
      });
      actions.push({
        callback: p => this.$emit('edit', p),
        label: this.$t('edit'),
        icon: 'mdi-pencil',
      });
      actions.push({
        callback: p => this.$emit('delete', p),
        label: this.$t('delete'),
        icon: 'mdi-delete',
      });
      return actions;
    },

    async initReordering() {
      await this.$nextTick();
      const table = document.querySelector('.order-table tbody');
      Sortable.create(table, {
        animation: 150,
        handle: '.draggable',
        onStart: () => {
          this.tableMixin_expandedRows = [];
        },
        onEnd: ({ newIndex, oldIndex }) => {
          this.$emit('reorder', newIndex, oldIndex);
        },
      });
    },

    onCheckboxChange(item, field) {
      const updatedOrder = {
        ...item,
        [field]: !item[field],
      };
      if (updatedOrder.is_shipped && updatedOrder.is_invoice_issued) {
        updatedOrder.status = 'completed';
      }
      this.$emit('update:order', updatedOrder);
    },

    onStatusChange(item, newStatus) {
      const updatedOrder = {
        ...item,
        status: newStatus,
      };
      this.$emit('update:order', updatedOrder);
    },
  },
};
</script>
