<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-radio-group
              v-model="order.type"
              :error-messages="errors.type"
              :label="$t('type')"
              :disabled="formItem.type === 'order' && !!order.id"
              @change="updateOrderNo"
            >
              <v-radio :label="$t('order')" value="order" />
              <v-radio :label="$t('offer')" value="offer" />
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="6">
            <v-radio-group
              v-model="order.status"
              :error-messages="errors.status"
              :label="$t('status')"
              @change="formMixin_clearErrors('status')"
            >
              <v-radio
                v-for="status in orderStatuses"
                :key="status.value"
                :label="status.text"
                :value="status.value"
              />
            </v-radio-group>
          </v-col>

          <v-col v-if="order.status === 'canceled'" cols="12">
            <v-textarea
              v-model="order.cancellation_comment"
              :error-messages="errors['cancellation_comment']"
              :label="$t('cancellation_comment')"
              rows="1"
              auto-grow
              @blur="formMixin_clearErrors('cancellation_comment')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="order.order_no"
              :error-messages="errors['order_no']"
              :label="
                formMixin_getRequiredFieldLabel(
                  $t(order.type === 'order' ? 'order_no' : 'offer_no'),
                )
              "
              disabled
              @blur="formMixin_clearErrors('order_no')"
            />
          </v-col>

          <v-col cols="12" sm="6"></v-col>

          <v-col cols="12" sm="6">
            <BaseAutocomplete
              v-model="order.client_contact_id"
              :initial-item="order.client"
              :search-function="clientSearchFunction"
              :error-messages="errors.client_contact_id"
              :label="formMixin_getRequiredFieldLabel($t('client_contact_id'))"
              :key="order.id"
              @input="formMixin_clearErrors('client_contact_id')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="order.client_order_no"
              :error-messages="errors['client_order_no']"
              :label="$t('client_order_no')"
              @blur="formMixin_clearErrors('client_order_no')"
            />
          </v-col>

          <v-col cols="12" sm="9">
            <v-text-field
              v-model="order.group.name"
              :error-messages="errors['group.name']"
              :label="formMixin_getRequiredFieldLabel($t('order_group'))"
              @blur="formMixin_clearErrors('order_no')"
            />
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field
              v-model="order.quantity"
              :error-messages="errors['quantity']"
              :label="formMixin_getRequiredFieldLabel($t('quantity'))"
              type="number"
              @blur="formMixin_clearErrors('quantity')"
            />
          </v-col>

          <v-col v-if="order.type === 'order'" cols="12" sm="6">
            <BaseDatepickerInput
              v-model="order.deadline_at"
              :error-messages="errors['deadline_at']"
              :label="$t('deadline_at')"
              @input="formMixin_clearErrors('deadline_at')"
            />
          </v-col>

          <v-col v-if="order.type === 'offer'" cols="12" sm="6">
            <v-checkbox
              v-model="order.is_offer_sent"
              :error-messages="errors['is_offer_sent']"
              :label="$t('is_offer_sent')"
              hide-details
              @blur="formMixin_clearErrors('is_offer_sent')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <BaseAutocomplete
              v-model="order.responsible_user_id"
              :initial-item="order.responsible_user"
              :search-function="searchUsers"
              :error-messages="errors.responsible_user_id"
              :label="$t('responsible_user')"
              :key="order.id"
              no-filter
              item-text="full_name"
              @input="formMixin_clearErrors('responsible_user_id')"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="order.comments"
              :error-messages="errors['comments']"
              :label="$t('comment')"
              rows="1"
              auto-grow
              @blur="formMixin_clearErrors('comments')"
            />
          </v-col>

          <v-col v-if="order.type === 'order'" cols="12" sm="4">
            <v-checkbox
              v-model="order.is_shipped"
              :error-messages="errors['is_shipped']"
              :label="$t('is_shipped')"
              hide-details
              @blur="formMixin_clearErrors('is_shipped')"
              @change="setOrderStatus"
            />
          </v-col>

          <v-col v-if="order.type === 'order'" cols="12" sm="4">
            <v-checkbox
              v-model="order.is_invoice_issued"
              :error-messages="errors['is_invoice_issued']"
              :label="$t('is_invoice_issued')"
              hide-details
              @blur="formMixin_clearErrors('is_invoice_issued')"
              @change="setOrderStatus"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!order.id" class="subtitle-2 ml-3"> * {{ $t('must_be_filled') }} </span>

        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="isOrderIssuanceDialogOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="600"
      persistent
      scrollable
    >
      <OrderIssuanceForm
        :dialog="isOrderIssuanceDialogOpen"
        :form-item="order"
        @cancel="isOrderIssuanceDialogOpen = false"
        @update="
          isOrderIssuanceDialogOpen = false;
          $emit('update', $event);
          $emit('cancel');
        "
      />
    </v-dialog>
  </v-form>
</template>

<script>
import BaseAutocomplete from '../base/BaseAutocomplete';
import contactService from '../../api/contact-service';

import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import orderService, { ORDER_STATUSES } from '../../api/order-service';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';
import OrderIssuanceForm from '@/components/forms/OrderIssuanceForm';
import userService from '@/api/user-service';

export default {
  name: 'OrderForm',

  components: {
    OrderIssuanceForm,
    BaseDatepickerInput,
    BaseAutocomplete,
  },

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    formItem: Object,
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      order: {},
      orderTypes: [
        { text: this.$t('offer'), value: 'offer' },
        { text: this.$t('order'), value: 'order' },
      ],
      orderStatuses: ORDER_STATUSES,
      clientSearchFunction: contactService.searchClients,
      isOrderIssuanceDialogOpen: false,
    };
  },

  computed: {
    formTitle() {
      let title = this.order.id ? 'edit_' : 'new_';
      title += this.order.id ? this.formItem.type : this.order.type;
      return this.$t(title);
    },
  },

  methods: {
    searchUsers: userService.search,

    onDialogOpen() {
      this.order = JSON.parse(JSON.stringify(this.formItem));
      this.errors = {};
      if (!this.order.order_no) {
        this.updateOrderNo(this.formItem.type);
      }
      if (this.order.responsible_user?.person) {
        this.order.responsible_user.full_name = this.order.responsible_user.person.full_name;
      }
    },

    onSubmit() {
      const createMsg = this.$t(`${this.order.type}_created`);
      const updateMsg = this.$t(`${this.formItem.type}_updated`);
      this.crudMixin_createOrUpdate(orderService, this.order, createMsg, updateMsg);
    },

    setOrderStatus() {
      if (this.order.is_shipped && this.order.is_invoice_issued) {
        this.$set(this.order, 'status', 'completed');
        this.isOrderIssuanceDialogOpen = true;
      }
    },

    updateOrderNo(type) {
      this.formMixin_clearErrors('type');
      if (this.order.id && type === this.formItem.type) {
        this.$set(this.order, 'order_no', this.formItem.order_no);
      } else {
        orderService.getOrderNo(type).then(res => {
          this.$set(this.order, 'order_no', res.data.order_no);
        });
      }
    },
  },
};
</script>
