var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":!_vm.isInventoryItemGroupFormOpen && !_vm.isInventoryItemFormOpen && !_vm.crudMixin_isDeleteDialogOpen,"nudge-bottom":_vm.nudgeBottom,"nudge-left":_vm.nudgeLeft,"nudge-top":_vm.nudgeTop,"nudge-right":_vm.nudgeRight,"left":_vm.left,"top":_vm.top,"min-width":_vm.$vuetify.breakpoint.smAndUp ? 600 : 300,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",function(){return [_c('v-btn',_vm._g({attrs:{"color":"primary"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('add_materials'))+" ")],1)]},{"on":on})]}}],null,true),model:{value:(_vm.isMenuOpen),callback:function ($$v) {_vm.isMenuOpen=$$v},expression:"isMenuOpen"}},[_c('v-card',[_c('v-card-title',[(_vm.inventoryItemGroupBreadcrumbs.length && _vm.globalSearchQuery.length < 3)?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":_vm.viewParent}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1):_vm._e(),_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-tooltip',{attrs:{"transition":"none","max-width":"30em","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-3",attrs:{"color":"info"}},on),[_vm._v("mdi-information")])]}}])},[_c('div',[_c('div',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t('inventory_item_add_hint'))+" ")]),_c('div',[_vm._v(_vm._s(_vm.$t('inventory_item_group_open_hint')))])])]),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('create'))+" ")],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.crudMixin_openForm('inventoryItemGroup', _vm.newInventoryItemGroupTemplate)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('inventory_item_group_accusative')))])],1),_c('v-list-item',{on:{"click":_vm.createInventoryItem}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('material_accusative')))])],1)],1)],1)],1),_c('v-card-text',{staticClass:"px-0"},[_c('div',{staticClass:"px-3 pb-1"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"mt-0",attrs:{"label":_vm.$t('search_this_group'),"disabled":!!_vm.globalSearchQuery,"prepend-inner-icon":"mdi-magnify","clearable":""},model:{value:(_vm.localSearchQuery),callback:function ($$v) {_vm.localSearchQuery=$$v},expression:"localSearchQuery"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"mt-0",attrs:{"value":_vm.globalSearchQuery,"label":_vm.$t('search_all_materials'),"hint":!_vm.globalSearchQuery || _vm.globalSearchQuery.length < 3
                    ? _vm.$t('enter_three_or_more_symbols')
                    : '',"prepend-inner-icon":"mdi-magnify","clearable":""},on:{"input":_vm.onGlobalSearchInput}})],1)],1)],1),(_vm.loadingGlobalSearchResults)?_c('v-progress-circular',{staticClass:"mt-6 mx-3",attrs:{"color":"primary","indeterminate":""}}):_c('InventoryItemGroupTable',{attrs:{"rows":_vm.filteredItems,"pagination":_vm.pagination,"loading":_vm.$store.getters.loading['get:api/inventory-item-groups'],"height":300},on:{"dblclick:row":_vm.viewChildren,"row-click":_vm.onRowClick,"change-page":_vm.getInventoryItemGroups,"edit":_vm.onEditClick,"delete":_vm.deleteGroup}})],1)],1)],1),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"transition":"slide-y-reverse-transition","max-width":"800","persistent":"","scrollable":""},model:{value:(_vm.isInventoryItemGroupFormOpen),callback:function ($$v) {_vm.isInventoryItemGroupFormOpen=$$v},expression:"isInventoryItemGroupFormOpen"}},[_c('InventoryItemGroupForm',{attrs:{"dialog":_vm.isInventoryItemGroupFormOpen,"form-item":_vm.inventoryItemGroupFormItem,"parent-group-id":_vm.selectedGroup.id},on:{"cancel":function($event){_vm.isInventoryItemGroupFormOpen = false},"create":function($event){return _vm.crudMixin_created('inventoryItemGroup', $event)},"update":function($event){return _vm.crudMixin_updated('inventoryItemGroup', $event)}}})],1),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"transition":"slide-y-reverse-transition","max-width":"800","persistent":"","scrollable":""},model:{value:(_vm.isInventoryItemFormOpen),callback:function ($$v) {_vm.isInventoryItemFormOpen=$$v},expression:"isInventoryItemFormOpen"}},[_c('InventoryItemForm',{attrs:{"dialog":_vm.isInventoryItemFormOpen,"form-item":_vm.inventoryItemFormItem},on:{"cancel":function($event){_vm.isInventoryItemFormOpen = false},"create":_vm.inventoryItemCreated,"update":_vm.inventoryItemUpdated}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }