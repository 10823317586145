import http from './http';

const endpoint = 'api/order-defects';

const orderDefectService = {
  model: 'orderDefect',

  getPage: (page, params) => http.get(`${endpoint}?page=${page}`, { params }),

  create: orderDefect => http.post(`${endpoint}`, orderDefect),

  update: orderDefect => http.put(`${endpoint}/${orderDefect.id}`, orderDefect),

  delete: orderDefect => http.delete(`${endpoint}/${orderDefect.id}`, orderDefect),
};

export default orderDefectService;
