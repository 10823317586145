<template>
  <v-menu v-model="isOpen" :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="clickable text-no-wrap">
        <slot></slot>
      </span>
    </template>
    <v-form @submit.prevent="save">
      <v-tabs v-model="activeTab" class="align-center">
        <v-tab>
          <v-icon>mdi-calendar</v-icon>
        </v-tab>
        <v-tab>
          <v-icon>mdi-clock-outline</v-icon>
        </v-tab>
        <v-spacer />
        <div class="subtitle-2 black--text text-right pa-2">
          {{ date }}<br />
          {{ time }}
        </div>
        <v-tab-item class="text-center">
          <v-date-picker
            v-model="date"
            :locale="locale"
            class="elevation-0"
            first-day-of-week="1"
            no-title
            show-week
            scrollable
            @input="dateSelected"
          />
        </v-tab-item>
        <v-tab-item class="text-center">
          <v-time-picker
            v-model="time"
            class="elevation-0"
            format="24hr"
            ref="timePicker"
            no-title
            scrollable
            @input="onTimePickerInput"
          />
        </v-tab-item>
      </v-tabs>
      <v-sheet class="px-1 pb-1 text-right">
        <v-btn color="primary" text @click="isOpen = false">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn color="primary" type="submit" text @click="save">
          OK
        </v-btn>
      </v-sheet>
    </v-form>
  </v-menu>
</template>

<script>
import { format, isValid } from 'date-fns';

export default {
  name: 'BaseDateTimePicker',

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      activeTab: 0,
      isOpen: false,
      date: '',
      time: '',
    };
  },

  computed: {
    locale() {
      return this.$store.state.settings.locale;
    },

    currentValue() {
      return `${this.date} ${this.time}`;
    },
  },

  created() {
    if (this.value) {
      this.setDateTime(this.value);
    }
  },

  watch: {
    isOpen(newValue) {
      if (newValue && this.$refs.timePicker) {
        this.$refs.timePicker.selectingHour = true;

        if (this.value) {
          this.setDateTime(this.value);
        }
      }
    },
  },

  methods: {
    setDateTime(value) {
      if (!value || !isValid(new Date(value))) {
        this.date = '';
        this.time = '';
        return;
      }

      this.date = format(new Date(value), 'yyyy-MM-dd');
      this.time = format(new Date(value), 'HH:mm');
    },

    dateSelected() {
      this.activeTab = 1;
    },

    save() {
      this.$emit('input', this.currentValue);
      this.isOpen = false;
    },

    onTimePickerInput() {
      if (!this.$refs.timePicker.selectingHour) {
        this.save();
      }
    },
  },
};
</script>

<style scoped></style>
