<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :items="items"
    :headers="tableMixin_displayedHeaders"
    :mobile-breakpoint="0"
    disable-pagination
    disable-sort
    hide-default-footer
    @click:row="tableMixin_onRowClick"
    @update:page="tableMixin_changePage"
  >
    <template v-slot:item.order="{ item }">
      <div v-if="item.order">
        {{ item.order.title }}
        <router-link :to="getOrderUrl(item)" target="_blank" class="link">
          {{ item.order.order_no }}
        </router-link>
      </div>
    </template>

    <template v-slot:item.status="{ item }">
      <v-chip
        v-if="item.status"
        :color="orderPartStatuses[item.status].color"
        class="persist-opacity"
        label
        small
      >
        {{ orderPartStatuses[item.status].text }}
      </v-chip>
    </template>

    <template v-slot:item.warehouse_location_id="{ item }">
      <BaseChipSelect
        :value="item.warehouse_location_id"
        :items="$store.getters.warehouseLocations"
        item-value="id"
        item-text="name"
        show-search
        @change="updateWarehouseLocation(item, $event)"
      />
    </template>

    <template v-slot:item.quantity_in_warehouse="{ item }">
      <span :class="{ 'grey--text': !+item.quantity_in_warehouse }">
        {{
          getInventoryItemQuantity(
            item.quantity_in_warehouse,
            inventoryItem.material_unit,
            inventoryItem.unit_a_title,
            inventoryItem.unit_b_title,
            inventoryItem.unit_a_b_ratio,
          )
        }}
      </span>
    </template>

    <template v-slot:item.quantity_in_progress="{ item }">
      <span :class="{ 'grey--text': !+item.quantity_in_progress }">
        {{
          getInventoryItemQuantity(
            item.quantity_in_progress,
            inventoryItem.material_unit,
            inventoryItem.unit_a_title,
            inventoryItem.unit_b_title,
            inventoryItem.unit_a_b_ratio,
          )
        }}
      </span>
    </template>

    <template v-slot:item.quantity_issued="{ item }">
      <span :class="{ 'grey--text': !+item.quantity_issued }">
        {{
          getInventoryItemQuantity(
            item.quantity_issued,
            inventoryItem.material_unit,
            inventoryItem.unit_a_title,
            inventoryItem.unit_b_title,
            inventoryItem.unit_a_b_ratio,
          )
        }}
      </span>
    </template>

    <template v-slot:item.quantityAvailable="{ item }">
      <span :class="{ 'grey--text': !+getQuantityAvailable(item) }">
        {{
          getInventoryItemQuantity(
            getQuantityAvailable(item),
            inventoryItem.material_unit,
            inventoryItem.unit_a_title,
            inventoryItem.unit_b_title,
            inventoryItem.unit_a_b_ratio,
          )
        }}
      </span>
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu :actions="getRowActions(item)" :item="item" />
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.status>
          <v-chip
            v-if="item.status"
            :color="orderPartStatuses[item.status].color"
            class="persist-opacity"
            label
            small
          >
            {{ orderPartStatuses[item.status].text }}
          </v-chip>
        </template>

        <template v-slot:item.warehouse_location_id>
          <BaseChipSelect
            :value="item.warehouse_location_id"
            :items="$store.getters.warehouseLocations"
            item-value="id"
            item-text="name"
            show-search
            @change="updateWarehouseLocation(item, $event)"
          />
        </template>

        <template v-slot:item.quantity_in_warehouse>
          <span :class="{ 'grey--text': !+item.quantity_in_warehouse }">
            {{
              getInventoryItemQuantity(
                item.quantity_in_warehouse,
                inventoryItem.material_unit,
                inventoryItem.unit_a_title,
                inventoryItem.unit_b_title,
                inventoryItem.unit_a_b_ratio,
              )
            }}
          </span>
        </template>

        <template v-slot:item.quantity_in_progress>
          <span :class="{ 'grey--text': !+item.quantity_in_progress }">
            {{
              getInventoryItemQuantity(
                item.quantity_in_progress,
                inventoryItem.material_unit,
                inventoryItem.unit_a_title,
                inventoryItem.unit_b_title,
                inventoryItem.unit_a_b_ratio,
              )
            }}
          </span>
        </template>

        <template v-slot:item.quantity_issued>
          <span :class="{ 'grey--text': !+item.quantity_issued }">
            {{
              getInventoryItemQuantity(
                item.quantity_issued,
                inventoryItem.material_unit,
                inventoryItem.unit_a_title,
                inventoryItem.unit_b_title,
                inventoryItem.unit_a_b_ratio,
              )
            }}
          </span>
        </template>

        <template v-slot:item.quantityAvailable>
          <span :class="{ 'grey--text': !+getQuantityAvailable(item) }">
            {{
              getInventoryItemQuantity(
                getQuantityAvailable(item),
                inventoryItem.material_unit,
                inventoryItem.unit_a_title,
                inventoryItem.unit_b_title,
                inventoryItem.unit_a_b_ratio,
              )
            }}
          </span>
        </template>
      </BaseExpandedTableRow>
    </template>

    <template v-slot:foot>
      <tfoot v-if="items.length && $vuetify.breakpoint.mdAndUp">
        <tr>
          <td
            v-for="header in headers"
            :key="header.value"
            :class="{ 'text-right': header.align === 'end' }"
            class="grey lighten-4"
          >
            <template v-if="header.value === 'order'"> {{ $t('in_total') }}: </template>
            <template v-else-if="header.value === 'quantity_in_warehouse'">
              {{
                getInventoryItemQuantity(
                  totalQuantityInWarehouse,
                  inventoryItem.material_unit,
                  inventoryItem.unit_a_title,
                  inventoryItem.unit_b_title,
                  inventoryItem.unit_a_b_ratio,
                )
              }}
            </template>
            <template v-else-if="header.value === 'quantity_in_progress'">
              {{
                getInventoryItemQuantity(
                  totalQuantityInProgress,
                  inventoryItem.material_unit,
                  inventoryItem.unit_a_title,
                  inventoryItem.unit_b_title,
                  inventoryItem.unit_a_b_ratio,
                )
              }}
            </template>
            <template v-else-if="header.value === 'quantity_issued'">
              {{
                getInventoryItemQuantity(
                  totalQuantityIssued,
                  inventoryItem.material_unit,
                  inventoryItem.unit_a_title,
                  inventoryItem.unit_b_title,
                  inventoryItem.unit_a_b_ratio,
                )
              }}
            </template>
            <template v-else-if="header.value === 'quantityAvailable'">
              {{
                getInventoryItemQuantity(
                  totalQuantityAvailable,
                  inventoryItem.material_unit,
                  inventoryItem.unit_a_title,
                  inventoryItem.unit_b_title,
                  inventoryItem.unit_a_b_ratio,
                )
              }}
            </template>
          </td>
        </tr>
      </tfoot>
    </template>
  </v-data-table>
</template>

<script>
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import tableMixin from '@/mixins/table-mixin';
import BaseChipSelect from '@/components/base/BaseChipSelect';
import orderPartService, { getOrderPartStatusMap } from '@/api/order-part-service';
import eventBus, { OPEN_SNACKBAR } from '@/util/event-bus';
import BaseActionMenu from '@/components/base/BaseActionMenu';
import { getInventoryItemQuantity } from '@/util/inventory-item-quantity';

export default {
  name: 'InventoryItemPartsTable',

  components: { BaseActionMenu, BaseChipSelect, BaseExpandedTableRow },

  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    inventoryItem: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    hideLocationField: {
      type: Boolean,
      default: false,
    },
    hideStatusField: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    orderPartStatuses() {
      return getOrderPartStatusMap();
    },

    totalQuantityInWarehouse() {
      return this.items.reduce((sum, item) => sum + +item.quantity_in_warehouse, 0);
    },

    totalQuantityInProgress() {
      return this.items.reduce((sum, item) => sum + +item.quantity_in_progress, 0);
    },

    totalQuantityIssued() {
      return this.items.reduce((sum, item) => sum + +item.quantity_issued, 0);
    },

    totalQuantityAvailable() {
      return this.items.reduce((sum, item) => sum + +this.getQuantityAvailable(item), 0);
    },

    headers() {
      const headers = [{ value: 'order', text: this.$t('order') }];

      if (!this.hideStatusField) {
        headers.push({ value: 'status', text: this.$t('status'), hidden: 'xsOnly' });
      }

      if (!this.hideLocationField) {
        headers.push({
          value: 'warehouse_location_id',
          text: this.$t('warehouse_location_alt'),
          hidden: 'xsOnly',
        });
      }

      headers.push({
        value: 'quantity_in_warehouse',
        text: this.$t('quantity_in_warehouse'),
        hidden: 'smAndDown',
        align: 'end',
      });

      headers.push({
        value: 'quantity_in_progress',
        text: this.$t('quantity_in_progress'),
        hidden: 'smAndDown',
        align: 'end',
      });

      headers.push({
        value: 'quantity_issued',
        text: this.$t('quantity_issued'),
        hidden: 'smAndDown',
        align: 'end',
      });

      headers.push({
        value: 'quantityAvailable',
        text: this.$t('quantity_available/used_in_manufacturing'),
        hidden: 'smAndDown',
        align: 'end',
      });

      if (!this.disableActions) {
        headers.push({ value: 'actions' });
      }

      return headers;
    },
  },

  methods: {
    getInventoryItemQuantity,

    getOrderUrl(part) {
      return `/orders?search=${part.order.order_no}&orderPartId=${part.id}`;
    },

    updateWarehouseLocation(part, newLocationId) {
      orderPartService
        .updateLocation({ ...part, warehouse_location_id: newLocationId })
        .then(() => {
          this.$set(part, 'warehouse_location_id', newLocationId);
          eventBus.$emit(OPEN_SNACKBAR, this.$t('order_part_updated'));
        });
    },

    getRowActions(item) {
      const dispatchAction = {
        callback: p => this.$emit('dispatch', p, this.inventoryItem),
        label: this.$t('dispatch'),
        icon: 'mdi-tanker-truck',
      };

      if (this.inventoryItem.type === 'bought') {
        if (+item.quantity_in_warehouse <= 0) {
          dispatchAction.disabled = true;
          dispatchAction.label = this.$t('nothing_to_dispatch');
        }
      } else {
        // manufactured
        if (!['completed', 'in_progress'].includes(item.status)) {
          dispatchAction.disabled = true;
          dispatchAction.label = this.$t('part_is_not_completed');
        }

        if (+item.quantity_in_warehouse <= +item.quantity_issued) {
          dispatchAction.disabled = true;
          dispatchAction.label = this.$t('nothing_to_dispatch');
        }
      }

      return [dispatchAction];
    },

    getQuantityAvailable(part) {
      return (+part.quantity_in_warehouse - +part.quantity_issued).toFixed(4);
    },
  },
};
</script>
