import http from './http';

const endpoint = 'api/documents';

const documentService = {
  model: 'document',

  create: document => http.post(`${endpoint}`, document),

  delete: document => http.delete(`${endpoint}/${document.id}`, document),
};

export default documentService;
