<template>
  <BaseLoadingOverlay :loading="loading" hide-spinner>
    <v-data-table
      :value="selectedItems"
      :expanded="tableMixin_expandedRows"
      :headers="tableMixin_displayedHeaders"
      :items="rows"
      :mobile-breakpoint="0"
      :loading="loading"
      disable-pagination
      disable-sort
      hide-default-footer
      @click:row="onRowClick"
      @dblclick:row="$emit('dblclick:row', lastClickedItem)"
    >
      <template v-slot:item.file_name="{ item, value }">
        <div class="d-flex align-center">
          <v-progress-circular
            v-if="loadingFileIdMap[item.id]"
            color="grey"
            size="24"
            width="3"
            indeterminate
          />
          <v-icon v-else>
            {{ `mdi-${item.is_folder ? 'folder' : 'file-document-outline'}` }}
          </v-icon>
          <div class="ml-3">
            {{ value }}
          </div>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <BaseActionMenu
          :actions="actions"
          :item="item"
          :loading="pendingDocumentActionsMap[item.id]"
          :max-buttons="3"
        />
      </template>
    </v-data-table>
  </BaseLoadingOverlay>
</template>

<script>
import BaseActionMenu from '../base/BaseActionMenu';
import tableMixin from '../../mixins/table-mixin';
import BaseLoadingOverlay from '@/components/base/BaseLoadingOverlay';

export default {
  name: 'DocumentLibraryTable',

  components: {
    BaseLoadingOverlay,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    rows: Array,
    loading: {
      type: Boolean,
      default: false,
    },
    loadingFileIdMap: {
      type: Object,
      default: () => ({}),
    },
    pendingDocumentActionsMap: {
      type: Object,
      default: () => ({}),
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      lastClickedItem: null,
      actions: [
        {
          callback: p => this.$emit('move', p),
          label: this.$t('move'),
          icon: 'mdi-folder-move-outline',
        },
        {
          callback: p => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: p => this.$emit('delete', p),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ],
    };
  },

  computed: {
    headers() {
      const headers = [
        {
          text: this.$t('name'),
          value: 'file_name',
        },
      ];
      if (!this.hideActions) {
        headers.push({
          value: 'actions',
          align: 'end',
        });
      }
      return headers;
    },
  },

  methods: {
    onRowClick(item) {
      this.lastClickedItem = item;
      this.tableMixin_onRowClick(item);
    },
  },
};
</script>
