<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col v-if="orderDefect.id || !formItem.order_id" cols="12">
            <BaseAutocomplete
              v-model="orderDefect.order_id"
              :search-function="orderSearchFunction"
              :initial-item="orderDefect.order"
              :error-messages="errors.order_id"
              :label="$t('order')"
              :key="orderDefect.id"
              item-text="order_no"
              @input="formMixin_clearErrors('order_id')"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="orderDefect.comment"
              :error-messages="errors['comment']"
              :label="formMixin_getRequiredFieldLabel($t('comment'))"
              auto-grow
              @blur="formMixin_clearErrors('comment')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!orderDefect.id" class="subtitle-2 ml-3"> * {{ $t('must_be_filled') }} </span>

        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import orderDefectService from '../../api/order-defect-service';
import BaseAutocomplete from '../base/BaseAutocomplete';
import orderService from '../../api/order-service';

export default {
  name: 'OrderDefectForm',

  components: { BaseAutocomplete },

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    formItem: Object,
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      orderDefect: {},
      orderSearchFunction: orderService.search,
    };
  },

  computed: {
    formTitle() {
      return this.$t(this.orderDefect.id ? 'edit_order_defect' : 'new_order_defect');
    },
  },

  methods: {
    onDialogOpen() {
      this.orderDefect = JSON.parse(JSON.stringify(this.formItem));
      this.errors = {};
    },

    onSubmit() {
      this.crudMixin_createOrUpdate(orderDefectService, this.orderDefect);
    },
  },
};
</script>
