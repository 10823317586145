<template>
  <BaseAutocomplete
    :value="value"
    :search-function="searchFunction"
    :get-by-id-function="getContactPersonById"
    :label="label"
    :initial-item="initialItem"
    :error-messages="errorMessages"
    item-text="name"
    appended-icon="mdi-plus"
    clearable
    use-item-slot
    no-filter
    @input="$emit('input', $event)"
    @click:append="openSuppliersPage"
    @update:initial-item="$emit('update:initial-item', $event)"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          <span>{{ item.name }}</span>
        </v-list-item-title>
        <v-list-item-subtitle
          v-if="item.contact"
          class="text-body-2"
          style="font-size: 12px !important"
        >
          {{ item.contact.name }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </BaseAutocomplete>
</template>

<script>
import BaseAutocomplete from '@/components/base/BaseAutocomplete';
import i18n from '@/i18n/i18n-config';
import contactPersonService from '@/api/contact-person-service';

export default {
  name: 'ContactPersonAutocomplete',

  components: { BaseAutocomplete },

  props: {
    value: {
      type: Number,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    initialItem: {
      type: Object,
      default: () => null,
    },
    label: {
      type: String,
      default: () => i18n.t('contact_person'),
    },
    suppliersOnly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    searchFunction() {
      if (this.suppliersOnly) {
        return this.searchSupplierContactPersons;
      }

      return this.searchClientContactPersons;
    },
  },

  methods: {
    getContactPersonById: contactPersonService.getById,
    searchClientContactPersons: contactPersonService.searchClients,
    searchSupplierContactPersons: contactPersonService.searchSuppliers,

    openSuppliersPage() {
      const route = this.$router.resolve({ name: 'suppliers' });
      window.open(`${window.location.origin}${route.href}`);
    },
  },
};
</script>

<style scoped></style>
