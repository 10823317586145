import http from './http';

const endpoint = 'api/inventory-item-groups';

const inventoryItemGroupService = {
  model: 'inventoryItemGroup',

  getPage: (page, params) => http.get(endpoint, { params: { ...params, page } }),

  getById: id => http.get(`${endpoint}/${id}`),

  create: inventoryItemGroup => http.post(endpoint, inventoryItemGroup),

  update: inventoryItemGroup =>
    http.put(`${endpoint}/${inventoryItemGroup.id}`, inventoryItemGroup),

  delete: inventoryItemGroup =>
    http.delete(`${endpoint}/${inventoryItemGroup.id}`, inventoryItemGroup),
};

export default inventoryItemGroupService;
