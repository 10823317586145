<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
        <BaseChipSelect
          :value="orderPart.status"
          :items="orderPartStatuses"
          chip-classes="ml-2"
          @change="$set(orderPart, 'status', $event)"
        />
        <BaseChipSelect
          :value="orderPart.warehouse_location_id"
          :items="$store.getters.warehouseLocations"
          :no-data-text="$t('location_unspecified')"
          :outlined="!orderPart.warehouse_location_id"
          chip-classes="ml-2 my-1"
          item-value="id"
          item-text="name"
          clearable
          show-search
          @change="orderPart.warehouse_location_id = $event"
        />
        <BaseChipDatepicker
          v-model="orderPart.deadline_at"
          :label="$t('deadline_at')"
          chip-classes="ml-2"
          clearable
        />
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col md="12" lg="4" class="d-flex align-center">
            <BaseAutocomplete
              v-model="orderPart.inventory_item_id"
              :initial-item="orderPart.inventory_item"
              :error-messages="errors.inventory_item_id"
              :label="$t('inventory_item')"
              :search-function="inventoryItemSearchFunction"
              :hide-selected="false"
              :appended-icon="!orderPart.inventory_item ? 'mdi-plus' : 'mdi-information'"
              :key="orderPart.id"
              item-text="title"
              use-item-slot
              use-selection-slot
              clearable
              @input="formMixin_clearErrors('inventory_item_id')"
              @click:append="onInventoryItemAppendClick"
              @update:initial-item="orderPart.inventory_item = $event"
            >
              <template v-slot:item="{ item }">
                <div style="width: 100%">
                  <div>
                    {{ item.title }}
                  </div>
                  <div class="grey--text text-body-2">
                    {{ $t('type') }}: {{ $t(`inventory_item_types.${item.type}`) }}.
                    {{ $t('quantity_available_short') }}: {{ +item.quantity_in_warehouse }}
                    {{ $t('quantity_in_progress') }}: {{ +item.quantity_in_progress }},
                  </div>
                </div>
              </template>

              <template v-slot:selection="{ item }">
                <div class="py-1">
                  <div>
                    {{ item.title }}
                  </div>
                  <div class="grey--text text-body-2">
                    {{ $t('type') }}: {{ $t(`inventory_item_types.${item.type}`) }}.
                    {{ $t('quantity_available_short') }}: {{ +item.quantity_in_warehouse }}
                    {{ $t('quantity_in_progress') }}: {{ +item.quantity_in_progress }},
                  </div>
                </div>
              </template>
            </BaseAutocomplete>
          </v-col>

          <v-col cols="12" md="6" lg="2" class="d-flex align-center">
            <v-text-field
              v-if="orderPart.inventory_item"
              v-model.number="orderPart.quantity_in_warehouse"
              :error-messages="errors['quantity_in_warehouse']"
              :label="formMixin_getRequiredFieldLabel($t('quantity_in_warehouse'))"
              type="number"
              disabled
              @blur="formMixin_clearErrors('quantity_in_warehouse')"
            />
          </v-col>

          <v-col v-if="!isBought" cols="12" md="6" lg="2" class="d-flex align-center">
            <v-text-field
              v-if="orderPart.inventory_item"
              v-model.number="orderPart.quantity_in_progress"
              :error-messages="errors['quantity_in_progress']"
              :label="formMixin_getRequiredFieldLabel($t('quantity_in_progress'))"
              disabled
              type="number"
              @blur="formMixin_clearErrors('quantity_in_progress')"
            />
          </v-col>

          <v-col cols="12" md="6" lg="2" class="d-flex align-center">
            <v-text-field
              v-if="orderPart.inventory_item"
              v-model.number="orderPart.quantity_issued"
              :error-messages="errors['quantity_issued']"
              :label="formMixin_getRequiredFieldLabel($t('quantity_issued'))"
              disabled
              type="number"
              @blur="formMixin_clearErrors('quantity_issued')"
            />
          </v-col>

          <v-col cols="12" md="6" lg="2" class="d-flex align-center">
            <template v-if="orderPart.inventory_item">
              <v-text-field
                :value="(+orderPart.quantity_in_warehouse - +orderPart.quantity_issued).toFixed(4)"
                :error-messages="errors['quantity_available']"
                :label="$t('quantity_available')"
                type="number"
                disabled
                @blur="formMixin_clearErrors('quantity_available')"
              />

              <v-tooltip
                v-if="orderPart.id"
                content-class="tooltip-in-dialog"
                transition="none"
                bottom
              >
                <template #activator="{ on }">
                  <div v-on="on" class="ml-3">
                    <v-icon
                      :disabled="dispatchTooltipText !== $t('dispatch')"
                      @click="dispatchOrderPart"
                      >mdi-tanker-truck</v-icon
                    >
                  </div>
                </template>
                <div>
                  {{ dispatchTooltipText }}
                </div>
              </v-tooltip>
            </template>
          </v-col>
        </v-row>

        <BaseLoadingOverlay :loading="fetchingOldOrderPart">
          <OrderPartDocumentPicker :documents="orderPart.document_library_items" editable />

          <v-row v-if="!isBought" dense>
            <v-col cols="12" class="d-flex justify-space-between align-center">
              <h2 class="subtitle-2">
                {{ $t('processes') }}
                <template v-if="totalEmployeeSalaryAmount"
                  >({{ $t('total_employee_salary_amount') }}:
                  {{ totalEmployeeSalaryAmount | currency }})</template
                >
              </h2>

              <ProcessSelectMenu
                :process-groups="processGroups"
                left
                check-machinery-availability-on-select
                @select="addProcess"
              />
            </v-col>

            <v-col cols="12">
              <OrderPartProcessTable
                v-if="orderPart.processes.length"
                v-model="orderPart.processes"
                :hide-process-statuses="!orderPart.id"
                :order-part="orderPart"
                :order="order"
                item-key="key"
                indicate-required-fields
                can-edit
                @change="updateAdditionalCostSum(additionalCostPercent)"
                @remove="removeProcess"
                @reorder="reorderProcesses"
              />
            </v-col>

            <v-col cols="12">
              <BaseFormErrors :error-messages="errors.processes" />
            </v-col>
          </v-row>

          <OrderPartFormMaterials
            v-if="!isBought"
            :part="orderPart"
            :order="order"
            :key="openCount"
            class="mb-3"
          />

          <v-row v-if="!isBought" class="mt-8" dense>
            <v-col cols="12" sm="6" md="3" lg="2">
              <v-text-field
                v-model.number="orderPart.quantity"
                :error-messages="errors['quantity']"
                :label="$t('product_quantity')"
                type="number"
                @blur="formMixin_clearErrors('quantity')"
                @input="updateTotalManufacturedItemPrice(orderPart.material_sell_price, $event)"
              />
            </v-col>

            <v-col cols="12" sm="6" md="3" lg="2">
              <v-text-field
                v-model.number="orderPart.inventory_item_length_in_mm_param"
                :error-messages="errors['inventory_item_length_in_mm_param']"
                :label="$t('inventory_item_length_in_mm_param')"
                type="number"
                @blur="formMixin_clearErrors('inventory_item_length_in_mm_param')"
              />
            </v-col>

            <v-col cols="12" sm="6" md="3" lg="2">
              <v-text-field
                v-model.number="orderPart.material_sell_price"
                :error-messages="errors['material_sell_price']"
                :label="$t('material_sell_price')"
                type="number"
                @blur="formMixin_clearErrors('material_sell_price')"
                @input="updateTotalManufacturedItemPrice($event, orderPart.quantity)"
              />
            </v-col>

            <v-col cols="12" sm="6" md="3" lg="2">
              <v-text-field
                v-model.number="totalManufacturedItemPrice"
                :label="$t('total_sell_price')"
                type="number"
                @input="updateMaterialSellPrice($event, orderPart.quantity)"
              />
            </v-col>

            <v-col cols="12" sm="6" md="3" lg="2">
              <v-text-field
                v-model.number="additionalCostPercent"
                :label="$t('additional_cost_percent')"
                type="number"
                @input="updateAdditionalCostSum"
              />
            </v-col>

            <v-col cols="12" sm="6" md="3" lg="2">
              <v-text-field
                v-model="orderPart.additional_cost_sum"
                :error-messages="errors['additional_cost_sum']"
                :label="$t('additional_cost_sum')"
                type="number"
                @blur="formMixin_clearErrors('additional_cost_sum')"
              />
            </v-col>
          </v-row>

          <v-row
            v-if="!isBought"
            :style="`min-height: ${$vuetify.breakpoint.mdAndUp ? '80px' : 'unset'}`"
            dense
          >
            <v-col cols="12" sm="6" md="2"> </v-col>

            <v-col cols="12" sm="6" md="3">
              <BaseVerticalDataCell
                v-if="+orderPart.additional_cost_sum"
                :label="$t('material_and_manufacturing_price_and_additional_cost_per_unit')"
                :value="materialAndManufacturingPriceAndAdditionalCostPerUnit"
              />
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <BaseVerticalDataCell
                v-if="+orderPart.additional_cost_sum"
                :label="$t('material_and_manufacturing_price_and_additional_cost')"
                :value="materialAndManufacturingAndAdditionalCost"
              />
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <BaseVerticalDataCell
                v-if="+materialAndManufacturingPrice"
                :label="$t('material_and_manufacturing_price_per_unit')"
                :value="materialAndManufacturingPricePerUnit"
              />
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <BaseVerticalDataCell
                v-if="+materialAndManufacturingPrice"
                :label="$t('material_and_manufacturing_price')"
                :value="materialAndManufacturingPrice"
              />
            </v-col>
          </v-row>
        </BaseLoadingOverlay>
      </v-card-text>

      <v-card-actions>
        <span v-if="!orderPart.id" class="subtitle-2 ml-3"> * {{ $t('must_be_filled') }} </span>
        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="isInventoryItemFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <InventoryItemForm
        :dialog="isInventoryItemFormOpen"
        :form-item="newInventoryItem"
        @cancel="isInventoryItemFormOpen = false"
        @create="inventoryItemCreated"
      />
    </v-dialog>

    <InventoryItemDetails
      v-if="dialog"
      :is-open="isInventoryDetailsModalOpen"
      :item="orderPart.inventory_item"
      @close="isInventoryDetailsModalOpen = false"
      @fetch:order-part="fetchOrderPart"
    />

    <v-dialog
      v-model="isInventoryItemDispatchFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <InventoryItemDispatchForm
        :dialog="isInventoryItemDispatchFormOpen"
        :order-part="orderPart"
        @cancel="isInventoryItemDispatchFormOpen = false"
        @create="setOrderPartDispatchedAmount"
      />
    </v-dialog>
  </v-form>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import BaseAutocomplete from '../base/BaseAutocomplete';
import orderPartService, {
  ORDER_PART_DELIVERED_BY_TYPES,
  ORDER_PART_STATUSES,
} from '../../api/order-part-service';
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import ProcessSelectMenu from '../ProcessSelectMenu';
import OrderPartProcessTable from '../tables/OrderPartProcessTable';
import BaseFormErrors from '../base/BaseFormErrors';
import { round } from '@/util/numbers';
import inventoryItemService, { getDefaultInventoryItem } from '@/api/inventory-item-service';
import { INVENTORY_ITEM_PURCHASE_STATUSES } from '@/api/inventory-item-purchase-service';
import InventoryItemForm from '@/components/forms/InventoryItemForm';
import InventoryItemDetails from '@/components/InventoryItemDetails';
import InventoryItemDispatchForm from '@/components/forms/InventoryItemDispatchForm';
import BaseLoadingOverlay from '@/components/base/BaseLoadingOverlay';
import OrderPartDocumentPicker from '@/components/OrderPartDocumentPicker';
import OrderPartFormMaterials from '@/components/OrderPartFormMaterials';
import BaseChipSelect from '@/components/base/BaseChipSelect';
import { getInventoryItemQuantityForBackend } from '@/util/inventory-item-quantity';
import BaseVerticalDataCell from '@/components/base/BaseVerticalDataCell';
import BaseChipDatepicker from '@/components/base/BaseChipDatepicker';
import { addSeconds, format, isValid } from 'date-fns';

export default {
  name: 'OrderPartForm',

  components: {
    BaseChipDatepicker,
    BaseVerticalDataCell,
    BaseChipSelect,
    OrderPartFormMaterials,
    OrderPartDocumentPicker,
    BaseLoadingOverlay,
    InventoryItemDispatchForm,
    InventoryItemDetails,
    InventoryItemForm,
    BaseFormErrors,
    OrderPartProcessTable,
    ProcessSelectMenu,
    BaseAutocomplete,
  },

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    formItem: Object,
    processGroups: {
      type: Array,
      required: true,
    },
    order: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      orderPart: {},
      processArray: [],
      orderPartStatuses: ORDER_PART_STATUSES,
      orderPartMaterialStatuses: INVENTORY_ITEM_PURCHASE_STATUSES,
      deliveredByTypes: ORDER_PART_DELIVERED_BY_TYPES,

      totalManufacturedItemPrice: '',
      inventoryItemSearchFunction: inventoryItemService.search,

      newInventoryItem: getDefaultInventoryItem(),
      isInventoryItemFormOpen: false,

      isInventoryDetailsModalOpen: false,

      isInventoryItemDispatchFormOpen: false,
      fetchingOldOrderPart: false,
      openCount: 0,
      additionalCostPercent: 0,
    };
  },

  computed: {
    formTitle() {
      if (this.orderPart.id) {
        return this.$t('edit_order_part').replace(
          '{0}',
          `${this.order?.order_no}.${this.orderPart.order_part_no}`,
        );
      }
      return this.$t('new_order_part');
    },

    isBought() {
      return this.orderPart.inventory_item?.type === 'bought';
    },

    dispatchTooltipText() {
      if (!['completed', 'in_progress'].includes(this.orderPart.status)) {
        return this.$t('part_is_not_completed');
      }

      if (+this.orderPart.quantity_in_warehouse <= +this.orderPart.quantity_issued) {
        return this.$t('nothing_to_dispatch');
      }

      return this.$t('dispatch');
    },

    materialAndManufacturingPricePerUnit() {
      let price = 0;
      if (+this.totalEmployeeSalaryAmount) {
        price += +this.totalEmployeeSalaryAmount;
        if (+this.orderPart.quantity) {
          price /= this.orderPart.quantity;
        }
      }
      for (let i = 0; i < this.orderPart.materials?.length; i++) {
        const materialPricePerQuantityUnit = +this.orderPart.materials[i]
          .material_price_per_quantity_unit;
        if (materialPricePerQuantityUnit) {
          price += materialPricePerQuantityUnit;
        }
      }
      if (!price) {
        return '';
      }
      return round(price, 4);
    },

    materialAndManufacturingPrice() {
      return round(this.materialAndManufacturingPricePerUnit * this.orderPart.quantity);
    },

    materialAndManufacturingAndAdditionalCost() {
      if (!+this.materialAndManufacturingPricePerUnit) {
        return '';
      }
      let price = this.materialAndManufacturingPricePerUnit * (+this.orderPart.quantity || 1);
      if (+this.orderPart.additional_cost_sum) {
        price += +this.orderPart.additional_cost_sum;
      }
      if (!price) {
        return '';
      }
      return round(price, 4);
    },

    materialAndManufacturingPriceAndAdditionalCostPerUnit() {
      const quantity = +this.orderPart.quantity || 1;
      return round(this.materialAndManufacturingAndAdditionalCost / quantity);
    },

    totalEmployeeSalaryAmount() {
      let totalEmployeeSalaryAmount = 0;
      for (let i = 0; i < this.orderPart.processes.length; i++) {
        const p = this.orderPart.processes[i];
        if (p.employee_salary_amount_per_unit) {
          if (p.employee_salary_calculation_param === 'pcs' && p.planned_quantity) {
            totalEmployeeSalaryAmount += +p.planned_quantity * +p.employee_salary_amount_per_unit;
          } else if (
            p.employee_salary_calculation_param === 'minutes' &&
            p.planned_quantity &&
            p.estimated_duration_in_minutes
          ) {
            totalEmployeeSalaryAmount +=
              (+p.estimated_duration_in_minutes / 60) * // estimated_duration_in_minutes was changed to seconds, field name was kept the same
              +p.planned_quantity * // estimated_duration_in_minutes is for a single unit
              +p.employee_salary_amount_per_unit;
          }
        }
      }
      return totalEmployeeSalaryAmount;
    },
  },

  methods: {
    fetchOrderPart(id) {
      if (this.orderPart.id) {
        // We only want to set fields with old order part data (of selected inventory item) data
        // if a new order part is being created
        return;
      }
      this.fetchingOldOrderPart = true;
      orderPartService
        .getById(id)
        .then(res => {
          this.copyOldOrderPartData(res.data);
        })
        .finally(() => {
          this.fetchingOldOrderPart = false;
        });
    },

    copyOldOrderPartData(oldOrderPart) {
      const newOrderPart = JSON.parse(JSON.stringify(oldOrderPart));
      newOrderPart.processes = newOrderPart.processes.map(process => {
        const p = { ...process };
        delete p.id;
        p.status = 'not_started';
        p.quantity = 0;
        p.estimated_beginning_at = '';
        p.key = uuidv4();
        return p;
      });

      delete newOrderPart.id;
      newOrderPart.deadline_at = '';
      newOrderPart.status = 'not_started';
      newOrderPart.order_id = this.orderPart.order_id;

      this.orderPart = newOrderPart;
      this.updateTotalManufacturedItemPrice(
        this.orderPart.material_sell_price,
        this.orderPart.quantity,
      );
    },

    addProcess(process, estimatedStartAt = null) {
      let estimatedStartAtDate = new Date(estimatedStartAt);
      if (estimatedStartAt && isValid(estimatedStartAtDate)) {
        // offset estimatedStartAt by the duration of already added, but not yet saved processes
        let totalUnsavedProcessesDurationInSeconds = 0;
        for (let i = 0; i < this.orderPart.processes.length; i++) {
          // estimated_duration_in_minutes is actually in seconds. Was changed, field name was kept the same
          const { id, planned_quantity, estimated_duration_in_minutes } = this.orderPart.processes[
            i
          ];
          if (!id) {
            // processes with ID have been saved, so machinery business dates are already adjusted for it
            totalUnsavedProcessesDurationInSeconds +=
              estimated_duration_in_minutes * planned_quantity || 0;
          }
        }
        estimatedStartAtDate = addSeconds(
          estimatedStartAtDate,
          totalUnsavedProcessesDurationInSeconds,
        );
        // eslint-disable-next-line no-param-reassign
        estimatedStartAt = format(estimatedStartAtDate, 'yyyy-MM-dd HH:mm');
      }

      this.orderPart.processes.push({
        process,
        quantity: 0,
        planned_quantity: this.orderPart.quantity || 0,
        status: 'not_started',
        key: uuidv4(),
        process_id: process.id,
        employee_salary_calculation_param: 'pcs',
        estimated_start_at: estimatedStartAt,
      });
    },

    removeProcess(process) {
      for (let i = 0; i < this.orderPart.processes.length; i++) {
        if (this.orderPart.processes[i].key === process.key) {
          this.orderPart.processes.splice(i, 1);
          break;
        }
      }
    },

    reorderProcesses(newIndex, oldIndex) {
      const selectedProcess = this.orderPart.processes.splice(oldIndex, 1)[0];
      if (selectedProcess) {
        this.orderPart.processes.splice(newIndex, 0, selectedProcess);
      }
    },

    updateTotalManufacturedItemPrice(unitPrice, quantity) {
      if (!+unitPrice || !+quantity) {
        return;
      }
      this.totalManufacturedItemPrice = round(unitPrice * quantity, 4);
    },

    updateMaterialSellPrice(totalPrice, quantity) {
      if (!+totalPrice || !+quantity) {
        return;
      }

      this.$set(this.orderPart, 'material_sell_price', round(totalPrice / quantity, 4));
    },

    onDialogOpen() {
      this.openCount += 1; // to make OrderPartFormMaterials rerender when form is opened
      this.orderPart = JSON.parse(JSON.stringify(this.formItem));
      this.totalManufacturedItemPrice = null;
      this.updateTotalManufacturedItemPrice(
        this.orderPart.material_sell_price,
        this.orderPart.quantity,
      );
      // Because table rows need a unique key, and I can't use id for newly added processes
      this.orderPart.processes = this.orderPart.processes.map(p => ({ ...p, key: p.id }));
      this.updateAdditionalCostPercent(this.orderPart.additional_cost_sum);
      this.errors = {};
    },

    onInventoryItemAppendClick() {
      if (this.orderPart.inventory_item) {
        // if an inventory item is selected - append icon is 'information'
        // clicking on it should open a details dialog
        this.isInventoryDetailsModalOpen = true;
      } else {
        // otherwise the icon is a 'plus'
        // open a form to so the user can create a new inventory item
        this.isInventoryItemFormOpen = true;
      }
    },

    inventoryItemCreated(inventoryItem) {
      this.formMixin_setAutocompleteValue(inventoryItem, this.orderPart, 'inventory_item');
      this.newInventoryItem = getDefaultInventoryItem();
    },

    async onSubmit() {
      if (!this.orderPart.warehouse_location_id) {
        this.orderPart.warehouse_location_id = null;
      }
      const payload = JSON.parse(JSON.stringify(this.orderPart));
      for (let i = 0; i < payload.materials?.length; i++) {
        const { materialQuantity, material_unit } = payload.materials[i];
        const { unit_a_b_ratio } = payload.materials[i].material;
        payload.materials[i].material_quantity = getInventoryItemQuantityForBackend(
          materialQuantity,
          material_unit,
          unit_a_b_ratio,
        );
      }
      const res = await this.crudMixin_createOrUpdate(orderPartService, payload);
      if (res?.status === 422) {
        this.formMixin_collectMultiSelectErrors('processes');
      }
    },

    dispatchOrderPart() {
      this.isInventoryItemDispatchFormOpen = true;
    },

    setOrderPartDispatchedAmount(dispatch) {
      this.$set(
        this.orderPart,
        'quantity_issued',
        +this.orderPart.quantity_issued + +dispatch.quantity,
      );
    },

    updateAdditionalCostSum(additionalCostPercent) {
      const sum = round((+additionalCostPercent * +this.totalEmployeeSalaryAmount) / 100);
      this.$set(this.orderPart, 'additional_cost_sum', sum);
    },

    updateAdditionalCostPercent(additionalCostSum) {
      this.additionalCostPercent =
        round((+additionalCostSum * 100) / +this.totalEmployeeSalaryAmount) || '';
    },
  },
};
</script>
