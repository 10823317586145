<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="person.name"
              :error-messages="errors[`persons.${newPersonIndex}.name`]"
              :label="formMixin_getRequiredFieldLabel($t('name'))"
              @input="formMixin_clearErrors(`persons.${newPersonIndex}.name`)"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="person.email"
              :error-messages="errors[`persons.${newPersonIndex}.email`]"
              :label="$t('email')"
              @input="formMixin_clearErrors(errors[`persons.${newPersonIndex}.email`])"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="person.phone"
              :error-messages="errors[`persons.${newPersonIndex}.phone`]"
              :label="$t('phone')"
              @input="formMixin_clearErrors(`persons.${newPersonIndex}.phone`)"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="person.comment"
              :error-messages="errors[`persons.${newPersonIndex}.comment`]"
              :label="$t('comment')"
              rows="1"
              auto-grow
              @input="formMixin_clearErrors(`persons.${newPersonIndex}.comment`)"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!person.id" class="subtitle-2 ml-3"> * {{ $t('must_be_filled') }} </span>

        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          color="primary"
          type="submit"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import contactService from '../../api/contact-service';

export default {
  // currently supports creating only due to using contacts update endpoint

  name: 'ContactPersonForm',

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    contact: {
      // for which contact is the new person being created
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      person: {},
    };
  },

  computed: {
    formTitle() {
      return this.$t('new_representative');
    },

    newPersonIndex() {
      return this.contact.persons?.length || 0;
    },
  },

  methods: {
    onDialogOpen() {
      this.person = {};
      this.errors = {};
    },

    onSubmit() {
      if (!this.person.name) {
        this.$set(this.errors, `persons.${this.newPersonIndex}.name`, [
          this.$t('name_is_required'),
        ]);
        return;
      }

      const payload = {
        ...this.contact,
        persons: [],
      };
      if (this.contact.persons) {
        payload.persons = [...this.contact.persons];
      }
      payload.persons.push(this.person);

      // using contacts update because no BE routes were created for managing contact persons on their own
      this.crudMixin_update(
        contactService.update,
        'contact',
        payload,
        this.$t('representative_created'),
      );
    },
  },
};
</script>
