<template>
  <div class="mb-3">
    <div class="mb-3 d-flex justify-space-between align-center">
      <div>
        <h3 v-if="defects.length" class="subtitle-2">
          {{ $t('order_defects') }}
        </h3>
      </div>
      <v-btn
        color="primary"
        @click="$emit('new-item')"
      >
        <v-icon left>mdi-select-off</v-icon>
        {{ $t('create_order_defect') }}
      </v-btn>
    </div>

    <div
      v-for="defect in defects"
      :key="defect.id"
      class="d-flex justify-space-between"
    >
      <p style="white-space: pre-wrap">{{ defect.comment }}</p>
      <div>
        <BaseActionMenu
          :actions="actions"
          :item="defect"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseActionMenu from '../base/BaseActionMenu';

export default {
  name: 'OrderDefectList',

  components: { BaseActionMenu },

  props: {
    defects: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      actions: [
        {
          callback: p => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: p => this.$emit('delete', p),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
