/* eslint-disable import/prefer-default-export */
import { round } from '@/util/numbers';

export function getInventoryItemQuantity(
  quantity,
  materialUnit,
  unitATitle,
  unitBTitle,
  unitABRatio,
) {
  if (!+quantity) {
    return '0';
  }

  let transformedQuantity = quantity;
  if (materialUnit && +unitABRatio && materialUnit === 'b') {
    transformedQuantity = +quantity / +unitABRatio;
  }

  transformedQuantity = round(transformedQuantity, 4).toFixed(4);
  if (materialUnit) {
    transformedQuantity += ` ${materialUnit === 'a' ? unitATitle : unitBTitle}`;
  }
  return transformedQuantity;
}

export function getInventoryItemQuantityNumber(quantity, materialUnit, unitABRatio) {
  if (!+quantity) {
    return '0';
  }

  let transformedQuantity = quantity;
  if (materialUnit && +unitABRatio && materialUnit === 'b') {
    transformedQuantity = +quantity / +unitABRatio;
  }

  transformedQuantity = round(transformedQuantity, 4);
  return transformedQuantity;
}

// backend stores quantity in unit A
// so if unit B is the selected materialUnit quantity needs to be transformed
export function getInventoryItemQuantityForBackend(quantity, materialUnit, unitABRatio) {
  if (!+quantity) {
    return '0';
  }

  let transformedQuantity = quantity;
  if (materialUnit && +unitABRatio && materialUnit === 'b') {
    transformedQuantity = +quantity * +unitABRatio;
  }

  transformedQuantity = round(transformedQuantity, 4);
  return transformedQuantity;
}
