var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"expanded":_vm.tableMixin_expandedRows,"footer-props":{
    'items-per-page-options': [+_vm.pagination.rowsPerPage],
  },"headers":_vm.tableMixin_displayedHeaders,"items":_vm.rows,"loading":_vm.loading,"mobile-breakpoint":0,"page":_vm.pagination.page,"server-items-length":_vm.pagination.totalItems,"disable-pagination":_vm.disablePagination,"hide-default-footer":_vm.disablePagination,"disable-sort":""},on:{"update:page":_vm.tableMixin_changePage},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"link",on:{"click":function($event){return _vm.tableMixin_expandRow(item)}}},[_vm._v(_vm._s(item.date))])]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.getRowActions(item),"item":item}})]}},{key:"expanded-item",fn:function(ref){
  var headers = ref.headers;
  var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('BaseFileDropZone',{staticClass:"pt-2 pb-8 my-2",attrs:{"current-files":item.documents,"accept":_vm.acceptedFileFormats,"upload-function":function (files) { return _vm.uploadFiles(item, files); },"delete-function":function (file) { return _vm.deleteFile(item, file); },"multiple":""}})]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }