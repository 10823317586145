<template>
  <v-dialog
    :value="isOpen"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    persistent
    scrollable
    @input="onDialogInput"
  >
    <v-form @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>
          {{ formTitle }}
        </v-card-title>

        <v-card-text>
          <v-row :dense="!workerView">
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="measurement.name"
                :disabled="workerView"
                :error-messages="errors['name']"
                :label="formMixin_getRequiredFieldLabel($t('title'))"
                @blur="formMixin_clearErrors('name')"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="measurement.repetitions"
                :disabled="workerView"
                :error-messages="errors['repetitions']"
                :label="formMixin_getRequiredFieldLabel($t('repetitions'))"
                type="number"
                @input="setValuesArray"
                @blur="formMixin_clearErrors('repetitions')"
              />
            </v-col>

            <template v-if="measurement.values && measurement.values.length > 0">
              <v-col
                v-for="(val, index) in measurement.values"
                :key="index"
                cols="6" sm="4" md="3"
              >
                <v-text-field
                  v-model="measurement.values[index]"
                  :error-messages="errors[`values.${index}`]"
                  :label="`${index + 1} ${$t('measurement').toLowerCase()}`"
                  type="number"
                  @blur="formMixin_clearErrors(`values.${index}`)"
                />
              </v-col>
            </template>
          </v-row>
        </v-card-text>

        <v-card-actions v-if="!workerView">
          <span v-if="!measurement.id" class="subtitle-2 ml-3">
            * {{ $t('must_be_filled') }}
          </span>

          <v-spacer/>

          <v-btn
            color="primary"
            text
            @click.native="onDialogInput(false)"
          >
            {{ $t('cancel') }}
          </v-btn>

          <v-btn
            :disabled="isRequestPending"
            :loading="isRequestPending"
            type="submit"
            color="primary"
            text
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-row dense>
            <v-col cols="12" sm="6" class="px-4 pb-3 pb-sm-1">
              <v-btn
                color="primary"
                block
                x-large
                outlined
                @click.native="onDialogInput(false)"
              >
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="px-4 pb-3 pb-sm-1">
              <v-btn
                :disabled="isRequestPending"
                :loading="isRequestPending"
                type="submit"
                color="primary"
                block
                x-large
              >
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import crudMixin from '../../mixins/crud-mixin';
import formMixin from '../../mixins/form-mixin';
import measurementService from '../../api/measurement.service';
import {
  CLOSE_MEASUREMENT_FORM,
  STORE_MEASUREMENT,
  UPDATE_MEASUREMENT,
} from '../../store/mutation-types';

export default {
  name: 'MeasurementsFormDialog',

  mixins: [crudMixin, formMixin],

  props: {
    workerView: Boolean,
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      measurement: { values: [] },
      timerId: null,
    };
  },

  computed: {
    ...mapState({
      isOpen: state => state.measurements.isFormOpen,
      formItem: state => state.measurements.formItem,
    }),

    formTitle() {
      if (this.workerView) {
        return this.$t('measurement_filling');
      }
      return this.$t(this.measurement.id ? 'edit_measurement' : 'new_measurement');
    },
  },

  watch: {
    isOpen(newValue) {
      if (!newValue) {
        return;
      }
      this.measurement = JSON.parse(JSON.stringify(this.formItem));
      const values = [];
      for (let i = 0; i < +this.measurement.repetitions; i++) {
        values.push(this.measurement.values[i] ? this.measurement.values[i] : 0);
      }
      this.$set(this.measurement, 'values', values);
      this.errors = {};
    },
  },

  methods: {
    onDialogInput(val) {
      if (!val) {
        this.$store.commit(CLOSE_MEASUREMENT_FORM);
      }
    },

    onSubmit() {
      if (!this.workerView) {
        this.crudMixin_createOrUpdate(measurementService, this.measurement).then((res) => {
          if (res.status === 201) {
            this.$store.commit(STORE_MEASUREMENT, res.data);
            this.$store.commit(CLOSE_MEASUREMENT_FORM);
          }
          if (res.status === 200) {
            this.$store.commit(UPDATE_MEASUREMENT, res.data);
            this.$store.commit(CLOSE_MEASUREMENT_FORM);
          }
        });
      } else {
        this.crudMixin_update(
          measurementService.patch, measurementService.model, this.measurement,
        ).then((res) => {
          if (res.status === 200) {
            this.$store.commit(UPDATE_MEASUREMENT, res.data);
            this.$store.commit(CLOSE_MEASUREMENT_FORM);
          }
        });
      }
    },

    setValuesArray(count) {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        const values = [];
        for (let i = 0; i < +count; i++) {
          values.push(this.measurement.values[i] ? this.measurement.values[i] : 0);
        }
        this.$set(this.measurement, 'values', values);
      }, 500);
    },
  },
};
</script>
