<template>
  <div class="d-flex flex-wrap" style="max-width: 128px;">
    <v-tooltip v-for="m in materials" :key="m.id" transition="none" bottom>
      <template v-slot:activator="{ on }">
        <span
          v-on="on"
          :style="`background-color: ${orderPartMaterialColors[m.material_status]}`"
          class="bubble"
        />
      </template>
      <span style="white-space: pre-line">
        {{ m.title }}<br />{{ $t(m.material_status) }}
        <!--        <br />{{-->
        <!--          m.quantity_in_warehouse >= 0 ? $t('amount_in_warehouse') : $t('missing')-->
        <!--        }}:-->
        <!--        {{-->
        <!--          Math.abs(-->
        <!--            getInventoryItemQuantityNumber(-->
        <!--              m.quantity_in_warehouse,-->
        <!--              m.material_unit,-->
        <!--              m.unit_a_b_ratio,-->
        <!--            ),-->
        <!--          )-->
        <!--        }}-->
      </span>
    </v-tooltip>
  </div>
</template>

<script>
// import { getInventoryItemQuantityNumber } from '@/util/inventory-item-quantity';
import { INVENTORY_ITEM_PURCHASE_STATUSES } from '@/api/inventory-item-purchase-service';

export default {
  name: 'MaterialBubbles',

  props: {
    materials: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    orderPartMaterialColors() {
      const colorMap = {};
      for (let i = 0; i < INVENTORY_ITEM_PURCHASE_STATUSES.length; i++) {
        colorMap[INVENTORY_ITEM_PURCHASE_STATUSES[i].value] =
          INVENTORY_ITEM_PURCHASE_STATUSES[i].hexColor;
      }
      return colorMap;
    },
  },

  methods: {
    // getInventoryItemQuantityNumber,
  },
};
</script>

<style scoped></style>
