<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :footer-props="{
      'items-per-page-options': [+pagination.rowsPerPage],
    }"
    :headers="tableMixin_displayedHeaders"
    :items="rows"
    :loading="loading"
    :mobile-breakpoint="0"
    :page="pagination.page"
    :server-items-length="pagination.totalItems"
    :disable-pagination="disablePagination"
    :hide-default-footer="disablePagination"
    disable-sort
    @update:page="tableMixin_changePage"
  >
    <template v-slot:item.date="{ item }">
      <span class="link" @click="tableMixin_expandRow(item)">{{ item.date }}</span>
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu :actions="getRowActions(item)" :item="item" />
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:append>
          <BaseFileDropZone
            :current-files="item.documents"
            :accept="acceptedFileFormats"
            :upload-function="files => uploadFiles(item, files)"
            :delete-function="file => deleteFile(item, file)"
            class="pt-2 pb-8 my-2"
            multiple
          />
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import tableMixin from '@/mixins/table-mixin';
import BaseActionMenu from '@/components/base/BaseActionMenu';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import crudMixin from '@/mixins/crud-mixin';
import BaseFileDropZone from '@/components/base/BaseFileDropZone';
import documentService from '@/api/document-service';
import materialDefectService from '@/api/material-defect-service';

export default {
  name: 'MaterialDefectTable',

  components: {
    BaseFileDropZone,
    BaseExpandedTableRow,
    BaseActionMenu,
  },

  mixins: [tableMixin, crudMixin],

  props: {
    rows: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    acceptedFileFormats: {
      type: String,
      default: '*',
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('date'),
          value: 'date',
        },
        {
          text: this.$t('comments'),
          value: 'comments',
          hidden: 'xsOnly',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ];
    },
  },

  methods: {
    getRowActions() {
      return [
        {
          callback: p => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: p => this.$emit('delete', p),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ];
    },

    async uploadFiles(item, files) {
      const payload = {
        ...item,
        documents: files,
      };
      const res = await materialDefectService.update(payload);
      this.$set(item, 'documents', res.data.documents);
    },

    async deleteFile(item, file) {
      await documentService.delete(file);
      this.$set(
        item,
        'documents',
        item.documents.filter(doc => doc.id !== file.id),
      );
    },
  },
};
</script>
