<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6" md="4">
            <BaseDatepickerInput
              v-model="materialDefect.date"
              :error-messages="errors['date']"
              :label="formMixin_getRequiredFieldLabel($t('date'))"
              :clearable="false"
              @input="formMixin_clearErrors('date')"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="materialDefect.comments"
              :error-messages="errors['comments']"
              :label="$t('comments')"
              rows="1"
              autofocus
              auto-grow
              @input="formMixin_clearErrors('comments')"
            />
          </v-col>

          <v-col cols="12">
            <v-file-input
              v-model="materialDefect.documents"
              :error-messages="documentErrors"
              :label="$t('attach_files')"
              :accept="acceptedFileFormats"
              multiple
              chips
              small-chips
              @input="formMixin_clearErrors('documents')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!materialDefect.id" class="subtitle-2 ml-3">
          * {{ $t('must_be_filled') }}
        </span>

        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';
import materialDefectService from '@/api/material-defect-service';

export default {
  name: 'MaterialDefectForm',

  components: { BaseDatepickerInput },

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    formItem: Object,
    acceptedFileFormats: {
      type: String,
      default: '*',
    },
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      materialDefect: {},
    };
  },

  computed: {
    formTitle() {
      return this.$t(this.materialDefect.id ? 'edit_material_defect' : 'new_material_defect');
    },

    documentErrors() {
      let documentErrors = [];
      Object.keys(this.errors).forEach(error => {
        if (!error.includes('documents')) {
          return;
        }
        documentErrors = [...documentErrors, ...this.errors[error]];
      });
      return documentErrors;
    },
  },

  methods: {
    onDialogOpen() {
      this.materialDefect = JSON.parse(JSON.stringify(this.formItem));
      this.materialDefect.documents = [];
      if (!this.materialDefect.id) {
        // TODO presetting date makes it so changing it doesn't update the view. Find a fix
        // this.materialDefect.date = format(new Date(), 'yyyy-MM-dd');
      }
      this.errors = {};
    },

    onSubmit() {
      this.crudMixin_createOrUpdate(materialDefectService, this.materialDefect);
    },
  },
};
</script>
