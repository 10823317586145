import http from './http';

const endpoint = 'api/order-documents';

const orderDocumentService = {
  model: 'orderDocument',

  upload: payload => {
    const fd = new FormData();
    fd.append('order_id', payload.orderID);
    if (payload.orderPartID) {
      fd.append('order_part_id', payload.orderPartID);
    }
    for (let i = 0; i < payload.files.length; i++) {
      fd.append('files[]', payload.files[i]);
    }
    return http.post(`${endpoint}`, fd);
  },

  delete: document => http.delete(`${endpoint}/${document.id}`),
};

export default orderDocumentService;
