import http from './http';
import getFormData from '@/util/form-data';

const endpoint = 'api/material-defects';

const materialDefectService = {
  model: 'materialDefect',

  getPage: (page, params) => http.get(endpoint, { params: { ...params, page } }),

  create: materialDefect => http.post(`${endpoint}`, getFormData(materialDefect)),

  update: materialDefect => {
    const payload = getFormData(materialDefect);
    payload.append('_method', 'PUT');
    return http.post(`${endpoint}/${materialDefect.id}`, payload);
  },

  delete: materialDefect => http.delete(`${endpoint}/${materialDefect.id}`),
};

export default materialDefectService;
