// https://stackoverflow.com/questions/49820013/javascript-scrollintoview-smooth-scroll-and-offset
// eslint-disable-next-line import/prefer-default-export
export function scrollToTargetAdjusted(id, offset = 64) {
  const element = document.getElementById(id);
  if (!element) {
    return;
  }
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
}

export function scrollElementIntoView(selector, topBarOffset = 56) {
  const el = document.querySelector(selector);
  if (el) {
    window.scrollTo(0, el.getBoundingClientRect().top + window.scrollY - topBarOffset);
  }
}

export function adjustViewForPagination(res) {
  // scrolls to top of the table when a response returns with page param
  if (!res.config?.params?.page) {
    return;
  }

  const selector = '.v-data-table-header';
  scrollElementIntoView(selector);
}
