var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"table",staticClass:"order-table",class:{ 'content-loading': _vm.loading, 'force-wrap': _vm.forceContentWrapping },attrs:{"expanded":[_vm.expandedOrder],"footer-props":{
    'items-per-page-options': [50, 100, 250, 500],
  },"items-per-page":+_vm.pagination.rowsPerPage,"headers":_vm.tableMixin_displayedHeaders,"items":_vm.displayedItems,"mobile-breakpoint":0,"page":_vm.pagination.page,"loading":_vm.loading,"server-items-length":_vm.pagination.totalItems,"hide-default-header":_vm.displayAs === 'cards',"disable-sort":""},on:{"update:page":_vm.tableMixin_changePage,"update:items-per-page":function($event){return _vm.$emit('update:items-per-page', $event)}},scopedSlots:_vm._u([(_vm.displayAs === 'cards')?{key:"body",fn:function(ref){
  var items = ref.items;
return [_c('v-divider'),_c('OrderCardsGrid',{attrs:{"orders":items,"order-processes":_vm.orderProcesses,"order-materials":_vm.orderMaterials,"get-item-actions":_vm.getRowActions,"loading":_vm.loading},on:{"click:order-no":function($event){return _vm.$emit('row-expand', $event)},"change:status":_vm.onStatusChange}})]}}:null,{key:"item.handle",fn:function(){return [_c('v-icon',{staticClass:"draggable"},[_vm._v(" mdi-drag ")])]},proxy:true},{key:"item.order_no",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"min-width":"130px"}},[_c('span',{staticClass:"link",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('row-expand', item)}}},[_vm._v(" "+_vm._s(item.order_no)+" ")]),(item.workDaysTillDeadline >= 0 && item.workDaysTillDeadline <= 14)?_c('v-tooltip',{attrs:{"transition":"none","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"color":item.workDaysTillDeadline <= 7 ? 'error' : 'yellow darken-2',"small":""}},on),[_vm._v("mdi-clock")])]}}],null,true)},[(item.workDaysTillDeadline > 0)?_c('div',[_vm._v(" "+_vm._s(item.workDaysTillDeadline)+" "+_vm._s(_vm.$t('work_days_till_deadline'))+" ")]):_c('div',[_vm._v(_vm._s(_vm.$t('is_overdue'))+"!")])]):_vm._e(),(item.hasCompletedProcessesWithInsufficientManufacturedQuantity)?_c('v-tooltip',{attrs:{"transition":"none","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"color":"error","small":""}},on),[_vm._v("mdi-alert-circle")])]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('has_completed_processes_with_insufficient_manufactured_quantity'))+" ")])]):_vm._e()],1),_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"max-width":"128px"}},_vm._l((_vm.orderProcesses[item.id]),function(p){return _c('span',{key:p.id,staticClass:"bubble",style:(("background-color: " + (_vm.orderPartProcessStatuses[p.status].hexColor)))})}),0)]}},{key:"item.client",fn:function(ref){
  var item = ref.item;
return [(item.client)?_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","nudge-bottom":"8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('span',_vm._g({staticClass:"link"},on),[_vm._v(" "+_vm._s(item.client.name)+" ")])]}}],null,true)},[_c('ContactCard',{attrs:{"contact-id":item.client.id}})],1):_vm._e(),(item.client_order_no)?_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(item.client_order_no)+" ")]):_vm._e()]}},{key:"item.group",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(item.group && item.group.name)+" ")]),_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('new-group-item', item.group)}}},[_vm._v("mdi-file-document-box-plus")])]}},{key:"item.materials",fn:function(ref){
  var item = ref.item;
return [_c('MaterialBubbles',{attrs:{"materials":_vm.orderMaterials[item.id]}})]}},{key:"item.createdBy",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"link",on:{"click":function($event){return _vm.$emit('update:filter', Object.assign({}, _vm.filterParams,
          {search: item.createdBy}))}}},[_vm._v(" "+_vm._s(item.createdBy)+" ")]),_c('br'),_c('span',{staticClass:"grey--text text-no-wrap"},[_vm._v(_vm._s(item.createdAtFormatted))])]}},{key:"item.responsible_user",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"link",on:{"click":function($event){return _vm.$emit('update:filter', Object.assign({}, _vm.filterParams,
          {responsible_user_id: item.responsible_user_id}))}}},[_vm._v(" "+_vm._s(item.responsible_user.person.full_name)+" ")]),(item.deadline_at)?_c('div',{class:item.workDaysTillDeadline >= 0 ? 'error--text font-weight-bold' : 'grey--text'},[_vm._v(" "+_vm._s(item.deadline_at)+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('BaseChipSelect',{attrs:{"value":item.status,"items":_vm.orderStatuses,"tooltip":item.status === 'canceled' ? item.cancellation_comment : ''},on:{"change":function($event){return _vm.onStatusChange(item, $event)}}})]}},{key:"item.is_offer_sent",fn:function(ref){
        var item = ref.item;
return [_c('div',[(item.is_offer_sent)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-checkbox-marked-circle-outline ")]):_vm._e()],1)]}},{key:"item.is_shipped",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.is_shipped ? 'primary' : ''},on:{"click":function($event){$event.stopPropagation();return _vm.onCheckboxChange(item, 'is_shipped')}}},[_vm._v("mdi-checkbox-"+_vm._s(item.is_shipped ? 'marked' : 'blank-outline'))])]}},{key:"item.is_invoice_issued",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.is_invoice_issued ? 'primary' : ''},on:{"click":function($event){$event.stopPropagation();return _vm.onCheckboxChange(item, 'is_invoice_issued')}}},[_vm._v("mdi-checkbox-"+_vm._s(item.is_invoice_issued ? 'marked' : 'blank-outline'))])]}},{key:"item.comments",fn:function(ref){
        var item = ref.item;
return [_c('BaseTruncatedField',{attrs:{"text":item.comments,"truncated-length":50}})]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.getRowActions(item),"item":item,"simple":""}})]}},{key:"expanded-item",fn:function(ref){
        var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.group",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.group && item.group.name))]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('new-group-item', item.group)}}},[_c('v-icon',[_vm._v("mdi-file-document-box-plus")])],1)],1)]},proxy:true},{key:"item.materials",fn:function(){return [_c('MaterialBubbles',{attrs:{"materials":_vm.orderMaterials[item.id]}})]},proxy:true},{key:"item.client",fn:function(){return [(item.client)?_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","nudge-bottom":"8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({staticClass:"link"},on),[_vm._v(" "+_vm._s(item.client.name)+" ")])]}}],null,true)},[_c('ContactCard',{attrs:{"contact-id":item.client.id}})],1):_vm._e(),(item.client_order_no)?_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(item.client_order_no)+" ")]):_vm._e()]},proxy:true},{key:"item.createdBy",fn:function(){return [_c('span',{staticClass:"link",on:{"click":function($event){return _vm.$emit('update:filter', Object.assign({}, _vm.filterParams,
              {search: item.createdBy}))}}},[_vm._v(" "+_vm._s(item.createdBy)+" ")]),_c('br'),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.createdAtFormatted))])]},proxy:true},{key:"item.responsible_user",fn:function(){return [_c('div',{staticClass:"link",on:{"click":function($event){return _vm.$emit('update:filter', Object.assign({}, _vm.filterParams,
              {responsible_user_id: item.responsible_user_id}))}}},[_vm._v(" "+_vm._s(item.responsible_user.person.full_name)+" ")]),(item.deadline_at)?_c('div',{class:item.workDaysTillDeadline >= 0 ? 'error--text font-weight-bold' : 'grey--text'},[_vm._v(" "+_vm._s(item.deadline_at)+" ")]):_vm._e()]},proxy:true},{key:"item.status",fn:function(){return [_c('BaseChipSelect',{attrs:{"value":item.status,"items":_vm.orderStatuses},on:{"change":function($event){return _vm.onStatusChange(item, $event)}}}),(item.status === 'canceled' && item.cancellation_comment)?_c('p',[_vm._v(" "+_vm._s(item.cancellation_comment)+" ")]):_vm._e()]},proxy:true},{key:"item.is_offer_sent",fn:function(){return [_c('div',[(item.is_offer_sent)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-checkbox-marked-circle-outline ")]):_vm._e()],1)]},proxy:true},{key:"item.is_shipped",fn:function(){return [_c('v-icon',{attrs:{"color":item.is_shipped ? 'primary' : ''},on:{"click":function($event){$event.stopPropagation();return _vm.onCheckboxChange(item, 'is_shipped')}}},[_vm._v("mdi-checkbox-"+_vm._s(item.is_shipped ? 'marked' : 'blank-outline'))])]},proxy:true},{key:"item.is_invoice_issued",fn:function(){return [_c('v-icon',{attrs:{"color":item.is_invoice_issued ? 'primary' : ''},on:{"click":function($event){$event.stopPropagation();return _vm.onCheckboxChange(item, 'is_invoice_issued')}}},[_vm._v("mdi-checkbox-"+_vm._s(item.is_invoice_issued ? 'marked' : 'blank-outline'))])]},proxy:true},{key:"append",fn:function(){return [_vm._t("expanded-item-append",null,{"order":item})]},proxy:true}],null,true)})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }