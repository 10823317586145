<template>
  <div>
    <v-dialog
      v-model="isDialogOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="700"
      scrollable
    >
      <template v-slot:activator="{ on }">
        <slot name="activator" :on="on">
          <v-btn v-on="on" color="primary">
            {{ $t('select_inventory_item_group') }}
          </v-btn>
        </slot>
      </template>

      <v-card>
        <v-card-title>
          <v-btn v-if="inventoryItemGroupBreadcrumbs.length" class="mr-2" icon @click="viewParent">
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
          <h2 class="title">
            {{ title }}
          </h2>
          <v-tooltip transition="none" content-class="tooltip-in-dialog" max-width="30em" bottom>
            <template #activator="{ on }">
              <v-icon v-on="on" color="info" class="ml-3">mdi-information</v-icon>
            </template>

            <div>
              <div class="mb-3">{{ $t('inventory_item_group_select_hint') }}</div>
              <div>{{ $t('inventory_item_group_open_hint') }}</div>
            </div>
          </v-tooltip>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="crudMixin_openForm('inventoryItemGroup', newInventoryItemGroupTemplate)"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $t('create_inventory_item_group') }}
          </v-btn>
        </v-card-title>

        <div class="px-4 pb-1">
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="localSearchQuery"
                :label="$t('search_this_group')"
                class="mt-0"
                prepend-inner-icon="mdi-magnify"
                clearable
              />
            </v-col>
          </v-row>
        </div>

        <v-card-text class="px-0">
          <InventoryItemGroupTable
            :selected-items="selectedInventoryItemGroups"
            :rows="filteredInventoryItemGroups"
            :pagination="inventoryItemGroupPagination"
            :loading="$store.getters.loading['get:api/inventory-item-groups']"
            @dblclick:row="viewChildren"
            @row-click="onRowClick"
            @change-page="getInventoryItemGroups"
            @edit="onEditClick"
            @delete="deleteGroup"
          />
        </v-card-text>

        <v-card-actions>
          <div class="pl-2 d-flex flex-wrap align-center body-2" v-if="selectedInventoryItemGroup">
            <span class="mr-1">{{ $t('selected') }}:</span>
            <div class="d-flex align-center">
              <span class="black--text mr-1">{{ selectedInventoryItemGroup.title }}</span>
              <v-icon @click="clearSelectedItem">mdi-close</v-icon>
            </div>
          </div>
          <v-spacer />
          <v-btn color="primary" text @click="onConfirm">{{ $t('confirm') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isInventoryItemGroupFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="650"
      persistent
      scrollable
    >
      <InventoryItemGroupForm
        :dialog="isInventoryItemGroupFormOpen"
        :form-item="inventoryItemGroupFormItem"
        :parent-group-id="selectedGroup.id"
        @cancel="isInventoryItemGroupFormOpen = false"
        @create="crudMixin_created('inventoryItemGroup', $event)"
        @update="crudMixin_updated('inventoryItemGroup', $event)"
      />
    </v-dialog>
  </div>
</template>

<script>
import crudMixin from '@/mixins/crud-mixin';
import inventoryItemGroupService from '@/api/inventory-item-group-service';
import InventoryItemGroupTable from '@/components/tables/InventoryItemGroupTable';
import InventoryItemGroupForm from '@/components/forms/InventoryItemGroupForm';

export default {
  name: 'InventoryItemGroupPicker',

  components: { InventoryItemGroupForm, InventoryItemGroupTable },

  mixins: [crudMixin],

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    nudgeBottom: {
      type: String,
      default: '8',
    },
    nudgeLeft: {
      type: String,
      default: '0',
    },
    left: {
      type: Boolean,
      default: false,
    },
    openOnCreate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isDialogOpen: false,
      inventoryItemGroupArray: [],
      inventoryItemGroupPagination: {
        page: 1,
        rowsPerPage: 50,
      },
      inventoryItemGroupFilterParams: {
        parent_group_id: null,
      },

      inventoryItemGroupFormItem: {},
      newInventoryItemGroupTemplate: {},
      isInventoryItemGroupFormOpen: false,
      loadingInventoryItemGroups: true,
      inventoryItemGroupBreadcrumbs: [],

      selectedInventoryItemGroup: null,
      localSearchQuery: '',
    };
  },

  computed: {
    selectedGroup() {
      return this.inventoryItemGroupBreadcrumbs.length
        ? this.inventoryItemGroupBreadcrumbs[this.inventoryItemGroupBreadcrumbs.length - 1]
        : { id: null };
    },

    title() {
      return this.selectedGroup.title || this.$t('inventory_item_groups');
    },

    filteredInventoryItemGroups() {
      const q = this.localSearchQuery?.trim().toLowerCase() || '';
      return this.inventoryItemGroupArray.filter(g => g.title.toLowerCase().includes(q));
    },

    selectedInventoryItemGroups() {
      return this.selectedInventoryItemGroup ? [this.selectedInventoryItemGroup] : [];
    },
  },

  created() {
    this.selectedInventoryItemGroup = this.value;
    if (this.openOnCreate) {
      this.isDialogOpen = true;
    }
    this.getInventoryItemGroups();
  },

  methods: {
    getInventoryItemGroups(page = 1) {
      this.crudMixin_getPage(
        inventoryItemGroupService.getPage,
        inventoryItemGroupService.model,
        page,
        this.inventoryItemGroupFilterParams,
      );
    },

    viewChildren(group) {
      this.inventoryItemGroupBreadcrumbs.push(group);
      this.inventoryItemGroupFilterParams.parent_group_id = group.id;
      this.getInventoryItemGroups();
    },

    viewParent() {
      const group = this.inventoryItemGroupBreadcrumbs.pop();
      this.inventoryItemGroupFilterParams.parent_group_id = group.parent_group_id;

      // set the new parent as selected group
      this.selectedInventoryItemGroup = this.inventoryItemGroupBreadcrumbs.slice(-1)[0] || null;

      this.getInventoryItemGroups();
    },

    onRowClick(item) {
      this.selectedInventoryItemGroup = item;
    },

    clearSelectedItem() {
      this.selectedInventoryItemGroup = null;
    },

    onEditClick(item) {
      this.crudMixin_openForm('inventoryItemGroup', item);
    },

    deleteGroup(group) {
      this.crudMixin_delete(inventoryItemGroupService.delete, 'inventoryItemGroup', group);
    },

    onConfirm() {
      this.$emit('input', this.selectedInventoryItemGroup);
      this.isDialogOpen = false;
    },
  },
};
</script>
