<template functional>
  <span :class="{ 'grey--text': !+props.value }">{{ +props.value ? props.value : 0 }}</span>
</template>

<script>
export default {
  name: 'BaseNumberCell',

  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
  },
};
</script>
