<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ $t('issue_all_manufactured_amount') }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="order.invoice_no"
              :error-messages="errors['invoice_no']"
              :label="$t('invoice_no')"
              @blur="formMixin_clearErrors('invoice_no')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import orderService from '../../api/order-service';

export default {
  name: 'OrderIssuanceForm',

  components: {},

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    formItem: Object,
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      order: {},
    };
  },

  methods: {
    onDialogOpen() {
      this.order = JSON.parse(JSON.stringify(this.formItem));
      this.errors = {};
    },

    onSubmit() {
      this.crudMixin_createOrUpdate(orderService, this.order);
    },
  },
};
</script>
